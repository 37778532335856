import React, {useState} from "react";

const CarouselConfiguration = () => {
  const [selectors, setSelectors] = useState([]);
  const [idCounter, setIdCounter] = useState(1);
  const [carouselInterval, setCarouselInterval] = useState(3);

  const handleAddSelector = () => {
    setSelectors([...selectors, { id: idCounter, src: "" }]);
    setIdCounter(idCounter + 1);
  };

  const handleDeleteSelector = (id) => {
    setSelectors(selectors.filter((selector) => selector.id !== id));
  };

  const handleSelectChange = (id, value) => {
    setSelectors(
      selectors.map((selector) =>
        selector.id === id ? { ...selector, src: value } : selector
      )
    );
  };

  const handleSubmit = () => {
    const formData = {
      carousel: {
        images: selectors,
        speed: carouselInterval,
      },
    };
    console.log(formData)
  };

  return (
    <div className="p-4">
      <h2 className="text-xl font-semibold mb-2 text-center border-y border-gray-200 ">
        CAROUSEL CONFIGURATION FORM
      </h2>
      <h3 className="font-semibold">Carousel Images :</h3>

      <div className="mt-4  flex">
        <div className="p-2 w-4/12 bg-gray-300 rounded-md">
          <button
            className="mb-2 p-2 bg-gray-500 text-white rounded"
            onClick={handleAddSelector}
          >
            Add Image
          </button>
          {selectors.map((selector) => (
            <div key={selector.id} className="mb-2 flex items-center">
              <select
                id={`select-${selector.id}`}
                value={selector.src}
                onChange={(e) =>
                  handleSelectChange(selector.id, e.target.value)
                }
                className="ml-2 border rounded p-1 w-full"
              >
                <option value="">Select Image</option>
                <option value="Image1">Image1</option>
                <option value="Image2">Image2</option>
                <option value="Image3">Image3</option>
              </select>
              <button
                className="ml-2 p-2 bg-red-500 text-white rounded"
                onClick={() => handleDeleteSelector(selector.id)}
              >
                Delete
              </button>
            </div>
          ))}
        </div>
        <div className=" px-4">
          <div className="bg-gray-300 flex p-2 flex-col rounded-md">
            <label htmlFor="carouselInterval" className=" py-2 font-semibold">
              Carousel Interval (in seconds):
            </label>
            <input
              name="carouselInterval"
              type="number"
              value={carouselInterval}
              onChange={(e) => setCarouselInterval(e.target.value)}
              className=" p-2  ronded-md "
            />
          </div>
        </div>
      </div>
      <button
        onClick={handleSubmit}
        className="mt-4 bg-blue-500 text-white p-2 rounded hover:bg-blue-700"
      >
        Save Configuration
      </button>
    </div>
  );
};

export default CarouselConfiguration;
