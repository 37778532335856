import React, {useState} from "react";
import {FaRegTrashCan} from "react-icons/fa6";
import {RxCross2} from "react-icons/rx";

const RBACConditionList = ({ condition, onDelete, onChange, conditionKey }) => {
  const handleOperationValueChange = (e) => {
    const updatedCondition = { ...condition, operationValue: e.target.value };
    onChange(conditionKey, updatedCondition); // Ensure this matches the expected key in the parent.
  };

  const handleExpectedValueChange = (e) => {
    const updatedCondition = { ...condition, expectedValue: e.target.value };
    onChange(conditionKey, updatedCondition);
  };

  const handleConditionTypeChange = (e) => {
    const updatedCondition = { ...condition, operator: e.target.value };
    onChange(conditionKey, updatedCondition);
  };

  return (
    <div className="flex justify-between border-b-2 p-2 border-gray-400 m-2">
      <select
        className="px-2 mx-2 border border-gray-400"
        value={condition.operator}
        onChange={handleConditionTypeChange}
      >
        <option value="AND">AND</option>
        <option value="OR">OR</option>
      </select>

      <div className="flex flex-col w-full">
        <div className="flex w-full justify-center items-center">
          <div className="border border-gray-300 px-2 text-sm">ROLE</div>

          <select
            className="px-2 mx-2 border border-gray-400"
            name="operationValue"
            value={condition.operationValue}
            onChange={handleOperationValueChange}
          >
            <option value="">Select operation</option>
            <option value="Equals">Equals</option>
            <option value="Greater">Greater</option>
            <option value="Smaller">Smaller</option>
          </select>

          <select
            className="px-2 mx-2 border border-gray-400"
            name="expectedValue"
            value={condition.expectedValue}
            onChange={handleExpectedValueChange}
          >
            <option value="">Select role</option>
            <option value="Customer">Customer</option>
            <option value="Manager">Manager</option>
            <option value="Admin">Admin</option>
          </select>
        </div>
      </div>

      <button onClick={() => onDelete(condition.conditionKey)}>
        <FaRegTrashCan />
      </button>
    </div>
  );
};

const RBAC = ({ block, onDeleteBlock, onBlockChange }) => {
  const [rbacConditions, setRbacConditions] = useState([{ key: 1 }]);

  const handleBlockNameChange = (e) => {
    const updatedBlock = { ...block, ruleName: e.target.value };
    onBlockChange(block.blockId, updatedBlock);
  };

  const handleBlockDescriptionChange = (e) => {
    const updatedBlock = { ...block, description: e.target.value };
    onBlockChange(block.blockId, updatedBlock);
  };

  const handleAddCondition = () => {
    const newCondition = {
      conditionId: block.conditions.length + 1,
      operator: block.conditions.length === 0 ? "IF" : "AND",
      operationValue: "",
      attributeName: "user",
      expectedValue: "",
    };
    const updatedBlock = {
      ...block,
      conditions: [...block.conditions, newCondition],
    };
    onBlockChange(block.blockId, updatedBlock);
  };

  const handleDeleteCondition = (conditionId) => {
    const updatedBlock = {
      ...block,
      conditions: block.conditions.filter((c) => c.conditionId !== conditionId),
    };
    onBlockChange(block.blockId, updatedBlock);
  };

  const handleConditionChange = (conditionKey, updatedCondition) => {
    console.log(conditionKey, updatedCondition); // this is showing as :undefined {conditionId: 1, conditionType: 'IF', operationValue: 'Equals', expectedValue: ''}
    const updatedConditions = block.conditions.map((condition) =>
      condition.conditionId === conditionKey
        ? { ...condition, ...updatedCondition }
        : condition
    );
    onBlockChange(block.blockId, { ...block, conditions: updatedConditions });
  };

  const handleAddRBAC = () => {
    const newCondition = { key: rbacConditions.length + 1 }; // Using Date.now() to ensure a unique key
    setRbacConditions([...rbacConditions, newCondition]);
  };

  const handleDeleteRBAC = (key) => {
    setRbacConditions(
      rbacConditions.filter((condition) => condition.key !== key)
    );
  };

  return (
    <div className="bg-gray-200 px-2 py-1 m-1 relative">
      {block.blockId !== 1 && (
        <button
          onClick={() => onDeleteBlock(block.blockId)}
          className="absolute hover:bg-gray-500 top-0 right-0"
        >
          <RxCross2 />
        </button>
      )}

      <div className="flex mt-2 ">
        <div className="flex flex-col">
          <label className="text-xs font-semibold" htmlFor="ruleName">
            Rule Name
          </label>
          <input
            id="ruleName"
            type="text"
            className="border border-gray-300 text-sm px-2 py-1"
            value={block.ruleName}
            onChange={handleBlockNameChange}placeholder="..."
          />
        </div>
        <div className="flex flex-col pl-4 w-full pr-8">
          <label className="text-xs font-semibold" htmlFor="description">
            Rule description
          </label>
          <input
            id="description"
            type="text"
            placeholder="..."
            className="border border-gray-300 text-sm px-2 py-1"
            value={block.description}
            onChange={handleBlockDescriptionChange}
          />
        </div>
      </div>

      <div className="flex my-2">
        <label className="text-xs pr-2" htmlFor={`Required-${block.blockId}`}>
          Required
        </label>
        <input
          type="checkbox"
          name="Required"
          id={`Required-${block.blockId}`}
          checked={block.required}
          onChange={(e) => {
            const updatedBlock = { ...block, required: e.target.checked };
            onBlockChange(block.blockId, updatedBlock);
          }}
        />

        <label
          className="text-xs pl-5 pr-2"
          htmlFor={`EnableConditions-${block.blockId}`}
        >
          Enable Conditions
        </label>
        <input
          type="checkbox"
          name="EnableConditions"
          id={`EnableConditions-${block.blockId}`}
          checked={block.enableCondition}
          onChange={(e) => {
            const updatedBlock = {
              ...block,
              enableCondition: e.target.checked,
            };
            onBlockChange(block.blockId, updatedBlock);
          }}
        />
      </div>

      {/* Display and manage conditions */}
      <div>
        {block.conditions.map((condition) => (
          <RBACConditionList
            key={condition.conditionId}
            condition={condition}
            conditionKey={condition.conditionId}
            onDelete={() => handleDeleteCondition(condition.conditionId)}
            onChange={handleConditionChange}
          />
        ))}
      </div>

      {/* Add button */}
      <button
        onClick={handleAddCondition}
        className="text-sky-blue text-2xl hover:bg-sky-200"
      >
        +
      </button>
    </div>
  );
};

export default RBAC;
