// src/components/forms/CardForm.js
import React, { useState } from "react";
import CardPreview from "../previews/CardPreview";
import formComponents from "./formComponents";

export const getDefaultCardConfig = () => ({
  componentType: "Card",
  backgroundColor: "#ffffff",
  padding: "5%", // Responsive padding
  margin: "2%", // Responsive margin
  border: "1px solid #ccc",
  borderRadius: "8px",
  shadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
  width: "100%",
  height: "auto",
  minWidth: "100px",
  minHeight: "auto",
  maxWidth: "100%",
  maxHeight: "none",
  nestedComponents: [],
});

const CardForm = ({ config, onConfigChange }) => {
  const [nestedComponents, setNestedComponents] = useState(
    config.nestedComponents || []
  );
  const [selectedNestedComponent, setSelectedNestedComponent] =
    useState("Text");

  const handleConfigChange = (e) => {
    const { name, value } = e.target;
    onConfigChange({ ...config, [name]: value });
  };

  const handleAddNestedComponent = () => {
    const newComponent = {
      type: selectedNestedComponent,
      config: formComponents[selectedNestedComponent].getDefaultConfig(),
    };
    const newConfig = {
      ...config,
      nestedComponents: [...nestedComponents, newComponent],
    };
    setNestedComponents(newConfig.nestedComponents);
    onConfigChange(newConfig);
  };

  const handleMoveComponent = (index, direction) => {
    const newComponents = [...nestedComponents];
    const targetIndex = direction === "up" ? index - 1 : index + 1;

    if (targetIndex >= 0 && targetIndex < newComponents.length) {
      [newComponents[index], newComponents[targetIndex]] = [
        newComponents[targetIndex],
        newComponents[index],
      ];
      setNestedComponents(newComponents);
      onConfigChange({ ...config, nestedComponents: newComponents });
    }
  };

  const handleDeleteComponent = (index) => {
    const newComponents = nestedComponents.filter((_, i) => i !== index);
    setNestedComponents(newComponents);
    onConfigChange({ ...config, nestedComponents: newComponents });
  };

  return (
    <div>
      <h3>Card Settings</h3>
      <label>
        Background Color:
        <input
          type="color"
          name="backgroundColor"
          value={config.backgroundColor}
          onChange={handleConfigChange}
        />
      </label>
      <label>
        Padding:
        <input
          type="text"
          name="padding"
          value={config.padding}
          onChange={handleConfigChange}
        />
      </label>
      <label>
        Margin:
        <input
          type="text"
          name="margin"
          value={config.margin}
          onChange={handleConfigChange}
        />
      </label>
      <label>
        Border:
        <input
          type="text"
          name="border"
          value={config.border}
          onChange={handleConfigChange}
        />
      </label>
      <label>
        Border Radius:
        <input
          type="text"
          name="borderRadius"
          value={config.borderRadius}
          onChange={handleConfigChange}
        />
      </label>
      <label>
        Shadow:
        <input
          type="text"
          name="shadow"
          value={config.shadow}
          onChange={handleConfigChange}
        />
      </label>

      {/* Dropdown to add nested components */}
      <label>
        Add Component:
        <select
          onChange={(e) => setSelectedNestedComponent(e.target.value)}
          value={selectedNestedComponent}
        >
          {Object.keys(formComponents).map((component) => (
            <option key={component} value={component}>
              {component}
            </option>
          ))}
        </select>
        <button onClick={handleAddNestedComponent}>
          Add {selectedNestedComponent}
        </button>
      </label>

      {/* Render nested components with controls */}
      {nestedComponents.map((nestedComponent, index) => {
        const NestedForm = formComponents[nestedComponent.type]?.form;
        return (
          NestedForm && (
            <div
              key={index}
              style={{
                marginTop: "20px",
                border: "1px solid #ddd",
                padding: "10px",
              }}
            >
              <NestedForm
                config={nestedComponent.config}
                onConfigChange={(newConfig) => {
                  const updatedComponents = [...nestedComponents];
                  updatedComponents[index] = {
                    ...updatedComponents[index],
                    config: newConfig,
                  };
                  setNestedComponents(updatedComponents);
                  onConfigChange({
                    ...config,
                    nestedComponents: updatedComponents,
                  });
                }}
              />
              <div style={{ marginTop: "10px" }}>
                <button
                  onClick={() => handleMoveComponent(index, "up")}
                  disabled={index === 0}
                  style={{ marginRight: "5px" }}
                >
                  Move Up
                </button>
                <button
                  onClick={() => handleMoveComponent(index, "down")}
                  disabled={index === nestedComponents.length - 1}
                  style={{ marginRight: "5px" }}
                >
                  Move Down
                </button>
                <button
                  onClick={() => handleDeleteComponent(index)}
                  style={{ color: "red" }}
                >
                  Delete
                </button>
              </div>
            </div>
          )
        );
      })}
    </div>
  );
};

export default CardForm;
export { CardPreview };
