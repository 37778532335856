// src/components/previews/TextAreaPreview.js
import React from "react";

const TextAreaPreview = ({ config }) => {
  const { placeholder, rows } = config;

  return (
    <textarea
      placeholder={placeholder || "Enter text here"}
      rows={rows || 4}
      readOnly
      style={{
        width: "100%",
        padding: "8px",
        border: "1px solid #ccc",
        borderRadius: "4px",
        resize: "none",
      }}
    />
  );
};

export default TextAreaPreview;
