import Api from "../../../../services/Api";
import Notification from "../../../../services/Notification";

export const attributeSubmitHandler = (data, setReload) => {
  let attributeData = [];
  let schemaName = "mudumbai";
  let tableName = "attributes";
  data.map((col) => {
    attributeData.push({
      attribute_name: col,
      data_type: "Data type",
      app_id: "A-1",
      updated_by: "admin",
      created_by: "user",
    });
  });

  Api.post("save-data")
    .params({
      schemaName: schemaName,
      tableName: tableName,
      dataList: attributeData,
    })
    .send(function (response) {
      if (response !== undefined) {
        Notification.success("Success", "Attributes saved sucessFully");
        setReload((prev) => !prev);
      } else {
        Notification.error(
          "Error",
          "An error occurred while saving attribute data."
        );
      }
    });
};
