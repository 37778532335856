import {create} from "zustand";
export const useButtonState = create((set) => ({
  isModalOpen: false,
  openModal: () => set({ isModalOpen: true }),
  closeModal: () => set({ isModalOpen: false }),
  toggleModal: () => set((state) => ({ isModalOpen: !state.isModalOpen })),
  buttonTypes: ["primary", "secondary"],
  buttonSizes: ["small", "medium", "large"],
  buttonActions: ["login", "signup", "open external link"],
  // button configuration
  buttonConfig: {
    buttonSize: "",
    buttonType: "",
    buttonLabel: "",
    buttonColor: "",
    buttonBorder: "",
    buttonFontSize: "",
    animationDuration: "",
    icon: "",
    iconLeft: false,
    iconRight: true,
    action: () => {},
  },
  setButtonConfig: (payload) => {
    set((state) => ({
      ...state,
      buttonConfig: { ...state.buttonConfig, ...payload },
    }));
  },
}));
