import React, {useState} from "react";
import {IoIosSearch} from "react-icons/io";
import {useSelector} from "react-redux";
import {SEARCH_API} from "../../../utils/utils/constants";

const SearchValue = ({tableName,setResultData}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const userData = useSelector((store) => store.user);
  const { applicationName } = userData;

  const handleDataSearch = async () => {
    const url = `${SEARCH_API}?schemaName=${applicationName}&tableName=${tableName}&searchValue=${searchQuery}`;
    const response = await fetch(url);
    const searchData = await response.json();

    const excludedColumns = new Set([
        "createdby",
        "updatedby",
        "created_at",
        "updated_at",
        "id",
      ]);
      const newTableData = searchData.map((item) => {
        const newItem = {};
        Object.keys(item).forEach((key) => {
          if (!excludedColumns.has(key)) {
            newItem[key] = item[key];
          }
        });
        return newItem;
      });
      setResultData(newTableData);

  };

  return (
    <div className="w-3/12  flex m-2">
      <input
        className="px-4 py-2  text-sm border border-gray-400"
        type="text"
        placeholder="Search..."
        onChange={(e) => setSearchQuery(e.target.value)}
      />

      <button onClick={handleDataSearch} className=" w-10 justify-center pl-2 bg-gray-300">
        <IoIosSearch className="text-xl text-skt-blue" />
      </button>
    </div>
  );
};

export default SearchValue;
