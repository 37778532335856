import React, {useState} from 'react'
import ManageImages from './ManageImages'
import ManageVideos from './ManageVideos'
import ManageAudios from './ManageAudios'

const Assets = () => {
  const  [mediaType, setMediaType] = useState('image')

  return (
    <div>
      Manage all your assets here.
      <div className= "bg-gray-200 flex flex-col py-4 px-2"> 
        <lable htmlFor="select-media font-semibold py-1"> select media type</lable>
        <select onChange={(e)=>{
          setMediaType(e.target.value)
        }} id="select-media" className='w-2/12'>
          <option value="image">Images</option>
          <option value="audio">Audio</option>
          <option value="video">Video</option>
        </select>
      </div>

      <div className="my-2 bg-gray-100">
          {mediaType == "image" && <ManageImages/> }
          {mediaType == "audio" && <ManageAudios/> }
          {mediaType == "video" && <ManageVideos/> }
      </div>
    </div>
  )
}

export default Assets
