import React from 'react'
import Preview_Nav from '../../../user_components/navbar/Preview_Nav'

const DUMMY_PREVIEW_JSON = {
  // {header:header,component:Heade}
  // banner:true,
  // carousel:false,
  
}
const preview = () => {
  return (
    <div>
      <Preview_Nav/>
    </div>
  )
}

export default preview
