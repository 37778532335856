import {create} from "zustand";
import customInstance from "../axios/axiosInstance";
import Notification from "../services/Notification";
import axios from "axios";
import { showErrorToast, showSuccessToast } from "../components/Toast/ToastNotification";
const useFormStore = create((set, get) => ({
  // form configuration state
  formConfiguration: {
    formName: "",
    formDescription: "",
    elements: [],
  },
  //   state for steps to configure form
  step: 0,
  //   all form inputs for storage in the backend.
  setAllFormInputs: (elementsArray) =>
    set((state) => ({
      formConfiguration: {
        ...state.formConfiguration,
        elements: elementsArray,
      },
    })),
  // to update a form elements.
  updateFormConfiguration: (formData) =>
    set((state) => ({
      ...state,
      formConfiguration: {
        ...state.formConfiguration,
        ...formData,
      },
    })),
  // individually set name, and description
  setFormName: (name) =>
    set((state) => ({
      ...state,
      formConfiguration: {
        ...state.formConfiguration,
        formName: name,
      },
    })),

  setFormDescription: (description) =>
    set((state) => ({
      ...state,
      formConfiguration: {
        ...state.formConfiguration,
        formDescription: description,
      },
    })),
  // functions to change step state
  incrementStep: () =>
    set((state) => ({
      step: state.step + 1,
    })),

  decrementStep: () =>
    set((state) => ({
      step: state.step > 0 ? state.step - 1 : 0,
    })),

  setStep: (number) =>
    set(() => ({
      step: number,
    })),
  // this is a function to call form configuration details from backend or mock api from json db
  getFormConfiguration: async () => {
    // fetch individually from the id of the forms
    try {
      const id = localStorage.getItem("formId");
      const appId = localStorage.getItem("appId");
      const appName = localStorage.getItem("appName");
      const res = await axios.get(
        `http://49.207.7.220:8080/api/fetch-json?schemaName=${appName}&tableName=component&id=${id}&columnName=app_id&columnValue=${appId}`
      );
      const dataStr = res.data;
      const data = JSON.parse(dataStr[0]["json"]);
      console.log(data);
      return data;
    } catch (error) {
      showErrorToast("There's an error fetching form data");
      console.log(error);
    }
  },
  // function to save configuration
  // saveConfigurationHandler: async () => {
  //   // this is the post method to add a form configuration
  //   const res = await customInstance.post("/forms", {
  //     formName,
  //     elements,
  //     formDescription,
  //   });
  //   const data = res.data;
  //   console.log(data);
  // },
  // layouts for the forms function to setlayout and function to get layout.
  layout: "default", // default layout
  setLayout: (layout) => set({ layout }),
  getLayoutClass: (layout) => {
    switch (layout) {
      case "modernGrid3x1":
        return "grid grid-cols-1 md:grid-cols-3 gap-4 p-4 bg-white rounded-lg shadow-lg";

      case "modernGrid3x3":
        return "grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 p-6 bg-gray-50 rounded-lg shadow-md";

      case "stackedGrid":
        return "grid grid-cols-1 gap-6 bg-white p-4 rounded-lg border border-gray-200 shadow-sm";

      case "flexEvenSpacing":
        return "flex flex-wrap justify-between gap-4 p-4 bg-white rounded-lg shadow-md border border-gray-200";

      case "asymmetricGrid":
        return "grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-[2fr_1fr] gap-6 p-4 bg-gray-50 rounded-md shadow place-items-center";

      case "centeredGrid":
        return "grid grid-cols-1 md:grid-cols-3 gap-4 p-6 bg-white rounded-lg shadow-md justify-items-center";

      default:
        return "flex flex-col p-4 gap-4 bg-white rounded-lg shadow-sm"; // Default layout if no specific layout is selected
    }
  },
  // delete form,
  deleteForm: async (id) => {
    try {
      const res = await axios.delete(
        `http://49.207.7.220:8080/api/delete-data/public/form_data/${id}`
      );
      showSuccessToast("delete successful");
    } catch (error) {
      showErrorToast("something went wrong");
      console.log(error);
    }
  },
  // update form
  updateForm: async (id) => {
    const { formConfiguration } = get();
    try {
      const res = await axios.put(
        `http://49.207.7.220:8080/api/update-form/${id}`,
        formConfiguration
      );
      const data = res.data;
      console.log(data);
      showSuccessToast("form updated successfully");
    } catch (error) {
      console.log(error);
      showErrorToast("sorry there was an error");
    }
  },
  // get single form
  getSingleForm: async (id) => {
    console.log(id);

    try {
      const res = await axios.get(
        `http://49.207.7.220:8080/api/get-form/${id}`
      );
      const data = res.data;
      console.log(data);
      return data;
    } catch (error) {
      console.log(error);
      showErrorToast("sorry there was an error");
    }
  },
}));
export default useFormStore;
