import {createSlice} from "@reduxjs/toolkit";


const dashboardSlice = createSlice({
    name:"dashboard",
    initialState:{
        showSidebar:true,
        allOpenTabs:[],
        activeItem:null,
    },
    reducers:{
        toggleSidebar:(state)=>{
            state.showSidebar = !state.showSidebar;
        },
        addTab:(state,action)=>{
            const tabExists = state.allOpenTabs.some(tab => 
                tab.path === action.payload.path);

                if (!tabExists) {
             
                    state.allOpenTabs.push(action.payload);
                    state.activeItem = action.payload;
                } else {
                   
                    state.activeItem = action.payload;
                }
        },
        removeTab:(state,action)=>{
            state.allOpenTabs =  state.allOpenTabs.filter((tab) => tab.path !== action.payload)
            state.activeItem = state.allOpenTabs[[state.allOpenTabs.length - 1]]
        },
        updateActiveTab:(state,action)=>{
            state.activeItem = action.payload
        },
        

        
    }
})

export const {toggleSidebar,addTab,removeTab,updateActiveTab} = dashboardSlice.actions;

export default dashboardSlice.reducer;