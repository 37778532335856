import React, {useEffect, useState} from "react";
import {Link, Route, Routes, useNavigate} from "react-router-dom";
import routes from "../routes";
import NewSideBar from "../components/Sidebar/NewSideBar";
import {useDispatch, useSelector} from "react-redux";
import {addTab} from "../store/dashboardSlice";
import Accordion from "../components/Accordion/Accordion";
import {userLaptop} from "../assets/images";

const DYNAMIC_APP_SUBMENUS = ["/BulkActions ","/Configuration",]

const MainLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeRoute, setActiveRoute] = useState(null);

  const dashboardData = useSelector((store) => store.dashboard);

  const { showSidebar } = dashboardData;
  const { activeItem } = dashboardData;

  useEffect(() => {
    const currentRoute = routes.find(
      (route) =>
        route?.path.trim() === activeItem?.path &&
        route?.layout === activeItem?.layout
    );
    console.log("CURRENTROUTE+++++",currentRoute)
    setActiveRoute(currentRoute);
  }, [activeItem]);

  const goToPreviousPage = () => {
    const matchingRoute = routes.find(
      (route) =>
        route.path.trim() === activeItem.path &&
        route.layout === activeItem.layout
    );
    if (matchingRoute) {
      const prevPage = matchingRoute ? matchingRoute.prevPage : null;
      const newPage = routes.find(
        (route) => route.path.trim() === "/" + prevPage
      );
      const routeDetails = {
        path: newPage.path,
        layout: newPage.layout,
      };
      navigate("/admin/" + prevPage);
      dispatch(addTab(routeDetails));
      // dispatch(updateActiveTab(routeDetails));
    } else {
      console.log("NO PREV ROUTE!");
    }
  };

  const goToNextPage = () => {
    const matchingRoute = routes.find(
      (route) =>
        route.path.trim() === activeItem.path &&
        route.layout === activeItem.layout
    );
    if (matchingRoute) {
      const nextPage = matchingRoute ? matchingRoute.nextPage : null;
      const newPage = routes.find(
        (route) => route.path.trim() === "/" + nextPage
      );
      const routeDetails = {
        path: newPage.path,
        layout: newPage.layout,
      };
      navigate("/admin/" + nextPage);
      dispatch(addTab(routeDetails));
      // dispatch(updateActiveTab(routeDetails));
    } else {
      console.log("NO PREV ROUTE!");
    }
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin" && prop.path) {
        return (
          <Route
            path={prop.path}
            element={<prop.component key={key} />}
            key={key}
          />
        );
      } else if (prop.layout === "/admin" && !prop.path) {
        return prop.children.map((props, key) => {
          return (
            <Route
              path={props.path}
              element={<props.component key={key} />}
              key={key}
            />
          );
        });
      } else return null;
    });
  };

  // useEffect(() => {
  //   console.log("ACTIVE ROUTE -==============>>>",activeRoute)
    
  // }, [activeRoute])


  return (
    <>
      <NewSideBar />
      <div
        className={
          showSidebar
            ? `content-container-with-sidebar`
            : "content-container-without-sidebar"
        }
      >
        <div className={activeRoute ? 'shadow-lg p-4 rounded-md':''}>
        <Routes>
          {getRoutes(routes)}

          <Route path="*" element={<Link to="/admin/UserInterface" />} />
          {/* <Route path="*" element={<Link to="/admin" />} /> */}
        </Routes>

        {activeRoute && activeRoute.children && (
          <Accordion
            accordionContent={activeRoute.children.map((child) => ({
              name: child.name,
              component: child.component, // This assumes `component` is a React component
            }))}
          />
        )}

        {activeRoute ? (
          <div className="flex w-full mt-10 h-full justify-between  ">
            <div className="">
              <button
                onClick={goToPreviousPage}
                className="px-2 text-sm  border-2 border-skt-blue text-skt-blue rounded-full hover:bg-skt-blue hover:text-white "
              >
                {" "}
                Previous
              </button>
            </div>
            <div className=" ">
              <button
                onClick={() => {
                  console.log("CLIKCED!");
                }}
                className="px-2 text-sm  mx-2 border-2 border-skt-blue text-skt-blue rounded-full hover:bg-skt-blue hover:text-white "
              >
                Save
              </button>
              <button
                onClick={goToNextPage}
                className="px-2 text-sm  border-2 border-skt-blue text-skt-blue rounded-full hover:bg-skt-blue hover:text-white "
              >
                Next
              </button>
            </div>
          </div>
        ) : (
          <div className=" text-center  h-[50%]">
            <img  className=" mx-auto w-[300px] h-[50%]"src={userLaptop} atl="user-laptop-img"/>
            <h1 className="font-semibold text-3xl text-gray-600">
              WELCOME USER TO MUDUMBAI DASHBOARD
            </h1>
          </div>
        )}
        </div>
      </div>
    </>
  );
};

export default MainLayout;
