// src/components/forms/TextAreaForm.js
import React from "react";
import TextAreaPreview from "../previews/TextAreaPreview";

export const getDefaultTextAreaConfig = () => ({
  placeholder: "Enter text here",
  rows: 4,
  width: "100%", // Optional width setting
});

const TextAreaForm = ({ config, onConfigChange }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    onConfigChange({ ...config, [name]: value });
  };

  return (
    <div>
      <h3>Textarea Settings</h3>
      <label>
        Placeholder:
        <input
          type="text"
          name="placeholder"
          value={config.placeholder}
          onChange={handleChange}
        />
      </label>
      <label>
        Rows:
        <input
          type="number"
          name="rows"
          value={config.rows}
          onChange={handleChange}
        />
      </label>
      <label>
        Width:
        <input
          type="text"
          name="width"
          value={config.width}
          onChange={handleChange}
        />
      </label>
    </div>
  );
};

export default TextAreaForm;
export { TextAreaPreview };
