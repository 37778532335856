import React, { useState } from 'react';
import { Button, Col, Input, Modal, Row } from 'antd'; // Imported Button from antd
import TextArea from 'antd/lib/input/TextArea'; // Imported TextArea separately

const EmailModal = ({ addElement, setAddElement, index }) => {
  const [isEmailModalOpen, setIsEmailModalOpen] = useState(true);
  const [email, setEmail] = useState('');
  const [emailSubject, setEmailSubject] = useState('');
  const [emailBody, setEmailBody] = useState('');

  const handleEmailSubmit = (e) => {
    e.preventDefault();
    // You can send the email and requirements to your backend service here
    let updatedElement = [...addElement];
    updatedElement[index]['email'] = email;
    updatedElement[index]['emailBody'] = emailBody;
    updatedElement[index]['emailSubject'] = emailSubject;
    console.log(updatedElement);
    setAddElement(updatedElement);
    // Close the modal after submitting the form
    closeEmailModal();
  };

  const openEmailModal = () => {
    setIsEmailModalOpen(true);
  };

  const closeEmailModal = () => {
    setIsEmailModalOpen(false);
  };

  return (
    <Modal
      title="Email Details"
      open={isEmailModalOpen} 
      onCancel={closeEmailModal}
      footer={null}
    >
      <form onSubmit={handleEmailSubmit}>
        <Col span={24}>
          <Row>
            <label htmlFor="email">From Email:</label>
          </Row>
          <Row>
            <Input
              type="email"
              id="email"
              value={email}
              placeholder="Enter your email"
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <label htmlFor="emailSubject">To Email:</label>
          </Row>
          <Row>
            <Input
              type="text"
              id="emailSubject"
              value={emailSubject}
              placeholder="Enter recipient's email"
              onChange={(e) => setEmailSubject(e.target.value)}
              required
            />
          </Row>
        </Col>
        <Col span={24}>
          <Row>
            <label htmlFor="emailBody">Email Body:</label>
          </Row>
          <Row>
            <TextArea
              id="emailBody"
              value={emailBody}
              onChange={(e) => setEmailBody(e.target.value)}
              required
            />
          </Row>
        </Col>
        
        <br />
        <Button type="primary" htmlType="submit">Save</Button>
      </form>
    </Modal>
  );
};

export default EmailModal;