import React, {useState} from "react";

const FormConfiguration = () => {
  const [selectedForm, setSelectedForm] = useState("");

  const submitHandler = ()=>{
    console.log("FORM SUBMIT LOGIC HERE")
  }
  return (
    <div className="p-2">
      <h2 className="text-xl font-semibold mb-2 text-center border-y border-gray-200 ">
        FORM CONFIGURATIONS
      </h2>

      <div className="">
        <h3 className="font-semibold">
          Select forms to Display on Form Component:
        </h3>
        <div className="p-2 bg-gray-300 rounded-md">
          <select
            id="selectForm"
            name="selectForm"
            value={selectedForm}
            onChange={(e) => setSelectedForm(e.target.value)}
            className="mx-2  rounded-md p-2 w-full"
          >
            <option value="">Select form to display</option>
            <option value="Homepage">ContactUs form </option>
            <option value="Aboutpage">User Demographic Form </option>
            <option value="Contactuspage">Employee Form </option>
          </select>
        </div>
      </div>
      <button
        onClick={submitHandler}
        className="mt-4 bg-blue-500 text-white p-2 rounded hover:bg-blue-700"
      >
        Save Configuration
      </button>
    </div>
  );
};

export default FormConfiguration;
