import { Link } from "react-router-dom";
import React, { useState } from "react";
import PopupModal from "./PopupModal";
import useHeaderStore from "../../store/useHeaderStore";
import Notification from "../../services/Notification";

import customInstance from "../../axios/axiosInstance";

import axios from "axios";
import {
  showErrorToast,
  showSuccessToast,
  showWarningToast,
} from "../../components/Toast/ToastNotification";

const HeaderPreview = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [componentName, setComponentName] = useState("");
  const [error, setError] = useState("");

  const { selectedTemplate, formConfiguration } = useHeaderStore();
  console.log("formConfiguration", formConfiguration);
  const handleSave = async () => {
    if (!componentName) {
      Notification.error("Error", "Component Name field is empty");
      return;
    }
    const compTempStr = selectedTemplate.name;
    const appId = localStorage.getItem("appId");
    const appName = localStorage.getItem("appName");
    const jsonPayload = {
      appId: appId,
      compTempID: "temp1",
      pageId: "page1",
      compName: componentName,
      compType: selectedTemplate.name,
      visibilityRule: "always",
      visible: true,
      enabled: true,
      version: "1.0",
      createDateTime: "2024-10-19T10:00:00",
      json: { ...formConfiguration },
    };

    console.log(JSON.stringify(jsonPayload, null, 2));

    try {
      const res = await axios.post(
        "http://49.207.7.220:8080/api/component/save",
        jsonPayload
      );
      console.log(res.data);
      const headerId = res.data.id;
      console.log("header id:16", headerId);

      localStorage.setItem("headerTemplate", selectedTemplate.name);
      // Notification.success("success configuration saved successfully");
      if (res.status === 200) {
        showSuccessToast("configuration saved successfully");
        localStorage.setItem("headerId", headerId);
        handleClose();

        console.log("header id:17", headerId);
      }
    } catch (error) {
      console.log(error);
      // Notification.error("something went wrong please try again");
      if (error.response.status === 400) {
        showWarningToast("something went wrong please try again");
      } else if (error.response.status === 500) {
        showErrorToast("Failed to save configuration");
      } else {
        console.error("Error saving configuration:", error);
        showErrorToast("Failed to save configuration.");
      }
    }
  };

  const handleComponentNameChange = (e) => {
    setComponentName(e.target.value);
    setError("");
  };

  const handleClose = () => {
    setIsPopupVisible(false);
    setComponentName("");
    setError("");
  };

  const Template = selectedTemplate;
  if (!Template) {
    return (
      <div className=" flex flex-col p-6 items-center justify-evenly">
        <p className="">select a template for configuration</p>
        <Link
          className="p-4 mt-6 rounded-md hover:bg-[#a9a9ff] duration-300  bg-[#d4d4ff]"
          to={"/config-steps/header"}
        >
          Back to configure
        </Link>
      </div>
    );
  }
  return (
    <>
      <Template config={formConfiguration} />
      {isPopupVisible && (
        <PopupModal
          isVisible={isPopupVisible}
          title="Enter Component Name"
          onClose={handleClose}
          onSave={handleSave}
          errorMessage={error}
          saveButtonText="Save"
          cancelButtonText="Cancel"
        >
          {/*Children*/}
          <input
            type="text"
            value={componentName}
            onChange={handleComponentNameChange}
            placeholder="Component Name"
            className="border border-gray-300 p-2 rounded w-full mb-4"
            required
          />
        </PopupModal>
      )}

      <div className="flex justify-between items-center p-4 bg-gray-100 border-b border-gray-300">
        <Link
          to={"/config-steps/header"}
          className="text-blue-600 hover:underline font-medium"
        >
          Back to configure
        </Link>
        <button
          onClick={() => setIsPopupVisible(true)}
          className="px-4 py-2 bg-green-500 text-white rounded"
        >
          Save configuration
        </button>
      </div>
    </>
  );
};

export default HeaderPreview;
