

export const addUnderscores = (string) => {
    return string.replace(/\s+/g, '_');
};


export function getInputType(input) {
    if (!isNaN(input) && !isNaN(parseFloat(input))) {
      return "number";
    } else {
      return "text";
    }
  }