import React, {useEffect, useState} from "react";
import Api from "../../../../../services/Api";
import Notification from "../../../../../services/Notification";
import {FaEdit} from "react-icons/fa";
import {CiTrash} from "react-icons/ci";

const BusinessRules = () => {
  const [rulesData, setRulesData] = useState([]);

  useEffect(() => {
    Api.get("table-data?tableName=rules&schemaName=mudumbai").send(
      (response) => {
        if (response !== undefined) {
          setRulesData(response.map(({ app_id, created_at, created_by, updated_at, updated_by, ...item }) => item));
          console.log("Fetched and set rules data:", response);
        } else {
          Notification.error("Error", "An error occurred while getting rules data.");
        }
      }
    );
  }, []);

  return (
    <div>
      <h1 className="text-xl font-bold">BUSINESS RULES!</h1>
      <div className="overflow-x-auto relative">
        <table className="min-w-full leading-normal">
          <thead>
            <tr>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Rule Name
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Description
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Required
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Enable Condition
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Test Status
              </th>
              <th className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {rulesData.map((rule, index) => (
              <tr key={index}>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  {rule.rule_name}
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  {rule.description}
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  {rule.required ? "Yes" : "No"}
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  {rule.enable_condition ? "Yes" : "No"}
                </td>
                <td className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                  pending
                </td>
                <td className="px-5 flex py-5 border-b border-gray-200 bg-white text-sm">
                 <FaEdit className="cursor-pointer "/>
                 <CiTrash className=" cursor-pointer"/> 
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BusinessRules;
