import React from 'react'

const UserInterFace = () => {
  return (
    <div className='text-center'>
    
  </div>
  )
}

export default UserInterFace
