import React from "react";
import { ImageComponent,ButtonComponent,CallToAskComponent,ContactCardComponent,MapComponent,PageTitleComponent,ParagraphComponent,RegisteredCardComponent,ServiceCardComponent,ServiceListComponent} from "./customComponents";
function RenderComponent({ templateId, data }) {
  console.log("templateId in render component",templateId,"data",data)
  const ComponentTemplates = {
    ButtonComponent: ButtonComponent,
    CallToAskComponent: CallToAskComponent,
    ContactCardComponent: ContactCardComponent,
    ImageComponent: ImageComponent,
    MapComponent: MapComponent,
    PageTitleComponent: PageTitleComponent,
    ParagraphComponent: ParagraphComponent,
    RegisteredCardComponent: RegisteredCardComponent,
    ServiceCardComponent: ServiceCardComponent,
    ServiceListComponent: ServiceListComponent,
  };
  const TemplateName = ComponentTemplates[templateId];

  console.log("ComponentTemplates in render component", ComponentTemplates);
  console.log("ComponentTemplates in render component", ComponentTemplates[templateId]);
  console.log("TemplateName in render component",TemplateName)

  if (!TemplateName) {
    return <div>Error: Component not found for templateId "{templateId}"</div>;
  }
  return (
    <div>
      <TemplateName data={data} />
    </div>
  );
}

export default RenderComponent;