import React from 'react';

const Security = () => {
    return (
        <div className='text-center'>
        {/* <h1 className='text-3xl'>SECURITY</h1> */}
        
      </div>
    );
};

export default Security;