// src/components/previews/DropdownPreview.js
import React from "react";

const DropdownPreview = ({ config }) => {
  const { options, selectedOption } = config;

  return (
    <select value={selectedOption}>
      {(options || ["Option 1", "Option 2", "Option 3"]).map(
        (option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        )
      )}
    </select>
  );
};

export default DropdownPreview;
