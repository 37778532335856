import React, { useEffect, lazy, Suspense, useTransition } from "react";
import { useLoaderData } from "react-router-dom";
import {
  HeaderTemplate1,
  HeaderTemplate2,
  HeaderTemplate3,
  HeaderTemplate4,
} from "../../templates/Header";
import {
  FooterTemplate1,
  FooterTemplate2,
  FooterTemplate3,
  FooterTemplate4,
} from "../../templates/footer";
import { FormPreview } from "../../components/Admin/formBuilder";
const AppPreview = () => {
  // load header configuration
  // load footer configuration
  // load form configuration
  // const headerConfigLocalStorage = localStorage.getItem("headerConfig");
  // const formConfigLocalStorage = localStorage.getItem("formConfig");
  // const footerConfigLocalStorage = localStorage.getItem("footerConfig");
  const { headerConfig, formConfig, footerConfig } = useLoaderData();
  console.log(headerConfig, formConfig, footerConfig);

  if (!headerConfig && !formConfig && !footerConfig) {
    return (
      <main>
        <h3>something went wrong ...</h3>
        <button onClick={() => window.location.reload()}>reload</button>
      </main>
    );
  }

  const headerTemplates = {
    HeaderTemplate1: HeaderTemplate1,
    HeaderTemplate2: HeaderTemplate2,
    HeaderTemplate3: HeaderTemplate3,
    HeaderTemplate4: HeaderTemplate4,
  };
  const footerTemplates = {
    FooterTemplate1: FooterTemplate1,
    FooterTemplate2: FooterTemplate2,
    FooterTemplate3: FooterTemplate3,
    FooterTemplate4: FooterTemplate4,
  };

  const HeaderTemplateName = localStorage.getItem("headerTemplate");
  const FooterTemplateName = localStorage.getItem("footerTemplate");
  const HeaderTemplate = headerTemplates[HeaderTemplateName];
  const FooterTemplate = footerTemplates[FooterTemplateName];
  console.log("templates", HeaderTemplate.name, FooterTemplate.name);

  // we can have dynamic imports here
  return (
    <main className="min-h-screen flex flex-col gap-4">
      {headerConfig != null && <HeaderTemplate config={headerConfig} />}
      {formConfig != null && (
        <div className="flex-1 rounded-md flex  justify-center ">
          <FormPreview config={formConfig} />
        </div>
      )}
      {footerConfig != null && <FooterTemplate config={footerConfig} />}
    </main>
  );
};

export default AppPreview;
