import React, { useState } from "react";
import InputField from "../../components/Reuseableform/InputField";
import Button from "../../components/Reuseableform/Button";
import useFormValidation from "./useFormValidation";
import "./F.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { showErrorToast, showSuccessToast, showWarningToast } from "../Toast/ToastNotification";

const OnboardingForm = () => {
  const [check, setCheck] = useState({
    Qop1: false,
    Qop2: false,
    Qop3: false,
    Qop4: false,
    op1: true,
    op2: false,
    op3: false,
    op4: false,
    op5: false,
    op6: false,
  });
  const navigate = useNavigate();
  const [r1, setR1] = useState("");
  const [r2, setR2] = useState(null);
  const [r3, setR3] = useState("");
  const [r4, setR4] = useState("");

  const [showErrors, setShowErrors] = useState(false);

  const formValue = {
    check,
    r1: check.op1 ? r1 : null,
    r2: check.op1 ? r2 : null,
    r3: check.op1 ? r3 : null,
    r4: check.op1 ? r4 : null,
  };
  const { errors, validate } = useFormValidation(formValue, "onboarding");
  // const { errors, validate, clearError } = useFormValidation(formValue, 'onboarding');

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setCheck((prevCheck) => ({ ...prevCheck, [name]: checked }));
    setShowErrors(false);
  };

  const handleRadioChange = (e, setter) => {
    setter(e.target.value);
    setShowErrors(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setShowErrors(true);

    if (validate()) {
       let UserID = localStorage.getItem('userId');
       const AppID = localStorage.getItem('appId');
       if (UserID.startsWith('"') && UserID.endsWith('"')) {
         UserID = JSON.parse(UserID);
       }

      const onboardingFormData =
      {
          "schemaName": "public",
          "tableName": "user_response",
          "data": {
              "user_id": UserID,
              "app_id": AppID,
               "question_id": "Q-12",
              "response_text": JSON.stringify(formValue)
           }
      };

      console.log(JSON.stringify(onboardingFormData, null, 2));
      try {

        const res = await axios.post("http://49.207.7.220:8080/api/save-data",onboardingFormData

        );
        const data = res.data;
        if (res.status === 200) {
        console.log("Successfuly data saved ");
          showSuccessToast("Data saved successfully");

        }

        console.log(data);
      } catch (error) {

        console.log(error);
        console.log(error.response);
        console.log(error.response.data);
         if (error.response.status === 400) {
           showWarningToast("There was a problem with your request.");
         } else if (error.response.status === 500) {
           showErrorToast("Failed to save data");
         } else {
           console.error("Error saving data:", error);
           showErrorToast("Failed to save data.");
         }
      }
      navigate("/config-steps/header");
    }
  };

  return (
    <div className="con">
      <div className="onbording">
        <form onSubmit={handleSubmit}>
          <div className="onbord-form">
            <div className="onboarding-main">
              <fieldset>
                <legend>
                  How do you want your users to access your application?
                </legend>

                <InputField
                  type="checkbox"
                  name="Qop1"
                  label="No registration required to check my website. The user can register in the process when required."
                  checked={check.Qop1}
                  onChange={handleCheckboxChange}
                />

                <InputField
                  type="checkbox"
                  name="Qop2"
                  label="User will Signup by himself"
                  checked={check.Qop2}
                  onChange={handleCheckboxChange}
                />
                <InputField
                  type="checkbox"
                  name="Qop3"
                  label="Admin will create user account"
                  checked={check.Qop3}
                  onChange={handleCheckboxChange}
                />
                <InputField
                  type="checkbox"
                  name="Qop4"
                  label="Admin can create users in bulk using CSV file upload."
                  checked={check.Qop4}
                  onChange={handleCheckboxChange}
                />
                {showErrors && errors.check && (
                  <p className="error">{errors.check}</p>
                )}
              </fieldset>
              <fieldset>
                <legend>How many ways can a user sign in to your app?</legend>
                <InputField
                  type="checkbox"
                  name="op1"
                  label="Basic Credential"
                  checked={check.op1}
                  onChange={handleCheckboxChange}
                />
                <InputField
                  type="checkbox"
                  name="op2"
                  label="Mobile OTP"
                  checked={check.op2}
                  onChange={handleCheckboxChange}
                />
                <InputField
                  type="checkbox"
                  name="op3"
                  label="Google Signup"
                  checked={check.op3}
                  onChange={handleCheckboxChange}
                />
                <InputField
                  type="checkbox"
                  name="op4"
                  label="Facebook Signup"
                  checked={check.op4}
                  onChange={handleCheckboxChange}
                />
                <InputField
                  type="checkbox"
                  name="op5"
                  label="MFA"
                  checked={check.op5}
                  onChange={handleCheckboxChange}
                />
                <InputField
                  type="checkbox"
                  name="op6"
                  label="OAuth"
                  checked={check.op6}
                  onChange={handleCheckboxChange}
                />
                {showErrors && errors.check && (
                  <p className="error">{errors.check}</p>
                )}
              </fieldset>
            </div>
            {check.op1 && (
              <div className="">
                <fieldset className="onboarding-div ">
                  <legend>
                    How should users be able to reset their password?
                  </legend>
                  <InputField
                    label="Via email link"
                    type="radio"
                    name="r1"
                    value="emailLink"
                    onChange={(e) => handleRadioChange(e, setR1)}
                  />
                  <InputField
                    label="By answering security questions"
                    type="radio"
                    name="r1"
                    value="securityQuestions"
                    onChange={(e) => handleRadioChange(e, setR1)}
                  />
                  <InputField
                    label="Admin reset only"
                    type="radio"
                    name="r1"
                    value="adminReset"
                    onChange={(e) => handleRadioChange(e, setR1)}
                  />
                  {showErrors && errors.r1 && (
                    <p className="error">{errors.r1}</p>
                  )}
                </fieldset>
                <fieldset className="onboarding-div ">
                  <legend>
                    Would you like to include a "Forgot Password" feature?
                  </legend>
                  <InputField
                    label="Yes"
                    type="radio"
                    name="r2"
                    value="yes"
                    onChange={(e) => handleRadioChange(e, setR2)}
                  />
                  <InputField
                    label="No"
                    type="radio"
                    name="r2"
                    value="no"
                    onChange={(e) => handleRadioChange(e, setR2)}
                  />
                  {showErrors && errors.r2 && (
                    <p className="error">{errors.r2}</p>
                  )}
                </fieldset>
                <fieldset className="onboarding-div ">
                  <legend>
                    Do you want to offer a "Remember Password" option for users?
                  </legend>
                  <InputField
                    label="Yes, with sessions lasting 30 days"
                    type="radio"
                    name="r3"
                    value="30days"
                    onChange={(e) => handleRadioChange(e, setR3)}
                  />
                  <InputField
                    label="Yes, with sessions lasting 7 days"
                    type="radio"
                    name="r3"
                    value="7days"
                    onChange={(e) => handleRadioChange(e, setR3)}
                  />
                  <InputField
                    label="No"
                    type="radio"
                    name="r3"
                    value="no"
                    onChange={(e) => handleRadioChange(e, setR3)}
                  />
                  {showErrors && errors.r3 && (
                    <p className="error">{errors.r3}</p>
                  )}
                </fieldset>
                <fieldset className="onboarding-div ">
                  <legend>
                    What password policies would you like to enforce?
                  </legend>
                  <InputField
                    label="Minimum 8 characters, including a number and a special character"
                    type="radio"
                    name="r4"
                    value="min8Chars"
                    onChange={(e) => handleRadioChange(e, setR4)}
                  />
                  <InputField
                    label="Minimum 12 characters, including uppercase, lowercase, number, and special character"
                    type="radio"
                    name="r4"
                    value="min12Chars"
                    onChange={(e) => handleRadioChange(e, setR4)}
                  />
                  <InputField
                    label="No specific requirements"
                    type="radio"
                    name="r4"
                    value="noRequirements"
                    onChange={(e) => handleRadioChange(e, setR4)}
                  />
                  {showErrors && errors.r4 && (
                    <p className="error">{errors.r4}</p>
                  )}
                </fieldset>
              </div>
            )}
          </div>
          <Button type="submit" label="Submit" />
        </form>
      </div>

    </div>
  );
};

export default OnboardingForm;