import React from 'react';
import useComponentStore from "../../../../../store/useComponentsStore";

const FeatureListComponent = () => {
  const { featureListData } = useComponentStore();

  const {
    features,
    iconColor,
    textColor,
    fontSize,
    alignment,
    layout,
  } = featureListData;

  // Define grid layout based on the selected layout option
  const gridStyle = layout === 'two-column' ? 'grid grid-cols-1 sm:grid-cols-2 gap-4' : 'grid grid-cols-1 gap-4';

  return (
    <div className={gridStyle}>
      {features.map((feature, index) => (
        <div
          key={index}
          className={`flex items-center ${alignment === 'horizontal' ? 'flex-row' : 'flex-col'} p-4 bg-white rounded-md`}
          style={{ color: textColor, fontSize: `${fontSize}px` }}
        >
          {feature.image ? (
            <img
              src={feature.image}
              alt={feature.text}
              className="inline-block w-8 h-8 mr-2" // Adjusted size for consistency
            />
          ) : (
            <span
              style={{
                color: iconColor,
                fontSize: `${fontSize}px`,
                marginRight: alignment === 'horizontal' ? '10px' : '0',
              }}
            >
              {feature.icon}
            </span>
          )}
          <span>{feature.text}</span>
        </div>
      ))}
    </div>
  );
};

export default FeatureListComponent;