import React, { useState } from 'react';
import './search.css'
function SearchConfigurator() {
  const [searchBar, setSearchBar] = useState(false);
  const [advancedSearch, setAdvancedSearch] = useState(false);
  const [selectedTable, setSelectedTable] = useState('');
  const [columns, setColumns] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);

  const availableTables = ['Users', 'Orders', 'Products']; // Example tables
  const tableColumns = {
    Users: ['Name', 'Email', 'Phone', 'Address'],
    Orders: ['Order ID', 'Product', 'Quantity', 'Price'],
    Products: ['Product ID', 'Name', 'Category', 'Stock'],
  };

  const handleTableChange = (e) => {
    const table = e.target.value;
    setSelectedTable(table);
    setColumns(tableColumns[table]);
    setSelectedColumns([]);
  };

  const handleColumnSelection = (e) => {
    const column = e.target.value;
    if (e.target.checked) {
      setSelectedColumns([...selectedColumns, column]);
    } else {
      setSelectedColumns(selectedColumns.filter(col => col !== column));
    }
  };

  return (
    <fieldset className='main-s'>
      <legend>Search and Advanced Search Configuration</legend>
      
      <div>
        <label>
          Include a search bar in the header?
          <div className='op-yes'>
            <label>
              <input
                type="radio"
                name="searchBar"
                value="Yes"
                onChange={() => setSearchBar(true)}
              />
              Yes
            </label>
            <label>
              <input
                type="radio"
                name="searchBar"
                value="No"
                onChange={() => setSearchBar(false)}
              />
              No
            </label>
          </div>
        </label>
      </div>

      {searchBar && (
        <div className='select-table'>
          <label>
            Select a table:
            <select onChange={handleTableChange}>
              <option value="">Select a table</option>
              {availableTables.map((table) => (
                <option key={table} value={table}>
                  {table}
                </option>
              ))}
            </select>
          </label>

          {selectedTable && (
            <div>
              <label>
                Enable Advanced Search:
                <input
                  type="checkbox"
                  onChange={(e) => setAdvancedSearch(e.target.checked)}
                />
              </label>

              {advancedSearch && (
                <div className='adv-search'>
                  Select columns for Advanced Search
                  {columns.map((column) => (
                    <label key={column}>
                      <input
                        type="checkbox"
                        value={column}
                        onChange={handleColumnSelection}
                      />
                      {column}
                    </label>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </fieldset>
  );
}

export default SearchConfigurator;
