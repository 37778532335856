// src/hooks/useFormValidation.js

import { useState } from 'react';

const useFormValidation = (formValue,componentType) => {
    const [errors, setErrors] = useState({});

    const validate = () => {
        let newErrors = {};

    //validations of onboarding form
    if(componentType === 'onboarding') {
            if ( !formValue.check.Qop1 && !formValue.check.Qop2 &&
                 !formValue.check.Qop3 && !formValue.check.Qop4) {
                    newErrors.check = 'At least one option must be selected for user access.';
            }
            if ( !formValue.check.op1 && !formValue.check.op2 &&
                !formValue.check.op3 && !formValue.check.op4 &&
                !formValue.check.op5 && !formValue.check.op6)
                {
                    newErrors.check = 'At least one option must be selected for user access.';
            }
                if ( formValue.check.op1) {
                    if (!formValue.r1) {
                        newErrors.r1 = 'You must select a password reset method.';
                    }
                    if (formValue.r2 === null) {
                        newErrors.r2 = 'You must choose whether to include a "Forgot Password" feature.';
                    }
                    if (!formValue.r3) {
                        newErrors.r3 = 'You must select a "Remember Password" option.';
                    }
                    if (!formValue.r4) {
                        newErrors.r4 = 'You must select a password policy.';
                    }
                }

    } else if(componentType === 'headerConfig') {
             // Validation of header form
            //Validate Logo and Branding
            if(formValue.logoType === 'siteName' && !formValue.siteName){
                newErrors.siteName = 'Site Name is required';
            }
            if (formValue.logoType=== 'uploadLogo' && formValue.uploadedLogo){
                const fileExtension = formValue.uploadedLogo.name.split('.').pop().toLowerCase();
                if(fileExtension !=='png' && fileExtension !== 'jpg'){
                    newErrors.uploadedLogo='only PNG and JPG files are allowed';

                } else if (formValue.logoType=== 'uploadLogo' && !formValue.uploadedLogo){
                    newErrors.uploadedLogo='You must upload logo';
                }
            }
    }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
        const clearError = (fieldName) => {
            setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors[fieldName];
            return updatedErrors;
            });
        };

    if(componentType === 'onboarding'){
        return { errors, validate};
    } else {
        return { errors, validate, clearError };
    }

};

export default useFormValidation;
