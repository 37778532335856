import React, {useState} from "react";
import CarouselConfiguration from "../../../../user_components/carousel/CarouselConfiguration";
import BannerConfiguration from "../../../../user_components/banner/BannerConfiguration";
import FooterConfiguration from "../../../../user_components/footer/FooterConfiguration";
import NavbarConfigurator from "../../../../user_components/navbar/Nav_config";
import SideBarConfigForm from "../../../../user_components/sidebar/SideBarConfigForm";
import FormConfiguration from "../../../../user_components/form/FormConfiguration";
import SidebarBodyConfigForm from "../../../../user_components/sidebar/SidebarBodyConfigForm";

const DUMMY_COMPONENT_TABLE_DATA = [
  { componentID: 12, componentName: "Navbar" },
  { componentID: 13, componentName: "Carousel" },
  { componentID: 14, componentName: "Banner" },
  { componentID: 18, componentName: "Sidebar" },
  { componentID: 18, componentName: "Sidebar - Body" },
  { componentID: 19, componentName: "Footer" },
  { componentID: 20, componentName: "Form" },
];

const COMPONENTS_MAP = {
  Navbar:NavbarConfigurator ,
  Carousel: CarouselConfiguration,
  Banner: BannerConfiguration,
  "Sidebar": SideBarConfigForm,
  "Sidebar - Body": SidebarBodyConfigForm,
  Footer: FooterConfiguration,
  Form: FormConfiguration,
};

const Components = () => {
  const [selectedComponent, setSelectedComponent] = useState("");

  const handleChange = (event) => {
    setSelectedComponent(event.target.value);
  };

  const SelectedComponent = COMPONENTS_MAP[selectedComponent];

  return (
    <div>
      <div className="flex justify-between mb-4">
        <div>
          <h1 className="text-xl font-semibold">COMPONENTS</h1>
          <p className="text-blue-500 font-semibold text-xs">
            Edit components according to your design needs.
          </p>
        </div>
      </div>
      <div className="">
        <select
          className="block w-3/12 ml-4 p-2 border rounded mb-2"
          value={selectedComponent}
          onChange={handleChange}
        >
          <option value="">Select Component</option>
          {DUMMY_COMPONENT_TABLE_DATA.map((component) => (
            <option key={component.componentID} value={component.componentName}>
              {component.componentName}
            </option>
          ))}
        </select>

        {SelectedComponent && <SelectedComponent />}
      </div>
    </div>
  );
};

export default Components;
