import SendEmail from '../utils/SendEmail';

const SendEmailService = async ({verifyToken,email}) => {
    
    const verifyEmail=`http://49.207.7.220:3000/verify-email?token=${verifyToken}`;

    const templateParams={
        to_email: email,
        verify_Link: verifyEmail,
    };

    await SendEmail({
        serviceId: "service_8715ym8",
        templateId: "template_0h90aq7",
        userId: "GR1M3HK1I-dx7h-qU",
        templateParams,
      

    });
 
};

export default SendEmailService;



  