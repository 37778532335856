import React from 'react';
import './HeadConfig.css';
import SearchConfigurator from '../forms/header/SearchConfigurator';
import HeaderConfigForm from '../forms/header/HeaderConfigForm';
import Nav from '../forms/header/Nav';


const HeadMain = () => {
  return (
    <div className='Header-form-main'>
    <div className='header-config'>
        <HeaderConfigForm/>
    </div>
    <div className='search-config'>
      <SearchConfigurator/>
    </div>
    <div className='navigation-config'>
    <Nav/>
    </div>
    </div>
  )
}

export default HeadMain