import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SignInUser } from "../../services/services";
import {
  getTokenFromLocalStorage,
  setTokenToLocalStorage,
} from "../../utils/utils/LocalStorageUtils";
import Notification from "../../services/Notification";
import { BsFillEyeFill, BsFillEyeSlashFill } from "react-icons/bs";
  import useAuthStore from "../../store/useAuthStore";
import axios from "axios";

import Googlelogin from "./Googlelogin";

import Header from "../header/Header";
import "./Signin.css";
import { showErrorToast, showSuccessToast, showWarningToast } from "../Toast/ToastNotification";



const SignInForm = () => {
  const navigate = useNavigate();
  const [signInEmail, setSignInEmail] = useState("");
  const [signInPassword, setSignInPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordError, setPasswordError] = useState("");
 const { isAuthenticated, login, logout } = useAuthStore();

  useEffect(() => {
    const token = getTokenFromLocalStorage("token");
    if (token) {
      navigate("/welcome");
    }
  }, [navigate]);

  const validateEmail = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/;
    if (!signInEmail.trim()) {
      setEmailError("Email is required");
      showWarningToast("Email is required");
      return false;
    }
    if (!emailRegex.test(signInEmail.trim())) {
      setEmailError("Email is invalid");
      showWarningToast("Email is required");
      return false;
    }
    setEmailError("");
    return true;
  };
  let isValidating = false;
  const validatePassword = () => {
    if (isValidating) return; // Prevent duplicate submissions
    isValidating = true;

    if (!signInPassword.trim()) {
      setPasswordError("Password is required");
      showWarningToast("Password is required");
      return false;
    }
    if (signInPassword.trim().length < 8) {
      setPasswordError("Password must be at least 8 characters");
      showWarningToast("Password must be at least 8 characters");
      return false;
    }
    setPasswordError("");
    isValidating = false;
    return true;
  };

  let isSubmitting = false;

  const handleSignIn = async (e) => {
    e.preventDefault();
    if (isSubmitting) return; // Prevent duplicate submissions
    isSubmitting = true;

    const isEmailValid = validateEmail();
    const isPasswordValid = validatePassword();
    if (isEmailValid && isPasswordValid) {
      console.log("valid");
      try {
        const response = await SignInUser(signInEmail, signInPassword);
        if (response) {
          // const appUrlData = await createAppUrl(); // Call the new function here
          // console.log("App URL Data:", appUrlData);
          setTokenToLocalStorage("token", response.data.token);
          setTokenToLocalStorage("userId", response.data.userId);
          setTokenToLocalStorage("email", signInEmail);
          // Notification.success("Success", "Login successful");
          showSuccessToast("Login successful");
          login();
          navigate("/welcome", { replace: true });
        } else {
          console.error(
            "Sign-in failed:",
            response.status,
            response.statusText
          );

          if (response.status === 401) {
            setPasswordError(
              "Incorrect email or password. Please check your credentials."
            );
          } else {
            setPasswordError("An error occurred. Please try again.");
          }
        }
      } catch (error) {
        console.log("1", error);
        console.log("2", error.response);

        if (error) {
          // Handle specific error messages returned from the server
          if (typeof error !== "string") {
            error = String(error); // Convert error to string
          }
          console.log("3", typeof error);
          // Handle cases for invalid credentials
          if (error && error.includes("Invalid credentials")) {
            console.log("Invalid credentials");
            showErrorToast("Invalid username or password. Please try again.");
          }
          // Handle cases for account being locked
          else if (error && error.includes("Email is not registered")) {
            console.log("Authentication failed:Email is not registered");
            showErrorToast("Email is not registered");
          }
          // Handle cases for unverified email
          else if (error && error.includes("Email is not verified")) {
            console.log("Email not verified.");
            showWarningToast("Your email is not verified. Please check your email for the verification link.");
          }

          // Handle unknown or unexpected errors
          else {
            console.log("Error occurred:", error.errorMessage);
            showErrorToast("Unexpected error occurred. Please try again later.");
          }
        } else {
          // Something else happened while setting up the request
          showErrorToast("Unexpected error occurred. Please try again later.");
          console.log("Error occurred:", "Unexpected error ");
        }
      }
    }
    isSubmitting = false;
  };

  return (
    <>
      <div className="w-full h-screen bg-wight-500 flex justify-center items-center">
        <div
          onSubmit={handleSignIn}
          className="w-full max-w-[700px] bg-[#2c2c2c] rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 border-gray-300"
        >
          <div className="w-full max-w-[700px] bg-[#2c2c2c] rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 border-gray-300">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-4xl font-bold text-center leading-tight tracking-tight mb-16 text-white md:text-3xl">
                Sign in to your account
              </h1>
              <Googlelogin />
              <div className="flex ml-16 justify-center items-center w-60 mb-8">
                <hr className="flex-grow border-gray-400" />
                <span className="px-2 ml-4 mr-4 text-2xs text-white">Or</span>
                <hr className="flex-grow border-gray-400" />
              </div>
              <form className="space-y-7 md:space-y-6" onSubmit={handleSignIn}>
                <div className="w-full relative">
                  <input
                    type="email"
                    value={signInEmail}
                    onChange={(e) => setSignInEmail(e.target.value)}
                    required
                    name="email"
                    onFocus={() => emailError && setEmailError("")}
                    className={`field block mt-8 w-full h-[50px] px-4 bg-[#3b3b3b] border-b-2 border-[#555] rounded-md text-base text-[#f0f0f0] transition-all ease-in-out duration-300  ${
                      emailError ? "border-b-3 border-red-500" : "border-[#555]"
                    } focus:outline-none focus:border-b-3 focus:border-blue-300 `}
                    placeholder=" "
                  />
                  <label className="email-pass-label">Your email</label>
                  {/*emailError && <p className="error-message">{emailError}</p>*/}
                </div>
                <div className="w-full relative">
                  <div className="relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      value={signInPassword}
                      onChange={(e) => setSignInPassword(e.target.value)}
                      required
                      name="password"
                      id="password"
                      placeholder=""
                      onFocus={() => passwordError && setPasswordError("")}
                      className={`field block mt-8 w-full h-[50px] px-4 bg-[#3b3b3b] border-b-2 border-[#555] rounded-md text-base text-[#f0f0f0] transition-all ease-in-out duration-300  ${
                        passwordError
                          ? "border-b-4 border-red-600"
                          : " border-[#555]"
                      } focus:outline-none focus:border-b-3 focus:border-blue-300 `}
                    />
                    <label className="email-pass-label">Password</label>
                    {showPassword ? (
                      <BsFillEyeFill
                        onClick={() => setShowPassword(false)}
                        className="cursor-pointer absolute top-4 text-blue-300 right-2 hover:text-blue-300 focus:text-blue-300"
                      />
                    ) : (
                      <BsFillEyeSlashFill
                        onClick={() => setShowPassword(true)}
                        className="cursor-pointer absolute top-4 text-gray-400 right-2"
                      />
                    )}
                    {/*passwordError && <p className="error-message"></p>*/}
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <Link
                    to={"/auth/forgotPassword"}
                    className="text-[16px] font-medium text-[#90cdf4] mt-4"
                  >
                    Forgot password?
                  </Link>
                </div>
                <button
                  type="submit"
                  className="w-full bg-[#1e90ff] text-white mt-7 font-bold text-[1.2rem] py-2.5 px-5 rounded-md cursor-pointer transition-colors duration-300 hover:bg-[#1c86ee]"
                >
                  Sign in
                </button>

                <p className="text-[16px] font-medium text-[#90cdf4] mt-4">
                  Don’t have an account yet?{" "}
                  <a
                    onClick={() => navigate("/auth/signup")}
                    href="#"
                    className="text-[17px] first-line:font-bold text-[#90cdf4] "
                  >
                    Sign up
                  </a>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>


    </>
  );
};

export default SignInForm;
