import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {Select} from "antd";
import {FaRegTrashCan} from "react-icons/fa6";
import {GET_TABLE_DETAILS_API, GET_TABLE_NAMES_API} from "../../../../utils/utils/constants";

const TableAndColumnsSelect = ({selectorKey , onDelete,onAddToSelectedCols}) => {


  const userData = useSelector((store) => store.user);
  const [tableNames, setTableNames] = useState([]);
  const [columnNames, setColumnNames] = useState([]);

  const { applicationName } = userData;


  const getTableNames = async () => {
    const schemaName = applicationName;
    const data = await fetch(
      `${GET_TABLE_NAMES_API}?schemaName=${schemaName}`
    );
    const tableNameJsonData = await data.json();
    setTableNames(tableNameJsonData);
  };

  useEffect(() => {
    getTableNames();
  }, []);

  const onChangeTableName = async (e) => {
    const data = await fetch(
      `${GET_TABLE_DETAILS_API}?schemaName=${applicationName}&tableName=${e.target.value}`
    );
    const json = await data.json();
    console.log("JSON ==>> ", json.body);

    const excludedColumns = new Set([
      "createdby",
      "updatedby",
      "created_at",
      "updated_at",
      "id",
      "flowid",
      "appid",
    ]);

    const optionsData =
      json && Array.isArray(json.body) // Check if `json` is defined and is an array
        ? json.body
            .filter((col) => !excludedColumns.has(col.column_name)) // Exclude specific columns
            .map((col) => ({
              label: col.column_name, // Display text
              value: col.column_name, // Unique value
            }))
        : [];

    setColumnNames(optionsData);
    console.log("COL NAME ------->>::::", optionsData);
    // setColumnNames()
  };

  return (
    <div className="flex w-full bg-gray-300 px-4   py-2 gap-5">
      <select 
      className="w-5/12 rounded-lg text-center"
        name="tableNames"
        onChange={(e) => {
          onChangeTableName(e);
        }}
      >
        <option value="">select table name</option>
        {tableNames &&
          tableNames.map((table) => (
            <option key={table} value={table}>
              {table}
            </option>
          ))}
      </select>

      <Select
        placeholder="Select column names"
        mode="multiple"
        className="w-6/12"
        options={columnNames}
        onChange={onAddToSelectedCols}
      />
      {selectorKey != 1 ? (
       <button  onClick={() => onDelete(selectorKey)}>
          <FaRegTrashCan />
        </button>):''}
    </div>
  );
};

export default TableAndColumnsSelect;
