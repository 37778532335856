import React from 'react';
import {AiOutlinePlus} from 'react-icons/ai';
import {GoTrash} from "react-icons/go";

const ScreenInputList = ({screens,setScreens}) => {

  const handleAddClick = (index) => {
    const newScreens = [...screens];
    newScreens.splice(index + 1, 0, ''); // Insert a new empty string after the current index
    setScreens(newScreens);
  };

  const handleDeleteClick = (index) => {
    const newScreens = [...screens];
    if (newScreens.length > 1) {
      newScreens.splice(index, 1); // Remove the item at the current index
      setScreens(newScreens);
    }
  };

  const handleChange = (index, event) => {
    const newScreens = [...screens];
    newScreens[index] = event.target.value;
    setScreens(newScreens);
  };

  return (
    <div className="flex flex-col items-center justify-center mt-3">
        <h2 className='bg-gray-400 font-semibold w-full text-start p-2'>Screens</h2>
      <div className="w-full  ">
        {screens.map((screen, index) => (
          <div key={index} className="p-2 bg-gray-300 flex items-center mb-2">
            <div className="mr-2 text-xs font-semibold">{index + 1}</div>
            <input
              type="text"
              value={screen}
              onChange={(event) => handleChange(index, event)}
              className="border-2 w-60 border-gray-200 text-sm  "
            />
            <div className="flex">
            <button
              onClick={() => handleAddClick(index)}
              className="ml-2   hover:text-blue-700 text-skt-blue"
            >
              <AiOutlinePlus size="1em" />
            </button>
            <button
              onClick={() => handleDeleteClick(index)}
              className="ml-2    hover:text-red-500 text-skt-blue"
              disabled={screens.length === 1}
            >
              <GoTrash size="1em" />
            </button>
            </div>
            
          </div>
        ))}
      </div>
    </div>
  );
};

export default ScreenInputList;
