import React from 'react'
import {logoMudumbai} from '../../assets/images'
import {FaFacebook, FaYoutube} from "react-icons/fa";
import {FaLinkedin, FaSquareXTwitter} from "react-icons/fa6";

const Footer = () => {
  return (
    <section id="footer">
    <div className="bg-color-primary-dark relative text-white">
      <div className="container py-10">
        <div className="grid gap-10 md:grid-cols-3 pb-10">
          <div className="space-y-6">
            <h4 className="font-bold text-lg">About Us</h4>
            <p className="leading-relaxed">
              Embark on your digital journey with MUDUMBAI - where
              innovation meets simplicity, and your online success begins!
            </p>
            <div className="flex gap-5 items-center">
              <p>Follow Us</p>
              <FaFacebook className="fa-brands fa-facebook-f cursor-pointer hover:text-color-secondary"></FaFacebook>
              <FaSquareXTwitter className="fa-brands fa-twitter cursor-pointer hover:text-color-secondary"></FaSquareXTwitter>
              <FaYoutube className="fa-brands fa-youtube cursor-pointer hover:text-color-secondary"></FaYoutube>
              <FaLinkedin className="fa-brands fa-linkedin  cursor-pointer hover:text-color-secondary"></FaLinkedin>
            </div>
          </div>
          <div className="flex justify-between md:justify-around">
            <div className="space-y-6">
              <h4 className="font-bold text-lg">Quick Links</h4>
              <ul className="space-y-3">
                <li className="underline hover:no-underline hover:text-color-secondary">
                  <a href="#">Home</a>
                </li>
                <li className="underline hover:no-underline hover:text-color-secondary">
                  <a href="#features">Features</a>
                </li>
                <li className="underline hover:no-underline hover:text-color-secondary">
                  <a href="#testimonial">Testimonial</a>
                </li>
                <li className="underline hover:no-underline hover:text-color-secondary">
                  <a href="#pricing">Pricing</a>
                </li>
                <li className="underline hover:no-underline hover:text-color-secondary">
                  <a href="#blog">Blog</a>
                </li>
                <li className="underline hover:no-underline hover:text-color-secondary">
                  <a href="#contact">Contact</a>
                </li>
              </ul>
            </div>

            <div className="space-y-6">
              <h4 className="font-bold text-lg">Help</h4>
              <ul className="space-y-3">
                <li className="underline hover:no-underline hover:text-color-secondary">
                  <a href="#">About Us</a>
                </li>
                <li className="underline hover:no-underline hover:text-color-secondary">
                  <a href="#features">Partners</a>
                </li>
                <li className="underline hover:no-underline hover:text-color-secondary">
                  <a href="#testimonial">Career</a>
                </li>
                <li className="underline hover:no-underline hover:text-color-secondary">
                  <a href="#pricing">Reviews</a>
                </li>
                <li className="underline hover:no-underline hover:text-color-secondary">
                  <a href="#blog">Terms & Conditions</a>
                </li>
                <li className="underline hover:no-underline hover:text-color-secondary">
                  <a href="#contact">Help</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="space-y-6">
            <div className="md:w-1/2 ">
              <img src={logoMudumbai} alt="" />
            </div>
          </div>
        </div>

        <div className="flex justify-center pt-10 border-t border-color-gray">
          <p>2024 &copy; Mudumbai. All Rights Reserved.</p>
        </div>
      </div>
    </div>
  </section>
  )
}

export default Footer
