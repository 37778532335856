import {v4 as uuidv4} from "uuid"; // For generating unique ids for each field
import InputField from "./InputFeild";
import React, {useState} from "react";

const AddCustomDataFieldModal = ({ onFieldsSubmit, prevFieldData }) => {
  const [fields, setFields] = useState([{ id: uuidv4(), name: "", type: "" }]);
  const [error, setError] = useState(null);

  const handleNameChange = (id, value) => {
    setFields(
      fields.map((field) =>
        field.id === id ? { ...field, name: value } : field
      )
    );
  };

  const handleTypeChange = (id, value) => {
    setFields(
      fields.map((field) =>
        field.id === id ? { ...field, type: value } : field
      )
    );
  };

  const handleAddField = () => {
    setFields([...fields, { id: uuidv4(), name: "", type: "" }]);
  };

  const handleRemoveField = (id) => {
    setFields(fields.filter((field) => field.id !== id));
  };

  const handleSubmit = () => {
    const previousFieldData = new Set(prevFieldData.map((field) => field.name));
    let foundError = null;
    fields.forEach((field) => {
      // console.log(field.type.trim)
      if (previousFieldData.has(field.name)) {
        foundError = `Column name "${field.name}" already exists in your table, please choose a unique column name.`;
      } else if (field.name.trim().length === 0) {
        foundError = "Column name cannot be empty!";
      }
      else if(field.type.trim().length === 0){
        foundError = `Please select a data type for "${field.name}"`

      }
    });
    setError(foundError);
    if (!foundError) {
      const customFeildData = fields.map(({ name, type }) => ({ name, type }));
      onFieldsSubmit(customFeildData);
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center ">
      <div className="bg-white p-4 rounded-lg ">
        {error && (
          <p className="shadow-lg p-2 text-red-600 font-semibold text-sm bg-red-200">
            {error}
          </p>
        )}
        <div className="grid grid-cols-2">
          {fields.map((field) => (
            <InputField
              key={field.id}
              id={field.id}
              onNameChange={handleNameChange}
              onTypeChange={handleTypeChange}
              onRemove={handleRemoveField}
            />
          ))}
        </div>
        <button
          onClick={handleAddField}
          className="m-2 bg-skt-blue p-2 text-white rounded-md text-xs px-2"
        >
          Add column +
        </button>
        <button
          onClick={handleSubmit}
          className="m-2 bg-skt-light-blue  text-white rounded-md p-2 text-xs"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default AddCustomDataFieldModal;
