import { useState } from 'react';

function useImageValidation(maxSizeMB = 5, allowedTypes = ['image/jpeg', 'image/png']) {
  const [error, setError] = useState(null);

  const validateImage = (file) => {
    if (!file) {
      setError(null);
      return false;
    }

 
    if (!allowedTypes.includes(file.type)) {
      setError('Only JPG and PNG files are allowed.');
      return false;
    }

    
    const maxSizeBytes = maxSizeMB * 1024 * 1024;
    if (file.size > maxSizeBytes) {
      setError(`File size must be ${maxSizeMB} MB or less.`);
      return false;
    }

    setError(null); 
    return true;
  };

  return { error, validateImage };
}

export default useImageValidation;
