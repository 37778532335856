import "./Header2.css";
import { Link } from "react-router-dom";
import { FaCartShopping } from "react-icons/fa6";
import { FaSearch } from "react-icons/fa";
const HeaderTemplate2 = ({ config }) => {
  if (!config) {
    return (
      <nav className="nav-template">
        <div className="nav-top">
          <div className="nav-logo">Logo</div>
          <div className="nav-search">
            <input type="text" placeholder="Search here..." />
            <button className="search-btn">🔍</button>
          </div>
          <div className="nav-links">
            <a href="#" className="nav-link">
              Link 1
            </a>
            <a href="#" className="nav-link">
              Link 2
            </a>
            <a href="#" className="nav-link">
              Link 3
            </a>
            <a href="#" className="nav-link">
              Link 4
            </a>
            <a href="#" className="nav-link">
              Link 5
            </a>
          </div>
        </div>
        <div className="nav-categories">
          <div className="category-item">
            <a href="#" className="category-link">
              Category 1
            </a>
            <div className="sub-categories">
              <ul>
                <li>
                  <a href="#">Sub-Category 1</a>
                </li>
                <li>
                  <a href="#">Sub-Category 2</a>
                </li>
                <li>
                  <a href="#">Sub-Category 3</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="category-item">
            <a href="#" className="category-link">
              Category 2
            </a>
            <div className="sub-categories">
              <ul>
                <li>
                  <a href="#">Sub-Category 1</a>
                </li>
                <li>
                  <a href="#">Sub-Category 2</a>
                </li>
                <li>
                  <a href="#">Sub-Category 3</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="category-item">
            <a href="#" className="category-link">
              Category 3
            </a>
            <div className="sub-categories">
              <ul>
                <li>
                  <a href="#">Sub-Category 1</a>
                </li>
                <li>
                  <a href="#">Sub-Category 2</a>
                </li>
                <li>
                  <a href="#">Sub-Category 3</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="category-item">
            <a href="#" className="category-link">
              Category 4
            </a>
            <div className="sub-categories">
              <ul>
                <li>
                  <a href="#">Sub-Category 1</a>
                </li>
                <li>
                  <a href="#">Sub-Category 2</a>
                </li>
                <li>
                  <a href="#">Sub-Category 3</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="category-item">
            <a href="#" className="category-link">
              Category 5
            </a>
            <div className="sub-categories">
              <ul>
                <li>
                  <a href="#">Sub-Category 1</a>
                </li>
                <li>
                  <a href="#">Sub-Category 2</a>
                </li>
                <li>
                  <a href="#">Sub-Category 3</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    );
  }
  return (
    <nav className="nav-template">
      <div className="nav-top">
         <div className="flex items-center gap-2">
          <img src={config?.logoUrl} alt="abcd" className="w-12 h-12" />
        <div className="nav-logo">{config?.logoText}</div>
            </div>
        {config.searchBar ? (
          <div className="flex items-center border rounded-lg shadow-md bg-white p-2">
            <FaSearch className="text-gray-500 mr-2" />{" "}
            {/* Add the search icon */}
            <input
              type="text"
              placeholder="Search..."
              className="w-full p-2 text-gray-700 border-none outline-none rounded-md focus:ring-2 focus:ring-blue-500"
            />
          </div>
        ) : null}
        <div className="nav-links">
          {config.navigationLinks.map((link) => {
            return (
              <div className="p-4 bg-white shadow-md rounded-lg mb-4">
                <Link
                  to={`/${link.name.toLowerCase().replace(/\s+/g, "-")}`}
                  className="text-blue-600 hover:text-blue-800 font-semibold text-lg"
                >
                  {link.name}
                </Link>
              </div>
            );
          })}
        </div>
      </div>
      {/* categories here {config?.selectedCategories} */}

      <div className="nav-categories">
        {config?.selectedCategories.map((item) => {
          return (
            <div id={item.id}>
              <h3>{item.name}</h3>
            </div>
          );
        })}
      </div>
    </nav>
  );
};

export default HeaderTemplate2;
