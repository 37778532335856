import React from 'react';

const HinputField = ({ label, type, name, value, onChange, checked, placeholder }) => {
  return (
    <div className="inp-c">
      {type === 'checkbox' || type === 'radio' ? (
        <label>
          <input
            type={type}
            name={name}
            value={value}
            checked={checked}
            onChange={onChange}
          />
          {label}
        </label>
      ) : (
        <>
          <label>{label}</label>
          <input
            type={type}
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
          />
        </>
      )}
    </div>
  );
};

export default HinputField;
