import {create} from 'zustand';
import { persist } from 'zustand/middleware';


const profileStore = create(
   persist(
        (set)=>({
           signupEmail:'',
           userImage: null,     //default user image
           setUserImage: (newImage) => set({ userImage : newImage }),
           setSignupEmail:(newEmail)=> set({ signupEmail: newEmail}),
        }),
        {
           name:'profile-storage',
           partialize: (state) => ({signupEmail: state.signupEmail}),
           getStorage: () => localStorage,

        }
   )
);
export default profileStore;

