import React from 'react'

const ManageAudios = () => {
  return (
    <div className='text-6xl font-bold'>
    AUDIO
  </div>
  )
}

export default ManageAudios
