import React from 'react'

const Admin = () => {
  return (
    <div className='text-center'>
    {/* <h1 className='text-3xl'>ADMIN PANEL</h1> */}
    
  </div>
  )
}

export default Admin
