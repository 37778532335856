import React from "react";
import "./Header4.css";
import { Link } from "react-router-dom";
const HeaderTemplate4 = ({ config }) => {
  if (!config) {
    return (
      <nav className="nav-template">
        <div className="nav-content">
          <div className="nav-left">
            <div className="logo">LOGO</div>
            <div className="search-section">
              <input type="text" placeholder="Search here..." />
              <button className="search-btn">🔍</button>
            </div>
          </div>
          <div className="nav-right">
            <a href="#" className="nav-link">
              <span role="img" aria-label="login">
                👤
              </span>{" "}
              Login
            </a>
            <a href="#" className="nav-link">
              <span role="img" aria-label="cart">
                🛒
              </span>{" "}
              Cart
            </a>
            <a href="#" className="nav-link">
              Link 1
            </a>
            <a href="#" className="nav-link">
              Link 2
            </a>
            <a href="#" className="nav-link">
              Link 3
            </a>
            <div className="dropdown">
              <button className="dropbtn">More ▼</button>
              <div className="dropdown-content">
                <a href="#">Link 4</a>
                <a href="#">Link 5</a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
  return (
    <nav className="nav-template">
      <div className="nav-content">
        <div className="nav-left">
          <div className="flex items-center gap-2">
            <img src={config?.logoUrl} alt="abcd" className="w-12 h-12" />
            <div className="nav-logo">{config?.logoText}</div>
          </div>
          {/* <div className="logo">{config?.logoText}</div> */}
          {config.searchBar ? (
            <div className="search-section">
              <input type="text" placeholder="Search here..." />
              <button className="search-btn">🔍</button>
            </div>
          ) : null}
        </div>
        <div className="nav-right">
          {config.loginEnabled ? (
            <a href="#" className="nav-link">
              <span role="img" aria-label="login">
                👤
              </span>{" "}
              Login
            </a>
          ) : null}

          {config.cart ? (
            <a href="#" className="nav-link">
              <span role="img" aria-label="cart">
                🛒
              </span>{" "}
              Cart
            </a>
          ) : null}

          {config.navigationLinks.map((link) => {
            return (
              <div className="p-4 bg-white shadow-md rounded-lg mb-4">
                <Link
                  to={`/${link.name.toLowerCase().replace(/\s+/g, "-")}`}
                  className="text-blue-600 hover:text-blue-800 font-semibold text-lg"
                >
                  {link.name}
                </Link>
              </div>
            );
          })}
          <div className="dropdown">
            <button className="dropbtn">More ▼</button>
            <div className="dropdown-content">
              <a href="#">Link 4</a>
              <a href="#">Link 5</a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default HeaderTemplate4;
