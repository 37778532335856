import React, { useState } from "react";
import { useOutsideClick } from "./OutsideClick";
import adminImage from "./profile.png";
import profileStore from "../../store/profileStore";

export const ProfileMenu = ({ children, trigger }) => {
  const [show, setShow] = useState(false);
  const dropRef = useOutsideClick(() => setShow(false));
  return (
    <>
      <div
        className="w-fit  relative "
        ref={dropRef}
        onClick={() => setShow((curr) => !curr)}
      >
        <div>{trigger}</div>
        {show && (
          <ul className=" min-w-max absolute border border-gray-400 right-0 mt-2 bg-white divide-y divide-gray-200 rounded-lg shadow-xl overflow-hidden">
            {children}
          </ul>
        )}
      </div>
    </>
  );
};

export const ProfileMenuItem = ({ children }) => {
  return (
    <li className=" flex text-lg items-center pl-6 pr-[6rem] py-[15px] gap-4 font-normal text-gray-900 justify-start  hover:bg-gray-100 cursor-pointer">
      {children}
    </li>
  );
};

export const ProfileIcon = () => {
  const userImage = profileStore((state) => state.userImage);
  return (
    <img
      className="h-full w-full object-cover rounded-full border border-gray-300 transition-shadow duration-300 ease-in-out hover:shadow-2xl hover:shadow-blue-500/50"
      src={userImage ? URL.createObjectURL(userImage) : adminImage}
      alt="Admin Profile"
    />
  );
};
