import { useState } from "react";
import Notification from "../../services/Notification";
import { showErrorToast, showWarningToast } from "../Toast/ToastNotification";

export const useSignupValidation = () => {
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [nameNotified, setNameNotified] = useState(false);    // Flags to track if the notification was already shown
  const [emailNotified, setEmailNotified] = useState(false);   // Flags to track if the notification was already shown
  const [passwordNotified, setPasswordNotified] = useState(false);  // Flags to track if the notification was already shown

  // Function to validate name
  const validateName = (name) => {
    const nameRegex = /^[A-Za-z\s']+$/; // Only letters, spaces, and apostrophes allowed
    const trimmedName = name.trim();

    if (!trimmedName) {
      setNameError("Name is required");
      if (!nameNotified) {
        showWarningToast("Name is required"); // Show notification
        setNameNotified(true);
      }
      return false;
    }

    if (trimmedName.length < 2 || trimmedName.length > 18) {
      setNameError("Name must be between 2 and 18 characters");
      if (!nameNotified) {
        showWarningToast("Name must be between 2 and 18 characters"); // Show notification
        setNameNotified(true);
      }
      return false;
    }

    if (!nameRegex.test(trimmedName)) {
      setNameError("Name can only contain letters, spaces, and apostrophes");
      if (!nameNotified) {
        showWarningToast("Name can only contain letters, spaces, and apostrophes"); // Show notification
        setNameNotified(true);
      }
      return false;
    }

    setNameError("");
    setNameNotified(false);
    return true;
  };

  // Function to validate email
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/;
    const trimmedEmail = email && typeof email === "string" ? email.trim() : "";

    if (!trimmedEmail) {
      setEmailError("Email is required");
      if (!emailNotified) {
        showWarningToast("Email is required"); // Show notification
        setEmailNotified(true);
      }
      return false;
    }
    if (!emailRegex.test(trimmedEmail)) {
      setEmailError("Email is invalid");
      if (!emailNotified) {
        showErrorToast("Error", "Invalid email format"); // Show notification
        setEmailNotified(true);
      }
      return false;
    }
    setEmailError("");
    setEmailNotified(false);
    return true;
  };

  // Function to validate password
  const validatePassword = (password, confirmPassword) => {
    const trimmedPassword =
      password && typeof password === "string" ? password.trim() : "";

    if (!trimmedPassword) {
      setPasswordError("Password is required");
      if (!passwordNotified) {
        showWarningToast("Warning", "Password is required"); // Show notification
        setPasswordNotified(true);
      }
      return false;
    }

    if (trimmedPassword.length < 8) {
      setPasswordError("Password must be at least 8 characters long");
      if (!passwordNotified) {
        showWarningToast("Password must be at least 8 characters long"); // Show notification
        setPasswordNotified(true);
      }
      return false;
    }

    const lowercaseRegex = /[a-z]/;
    const uppercaseRegex = /[A-Z]/;
    const numberRegex = /[0-9]/;
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;

    if (!lowercaseRegex.test(trimmedPassword)) {
      setPasswordError("Password must contain at least one lowercase letter");
      if (!passwordNotified) {
        showWarningToast("Password must contain at least one lowercase letter"); // Show notification
        setPasswordNotified(true);
      }
      return false;
    }

    if (!uppercaseRegex.test(trimmedPassword)) {
      setPasswordError("Password must contain at least one uppercase letter");
      if (!passwordNotified) {
        showWarningToast("Password must contain at least one uppercase letter"); // Show notification
        setPasswordNotified(true);
      }
      return false;
    }

    if (!numberRegex.test(trimmedPassword)) {
      setPasswordError("Password must contain at least one number");
      if (!passwordNotified) {
        showWarningToast("Password must contain at least one number"); // Show notification
        setPasswordNotified(true);
      }
      return false;
    }

    if (!specialCharRegex.test(trimmedPassword)) {
      setPasswordError("Password must contain at least one special character");
      if (!passwordNotified) {
        showWarningToast("Password must contain at least one special character"); // Show notification
        setPasswordNotified(true);
      }
      return false;
    }
    setPasswordError("");
    setPasswordNotified(false);
    return true;
  };

  return {
    nameError,
    emailError,
    passwordError,
    validateName,
    validateEmail,
    validatePassword,
  };
};
