'use client'
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from '../../components/checkout/CheckoutForm';

const stripePromise = loadStripe('pk_test_51LzgkkJ8oK5Bf3KeaGXtDujreS99IfV7ML3lSmJd02Q7dqGKq7ooZLztsVP31umAnDxnhCmAzOEpQYNYFGaed1CW00aJDNUA9W');// personal test key

const Checkout =()=> {
  const options = {
    // passing the client secret obtained from the server my example client but should come from server
    clientSecret: 'pi_3PwKSzJ8oK5Bf3Ke0la5x4jr_secret_hmF0W3ISOb8R36gOWIRYIZLvL',
    
  };
  
  return (
    <div className='h-screen flex items-center justify-center'>
      <Elements stripe={stripePromise} options={options}>
      <CheckoutForm />
    </Elements>
    </div>
  );
};
export default Checkout

