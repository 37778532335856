import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../../store/useAuthStore";

const Logout = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const { isAuthenticated, login, logout } = useAuthStore();

  useEffect(() => {
    if (!token) {
      navigate("/auth/signin", { replace: true });
    }
  }, [token, navigate]);

  const handleLogout = () => {
    const confirmLogout = window.confirm("Do you want to log out?");
    if (confirmLogout) {
      localStorage.removeItem("token");
      localStorage.clear();
      logout();
      navigate("/auth/signin", { replace: true });
    } else {
      console.log("Logout canceled");
    }
  };

  return (
    <div className="justify-center">
      {token ? (
        <button
          onClick={handleLogout}
          // className="bg-orange-500 text-white font-semibold py-2 px-4 rounded hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-300"
        >
          Logout
        </button>
      ) : (
        <p>You are already logged out.</p>
      )}
    </div>
  );
};

export default Logout;
