/**
 * 
 * The `Preview` component in this JavaScript React code fetches screen data from an API, dynamically
 * loads components based on the data, and displays them once loaded, with a loading spinner shown
 * while fetching data.
 *
 */

import React, {useEffect, useState} from "react";
import axios from "axios";
import {useParams} from "react-router-dom";
import {BASE_URL} from "../../utils/utils/constants";

const Loader = () => (
  <div className="flex items-center justify-center space-x-2 animate-bounce">
    <div className="w-4 h-4 bg-blue-500 rounded-full"></div>
    <div className="w-4 h-4 bg-green-500 rounded-full"></div>
    <div className="w-4 h-4 bg-red-500 rounded-full"></div>
  </div>
);


const Preview = () => {
  const [components, setComponents] = useState([]);
  const [pageConfig, setPageConfig] = useState(null);
  const [loading, setLoading] = useState(true);
  const { appId, screenId } = useParams();
  console.log("App id ::",appId,"Screen id::",screenId)

  // now make an api call to get the screen data to show on preview


  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}table-data?schemaName=mudumbai&tableName=screen_data`
      );
      const data = response?.data[0]?.json_array;
      console.log("DATA-->", data);

      const json_data = JSON.parse(data);
      console.log("DATA-->", json_data);
      setPageConfig(json_data);
    } catch (error) {
      console.error("Error fetching banner data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (pageConfig && pageConfig.components) {
      const loadComponents = async () => {
        const promises = pageConfig.components.map((comp) =>
          import(`../../user_components/${comp.type}/${comp.name}`).then(
            (module) => ({
              Component: module.default,
              id: comp.id,
            })
          )
        );
        const components = await Promise.all(promises);
        setComponents(components);
      };
      loadComponents();
    }
  }, [pageConfig]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="p-6 bg-white shadow-lg rounded-lg">
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <div>
      {components.map(({ Component, id }) => (
        <Component key={id} />
      ))}
    </div>
    // <SideBarComponent/>
  );
};

export default Preview;
