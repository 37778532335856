import React, {useEffect, useState} from "react";
import {IoIosSearch} from "react-icons/io";
import {useSelector} from "react-redux";
import {ADVANCE_SEARCH_API, GET_TABLE_DETAILS_API} from "../../../utils/utils/constants";

const getInputType = (dataType) => {
  switch (dataType) {
    case "integer":
      return "number";
    case "numeric":
      return "number";
    case "character varying":
      return "text";
    case "timestamp without time zone":
      return "date";
    case "date":
      return "date";
    default:
      return "text";
  }
};

const SearchBar = ({ tableName ,setResultData}) => {
  const [searchData, setSearchData] = useState([]);
  const [error, setError] = useState(null);
  const userData = useSelector((store) => store.user);
  const { applicationName } = userData;

  const [advanceSearchData, setAdvanceSearchData] = useState({
    schemaName: "applicationname",
    tableName: tableName,
    columnValuePairs: {},
    dateFilters: [],
  });

  const getSearchData = async () => {
    const url = `${GET_TABLE_DETAILS_API}?schemaName=${applicationName}&tableName=${tableName}`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      const json = data.body;
      const excludedColumns = new Set([
        // "createdby",
        // "updatedby",
        // "created_at",
        // "updated_at",
        // "id",
      ]);
      const jsonData = json.filter(
        (column) => !excludedColumns.has(column?.column_name)
      );

      setSearchData(jsonData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    getSearchData();
  }, [tableName]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Determine if the field is a date range based on a naming convention, such as 'from_joining_date' or 'to_joining_date'
    const isDateField = name.includes("from_") || name.includes("to_");
  
    setAdvanceSearchData((prev) => {
      // Clone the previous state to avoid mutations
      let newAdvanceSearchData = { ...prev };
  
      if (isDateField) {
        // Determine whether it's a 'from' or 'to' field and extract the base column name
        const prefix = name.split("_")[0]; // "from" or "to"
        const dateColumn = name.substring(name.indexOf('_') + 1); // the rest is the column name
  
        // Initialize the dateFilters for the column if it doesn't exist
        if (!newAdvanceSearchData.dateFilters[dateColumn]) {
          newAdvanceSearchData.dateFilters[dateColumn] = {};
        }
  
        // Update the fromDate or toDate based on the prefix
        newAdvanceSearchData.dateFilters[dateColumn][prefix + 'Date'] = value;
        
        // Remove the date filter object if both fromDate and toDate are empty
        if (!newAdvanceSearchData.dateFilters[dateColumn].fromDate && !newAdvanceSearchData.dateFilters[dateColumn].toDate) {
          delete newAdvanceSearchData.dateFilters[dateColumn];
        }
      } else {
        // For non-date fields, add or remove the value from columnValuePairs
        if (value.trim()) {
          // Only add/update if there is a non-empty value
          newAdvanceSearchData.columnValuePairs[name] = value;
        } else {
          // If the value is empty, remove the key from columnValuePairs
          delete newAdvanceSearchData.columnValuePairs[name];
        }
      }
  
      return newAdvanceSearchData;
    });
  };
  const handleSubmit =async (e) => {
    e.preventDefault(); // Prevent the form from submitting in the traditional way
  
    // Construct the dateFilters array including objects with only fromDate or toDate if applicable
    const dateFilters = Object.entries(advanceSearchData.dateFilters)
      .map(([dateColumn, dates]) => ({
        dateColumn: dateColumn,
        ...(dates.fromDate && { fromDate: dates.fromDate }),
        ...(dates.toDate && { toDate: dates.toDate })
      }));
  
    // Now include the dateFilters in your final search data object
    const finalSearchData = {
      ...advanceSearchData,
      dateFilters: dateFilters,
    };
  
    const url = ADVANCE_SEARCH_API;

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(finalSearchData),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const result = await response.json();
    // Handle your search results here


    const excludedColumns = new Set([
      "createdby",
      "updatedby",
      // "created_at",
      // "updated_at",
      "id",
    ]);
    const newTableData = result.map((item) => {
      const newItem = {};
      Object.keys(item).forEach((key) => {
        if (!excludedColumns.has(key)) {
          newItem[key] = item[key];
        }
      });
      return newItem;
    });

    setResultData(newTableData);
  //   setAdvanceSearchData({
  //   schemaName: "applicationname",
  //   tableName: tableName,
  //   columnValuePairs: {},
  //   dateFilters: [],
  // })





  } catch (error) {
    setError('Search failed. Please try again later.');
  }

  };
  
  return (
    <div className="p-4 rounded-lg relative w-full">
      {error && (
        <p className="text-red-700 px-2 bg-red-300  text-sm">{error}</p>
      )}
      <form className="grid grid-cols-5 gap-2">
        {searchData.length > 0
          ? searchData.map((column, index) => (
              <div key={index} className=" ">
                <label
                  htmlFor={column.column_name}
                  className="text-sm font-medium text-gray-900"
                >
                  {column.column_name}
                </label>
                <br />
                {column.data_type === "date" || column.data_type === "timestamp without time zone" ? (
                  <>
                    <p>FROM {column.column_name}: </p>
                    <input
                      type="date"
                      name={`from_${column.column_name}`}
                      onChange={handleInputChange}
                      className="form-input text-xs px-1 py-1 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-skt-blue focus:border-transparent"
                      placeholder={`Enter ${column.column_name}...`}
                    />
                    <p>TO {column.column_name}: </p>
                    <input
                      type="date"
                      name={`to_${column.column_name}`}
                      onChange={handleInputChange}
                      className="form-input text-xs px-1 py-1 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-skt-blue focus:border-transparent"
                      placeholder={`Enter ${column.column_name}...`}
                    />
                  </>
                ) : (
                  <input
                    type={getInputType(column.data_type)}
                    id={column.column_name}
                    name={column.column_name}
                    onChange={handleInputChange}
                    className="form-input text-xs px-1 py-1 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-skt-blue focus:border-transparent"
                    placeholder={`Enter ${column.column_name}...`}
                  />
                )}
              </div>
            ))
          : "Please select a table first!"}
        {/* ... Other elements ... */}
      </form>
      {searchData.length > 0 && (
        <button
          onClick={handleSubmit}
          type="submit"
          className="px-4 py-2 mt-2 text-sm flex bg-skt-blue text-white rounded-lg items-center"
        >
          Search <IoIosSearch className="text-sm" />
        </button>
      )}
    </div>
  );
};

export default SearchBar;
