import React from 'react';
import './button.css'
const Button = ({ type, label, onClick }) => {
  return (
    <button className='sub-button ' type={type} onClick={onClick}>
      {label}
    </button>
  );
};

export default Button;
