import Api from "../../../services/Api";
import Notification from "../../../services/Notification";

const fetchScreenNames = async (setScreenNames) => {
  Api.get("table-data?tableName=form_data&schemaName=public").send(
    (response) => {
      if (response !== undefined) {
        // Assuming the response data is an array of screen entries
        const screenNames = response.map((entry) => entry.form_name);
        console.log(screenNames);
        // Update the state with the extracted screen names
        setScreenNames(screenNames);
      } else {
        const name = ["dummy name", "dummy name 2"];
        setScreenNames(name);
        Notification.error("Error", "An error occurred while getting data.");
      }
    }
  );
};

const fetchTableList = async (setExistingTableList) => {
  Api.get("table-names?schemaName=public").send((response) => {
    if (response !== undefined) {
      // Assuming the response data is an array of screen entries
      setExistingTableList(response);
    } else {
      Notification.error("Error", "An error occurred while getting data.");
    }
  });
};

const fetchAllScreenData = async (
  option,
  setSelectedScreenData,
  setAddElement,
  setInputs,
  setIsLoading
) => {
  setIsLoading(true);
  Api.get("table-data?schemaName=public&tableName=form_data").send(
    (response) => {
      if (response !== undefined) {
        // setExistingTableList(response);
        let selectedScreenData = response.filter((data) => {
          return data.form_name === option;
        });

        console.log(selectedScreenData);

        setSelectedScreenData(
          selectedScreenData[selectedScreenData.length - 1]
        );

        setAddElement(
          JSON.parse(
            selectedScreenData?.[selectedScreenData.length - 1]?.json_array
          ) || []
        );
        setInputs([]);
        setIsLoading(false);
      } else {
        setSelectedScreenData([]);
        setInputs([]);
        setAddElement([]);
        Notification.error("Error", "An error occurred while getting data.");
      }
    }
  );
};

const fetchVisibleFor = async (setListForUsers) => {
  Api.get("table-data/?schemaName=public&tableName=approle").send(
    (response) => {
      if (response !== undefined) {
        let VisibleFor = response.map((data) => {
          return data.app_role;
        });
        setListForUsers(VisibleFor);
      } else {
        Notification.error("Error", "An error occurred while getting data.");
      }
    }
  );
};

const handlleformData = async (
  selectedScreenData,
  addElement,
  setInputs,
  selectedOption
) => {
  console.log("SAVING DATA--->>", {
    schemaName: "public",
    tableName: "form_data",
    data: {
      user_id: selectedScreenData?.user_id || 2.2,
      form_id: selectedScreenData?.form_id || 2.2,
      app_id: selectedScreenData?.app_id || 2.2,
      is_enabled: true,
      form_name: selectedOption,
      json_array: JSON.stringify(addElement),
    },
  });
  Api.post("save-data")
    .params({
      schemaName: "public",
      tableName: "form_data",
      data: {
        user_id: selectedScreenData?.user_id,
        form_id: selectedScreenData?.form_id,
        app_id: selectedScreenData?.app_id,
        is_enabled: true,
        form_name: selectedOption,
        json_array: JSON.stringify(addElement),
      },
    })
    .send(function (response) {
      if (response !== undefined) {
        Notification.success("Success", response);
        setInputs([]);
      } else {
        Notification.error(
          "Error",
          "An error occurred while adding data.",
          response
        );
      }
    });
};
// add comment here on this code to remove the dependency function
const handlePreviewClick = (addElement) => {
  // setIsPreviewModalOpen(true);
  // console.log("ADD ELEMENT --->>", addElement)
  // setPreviewContent(addElement);
};

const deleteElement = (index, addElement, setAddElement) => {
  const updatedElement = [...addElement];
  if (index >= 0 && index < updatedElement.length) {
    updatedElement.splice(index, 1); // Remove one element at the specified index
  }
  setAddElement([...updatedElement]);
};

const handleElement = (addElement, setAddElement) => {
  let temp = [...addElement];

  temp.push({
    FormElement: "",
    IsRequired: false,
    NameOfTheField: "",
    VisibleFor: [],
    disabled: false,
  });
  setAddElement(temp);
};

export {
  fetchScreenNames,
  fetchTableList,
  fetchAllScreenData,
  fetchVisibleFor,
  handlleformData,
  handlePreviewClick,
  deleteElement,
  handleElement,
};
