import React, { useState } from "react";
import './n.css';

const Nav = () => {
  const [links, setLinks] = useState([
    // { id: 1, name: "Home", type: "Display Page", display: "Home Page" },
    // { id: 2, name: "Services", type: "Not Configured", display: "" },
  ]);
  const [menus, setMenus] = useState(["Menu 1", "Menu 2"]); // Fetched from DB
  const [pages, setPages] = useState(["Home Page", "About Page", "Contact Page"]); // Fetched from DB
  const [newLinkName, setNewLinkName] = useState("");

  const handleAddLink = () => {
    setLinks([
      ...links,
      { id: Date.now(), name: newLinkName, type: "Not Configured", display: "" },
    ]);
    setNewLinkName("");
  };

  const handleRemoveLink = (id) => {
    setLinks(links.filter((link) => link.id !== id));
  };

  const handleTypeChange = (id, newType) => {
    setLinks(
      links.map((link) =>
        link.id === id ? { ...link, type: newType, display: "" } : link
      )
    );
  };

  const handleDisplayChange = (id, newDisplay) => {
    setLinks(
      links.map((link) =>
        link.id === id ? { ...link, display: newDisplay } : link
      )
    );
  };

  const handleSubmit = () => {
    // Here, you would send the configuration to the server or save it locally
    console.log("Configuration Submitted:", links);
    // Example of posting to a server
    // fetch('https://your-server.com/api/config', {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //   },
    //   body: JSON.stringify({ links }),
    // })
    //   .then(response => response.json())
    //   .then(data => console.log('Success:', data))
    //   .catch(error => console.error('Error:', error));
  };

  return (
    <div className="nav-main">
    <fieldset>
    <legend>Navigation Configuration</legend>
    <div className='nav-div'>
    {links.map((link) => (
      <div key={link.id} className="nav-item">
        <input
          type="text"
          value={link.name}
          readOnly
          className="link-name"
        />
        <select
          value={link.type}
          onChange={(e) => handleTypeChange(link.id, e.target.value)}
          className="link-type"
        >
          <option value="Display Page">Display Page</option>
          <option value="Display Menu">Display Menu</option>
        </select>

        {link.type === "Display Page" ? (
          <select
            value={link.display}
            onChange={(e) => handleDisplayChange(link.id, e.target.value)}
            className="link-display"
          >
            <option value="">Select Display Page</option>
            {pages.length > 0 ? (
              pages.map((page, index) => (
                <option key={index} value={page}>
                  {page}
                </option>
              ))
            ) : (
              <option value="">No Pages Available</option>
            )}
          </select>
        ) : link.type === "Display Menu" ? (
          <select
            value={link.display}
            onChange={(e) => handleDisplayChange(link.id, e.target.value)}
            className="link-display"
          >
            <option value="">Select Menu</option>
            {menus.length > 0 ? (
              menus.map((menu, index) => (
                <option key={index} value={menu}>
                  {menu}
                </option>
              ))
            ) : (
              <option value="">No Menus Available</option>
            )}
          </select>
        ) : null}

        <button
          onClick={() => handleRemoveLink(link.id)}
          className="remove-link"
        >
          Remove
        </button>
      </div>
    ))}


      <div className="add-link">
        <input
          type="text"
          value={newLinkName}
          placeholder="Link Name"
          onChange={(e) => setNewLinkName(e.target.value)}
          className="new-link-input"
        />
        <button onClick={handleAddLink} className="add-link-button">
          Add 
        </button>
      </div>


    
     
      
    </div>
    <div className="actions">
        <button
          onClick={handleSubmit}
          className="save-config"
        >
          Save 
        </button>
      </div>
    
    </fieldset>
      
     
</div>
  );
};

export default Nav;
