// src/components/previews/ButtonPreview.js
import React from "react";

const ButtonPreview = ({ config }) => {
  const { label, backgroundColor, textColor, padding, borderRadius } = config;

  return (
    <button
      style={{
        backgroundColor: backgroundColor || "#007bff",
        color: textColor || "#ffffff",
        padding: padding || "10px 20px",
        borderRadius: borderRadius || "5px",
        border: "none",
        cursor: "pointer",
      }}
    >
      {label || "Click Me"}
    </button>
  );
};

export default ButtonPreview;
