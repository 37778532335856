import React, {useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import routes from "../../routes";
import {BsArrowLeftShort, BsArrowRightShort} from "react-icons/bs";
import {useDispatch, useSelector} from "react-redux";
import {addTab, removeTab, toggleSidebar, updateActiveTab} from "../../store/dashboardSlice";

const NewSideBar = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userData  = useSelector((store)=>store.user)
    const {DYNAMIC_APP_MENUS} = userData



    const dashboardData = useSelector(store=>store.dashboard)

    const {showSidebar} = dashboardData;
    const {allOpenTabs} = dashboardData;
    const {activeItem} = dashboardData;

    useEffect(()=>{
        if (activeItem && activeItem.path && activeItem.layout) {
            navigate(activeItem.layout + activeItem.path);
        }
        else{
            navigate("/admin")
        }

    },[activeItem])

  
    const handleSidebarMove = () => {
        dispatch(toggleSidebar())
    };

  
    const openTab = (path, layout) => {
      const existingTab = allOpenTabs.find((tab) => tab.path === path);
      if (existingTab && existingTab.layout !== layout) {
        console.log("THIS LOGIC IS UNKNOWN!")
        // setOpenTabs(
        //   openTabs.map((tab) => (tab.path === path ? { path, layout } : tab))
        // );
      } else if (!existingTab) {
        dispatch(addTab( { path, layout }));
      }
      dispatch(updateActiveTab({ path, layout }));
    };
  
    const closeTab = (path) => {
      dispatch(removeTab(path))
    };
  
    const isTabOpen = (path, layout) => {
      return allOpenTabs.some((tab) => tab.path === path && tab.layout === layout);
    };
  
    const handleSidebarItemClick = (path, layout) => {
      if (isTabOpen(path, layout)) {
        closeTab(path);
      } else {
        openTab(path, layout);
      }

    };
  
    const handleTabClick = (path, layout) => {
    //   const activeItem = { path, layout };
    //   setActiveItem(activeItem);
      if (!isTabOpen(path, layout)) {
        openTab(path, layout);
      }
    //   navigate(layout + path);
    dispatch(updateActiveTab({ path, layout })); ///THIS IS NOW WORKING WHY??
    // navigate(layout + path) //WORKING FOR UPDATING URL

    };
  
    const handleTabClose = (path) => {
      closeTab(path);
    //   navigate(activeItem.layout+ activeItem.path);
    };
    return (
        <div
          className={`sidebar-container  ${
            !showSidebar ? "" : "sidebar-collapsed"
          }`}
        >
          <div
            className="sidebar bg-skt-blue z-50"
            style={{
              width: !showSidebar ? "50px" : "220px",
              borderColor: "#B2BEB5",
              position: "fixed",
              top: 85,
              height: "100%",
            }}
          >
            <div style={{ position: "relative" }}>
            {!showSidebar ?<BsArrowRightShort
              className="bg-primary text-white bg-skt-light-blue absolute -right-2 text-2xl rounded-full cursor-pointer "
              onClick={handleSidebarMove}
            />: (
              <BsArrowLeftShort
              className="bg-primary text-white bg-skt-light-blue absolute -right-2 text-2xl rounded-full cursor-pointer "
              onClick={handleSidebarMove}
            />
            )}
              
            </div>
    
            {showSidebar && (
              <div>
                {routes.map((item, index) => {
                  if (DYNAMIC_APP_MENUS.includes(item.path)){return}
                  return(
                  <div
                    className=" hover:bg-skt-light-blue rounded-lg  text-white  m-2"
                    key={index}
                  >
                    <div
                      className={` p-3 font-semibold menu-item  ${
                        activeItem && activeItem.path === item.path
                          ? "bg-skt-light-blue rounded-lg"
                          : ""
                      }`}
                    >
                      {item.layout === "/admin" && (
                        <div className="flex">
                          <div 
                            onClick={() =>
                              handleSidebarItemClick(item.path, item.layout)
                            }
                          >
                            <Link
                              className="text-white flex "
                              to={item.layout + item.path}
                            >
                              <span className="mt-1 mr-2">{item.menuIcon}</span>
                              &nbsp;
                              {item.name}
                            </Link>
                          </div>
                     
                        </div>
                      )}
                    </div>
    
                  </div>
                  )
})}
              </div>
            )}
    
          </div>
    
          {allOpenTabs.length > 0 && (
            <div className="horizontal-tabs-container ">
              <div className={`horizontal-tabs-with-sidebar ${!showSidebar ?'ml-16':'ml-[230px]'}`}>
                {allOpenTabs.map(({ path, layout }, index) => (
                  <div
                    key={index}
                    className={`horizontal-tab hover:bg-skt-blue hover:text-white   ${
                      activeItem && activeItem.path === path ? "active-tab text-white bg-skt-blue " : ""
                    }`}
                    onClick={() => handleTabClick(path, layout)}
                  >
                    {
                      routes.find(
                        (route) => route.path === path && route.layout === layout
                      )?.name
                    }
                    <span  className="text-white"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleTabClose(path);
                      }}
                    >
                      &times;
                    </span>
                  </div>
                ))}
              </div>
    
              <br className={` ${!showSidebar ?'ml-[65px]':'ml-[233px]'} h-px fixed w-full mt-9  bg-skt-blue border-0 `}/>
    
              
    
            </div>
          )}
        </div>
      );
}

export default NewSideBar
