import React, {useEffect, useRef, useState} from "react";
import {GrDocumentDownload, GrDocumentUpload} from "react-icons/gr";

import Pagination from "./Pagination";
import DataTable from "./DataTable";
import * as XLSX from "xlsx";
import {FaCircleInfo} from "react-icons/fa6";
import {useSelector} from "react-redux";
import SearchBar from "./SearchBar";
import SearchValue from "./SearchValue";
import {GET_TABLE_DATA_API, GET_TABLE_NAMES_API, SAVA_DATA_API,} from "../../../utils/utils/constants";

const ManageData = () => {
  const userData = useSelector((store) => store.user);
  const { applicationName, userName } = userData;
  const [tableData, setTableData] = useState([]);
  const [tableNames, setTableNames] = useState([]);

  const [searchAccordion, setSearchAccordion] = useState("commonSearch");
  const tableNameRef = useRef("");

  // Pagination states
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  // Pagination Logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = tableData.slice(indexOfFirstItem, indexOfLastItem);

  const getTableNames = async () => {
    const schemaName = applicationName;
    const data = await fetch(`${GET_TABLE_NAMES_API}?schemaName=${schemaName}`);
    const tableNameJsonData = await data.json();
    setTableNames(tableNameJsonData);
  };

  useEffect(() => {
    getTableNames();
  }, []);

  const downloadCSV = () => {
    // Define the CSV content with a header row
    let csvContent = "data:text/csv;charset=utf-8,";

    // Add the header row from the keys of the first object in the array
    if (tableData.length > 0) {
      const headerRow = Object.keys(tableData[0]).join(",") + "\r\n";
      csvContent += headerRow;

      // Add the data rows
      tableData.forEach((row) => {
        const dataRow = Object.values(row).join(",") + "\r\n";
        csvContent += dataRow;
      });
    }

    // Create a URI from the CSV content
    const encodedUri = encodeURI(csvContent);

    // Create a link to download the file
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);

    // Set the download attribute with a filename
    const selectedTable = tableNameRef.current.value;

    link.setAttribute("download", `${selectedTable}.csv`);

    // Append the link to the document and trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up by removing the link element
    document.body.removeChild(link);
  };

  const getTableDetails = async () => {
    const schemaName = applicationName;
    const selectedTable = tableNameRef.current.value;

    const data = await fetch(
      `${GET_TABLE_DATA_API}?schemaName=${schemaName}&tableName=${selectedTable}`
    );
    const json = await data.json();

    const excludedColumns = new Set([
      "createdby",
      "updatedby",
      "created_at",
      "updated_at",
      // "id",
    ]);

    const tableData = json.map((item) => {
      const newItem = {};
      Object.keys(item).forEach((key) => {
        if (!excludedColumns.has(key)) {
          newItem[key] = item[key];
        }
      });
      return newItem;
    });

    setTableData(tableData);
  };

  const handleImportExcel = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = async (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "binary" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const raw_json = XLSX.utils.sheet_to_json(worksheet);
      const json = raw_json.map((entry) => ({
        ...entry,
        createdby: userName,
        updatedby: userName,
      }));

      const url = SAVA_DATA_API;
      const csvData = {
        schemaName: applicationName,
        tableName: tableNameRef.current.value,
        dataList: json,
      };
      console.log(csvData)

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(csvData),
      });

      // setTableData(json);
      getTableDetails();
      event.target.value = null;
    };
    reader.readAsBinaryString(file);
  };

  return (
    <div className="flex flex-col">
      <div className="flex py-2 border-y border-gray-400 ">
        <select
          ref={tableNameRef}
          name="tables"
          className="w-3/12 h-7 text-sm px-2 border border-gray-600"
        >
          <option> select a table</option>
          {tableNames &&
            tableNames.map((table) => (
              <option key={table} value={table}>
                {table}
              </option>
            ))}
        </select>
        <button
          onClick={getTableDetails}
          className=" border border-blue-600 text-blue-600 hover:bg-blue-500 hover:text-white ml-4 px-2 text-sm"
        >
          Get Table Data
        </button>
      </div>
      <div className="flex flex-wrap my-2">
        {tableData.length > 0 && (
          <button
            // onClick={exportToCSV}
            onClick={downloadCSV}
            className="text-blue-500 text-xs font-semibold flex mr-4"
          >
            <GrDocumentDownload className="pr-1 text-sm" />
            Export
          </button>
        )}

        <label className="text-blue-500 text-xs font-semibold flex mr-4 cursor-pointer">
          <GrDocumentUpload className="pr-1 text-sm" />
          Import
          <input
            type="file"
            accept=".xlsx, .csv"
            onChange={handleImportExcel}
            style={{ display: "none" }}
          />
        </label>

        <div className="flex">
          <FaCircleInfo className="text-blue-600" />
          <p className="text-xs text-skt-blue ml-1 bg-yellow-200 px-2 rounded-md">
            You can Export CSV file first , then you can add data in that , then
            Import that file again!
          </p>
        </div>

        <hr className="text-gray-500 bg-gray-900 w-full mt-2" />
      </div>

      <div className=" flex mb-4">
        <div className="border flex border-gray-300">
          <button
            onClick={() => setSearchAccordion("commonSearch")}
            className={`p-1 px-4 font-semibold text-xs ${
              searchAccordion == "commonSearch" ? "bg-gray-400 " : ""
            }`}
          >
            Common Search
          </button>
          <button
            onClick={() => setSearchAccordion("advanceSearch")}
            className={`p-1 px-4 font-semibold text-xs ${
              searchAccordion == "advanceSearch" ? "bg-gray-400 " : ""
            }`}
          >
            Advance Search
          </button>
        </div>
      </div>
      <div className="border-gray-400 bg-gray-400 mb-4 rounded-md  w-full">
        {searchAccordion == "commonSearch" && (
          <SearchValue
            tableName={tableNameRef.current.value}
            setResultData={setTableData}
          />
        )}

        {searchAccordion == "advanceSearch" && (
          <SearchBar
            setResultData={setTableData}
            tableName={tableNameRef.current.value}
          />
        )}
      </div>
      <div className="">
        <DataTable
          tableData={tableData}
          currentItems={currentItems}
          getTableDetails={getTableDetails}
          tableName={tableNameRef.current.value}
        />
        {tableData.length > 0 && tableData[0][Object.keys(tableData[0])[0]] ? (
          <Pagination
            tableData={tableData}
            setCurrentPage={setCurrentPage}
            currentPage={currentPage}
            itemsPerPage={itemsPerPage}
          />
        ) : null}
      </div>
    </div>
  );
};

export default ManageData;
