import React from "react";
import { FaTrashAlt, FaEdit } from "react-icons/fa";
import useFormStore from "../../../store/useFormStore";
import { Link } from "react-router-dom";
const FormButtons = ({ id }) => {
  const { deleteForm, updateForm } = useFormStore();
  return (
    <div className="flex items-center justify-center mt-4 space-x-4">
      <button
        onClick={() => deleteForm(id)}
        className="flex items-center space-x-2 px-4 py-2 bg-red-500 text-white rounded-lg shadow-md hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-300"
      >
        <FaTrashAlt />
        <span>Delete</span>
      </button>
      <Link
        to={`/admin/edit/${id}`}
        onClick={() => updateForm(id)}
        className="flex items-center space-x-2 px-4 py-2 bg-blue-500 text-white rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
      >
        <FaEdit />
        <span>Edit</span>
      </Link>
    </div>
  );
};

export default FormButtons;
