import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
    import useAuthStore from "../../store/useAuthStore";

const ProtectedRoutes = ({ children }) => {
  const navigate = useNavigate();
  const [isVerified, setIsVerified] = useState(false);
  const [loading, setLoading] = useState(true);
    const { isAuthenticated, login, logout } = useAuthStore();

  const isTokenPresent = () => {
    const key = "token";
    const token = localStorage.getItem(key);
    return token !== null;
  };

  useEffect(() => {
    const verifyUser = () => {

        if (isAuthenticated && isTokenPresent()){
        // console.log("Valid user ..");
        setIsVerified(true);
      } else {
        console.log("Invalid user...");
        navigate("/auth/signin", { replace: true });
        setIsVerified(true);
      }
      setLoading(false);
    };

    verifyUser();
  }, [navigate]);



  return isVerified ? <div>{children}</div> : null;
};

export default ProtectedRoutes;
