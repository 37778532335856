// export const questionData =[
//     {
//         "id": 8,
//         "options_type": "text",
//         "question_description": "TBD",
//         "question_id": "GQ01",
//         "question_name": "What is the name of your  application?",
//         "question_type": "initial",
//         "created_at": "2024-05-29 11:24:50.625",
//         "updated_at": "2024-05-29 11:24:50.625",
//         "createdby": "DUMMY_FOR_NOW",
//         "updatedby": "DUMMY_FOR_NOW"
//     },
//     {
//         "id": 9,
//         "options_type": "select",
//         "question_description": "TBD",
//         "question_id": "GQ02",
//         "question_name": "What type of application do you want to create?",
//         "question_type": "initial",
//         "created_at": "2024-05-29 11:24:50.625",
//         "updated_at": "2024-05-29 11:24:50.625",
//         "createdby": "DUMMY_FOR_NOW",
//         "updatedby": "DUMMY_FOR_NOW"
//     },
//     {
//         "id": 10,
//         "options_type": "select",
//         "question_description": "TBD",
//         "question_id": "GQ03",
//         "question_name": "What will be the structure of your Application?",
//         "question_type": "initial",
//         "created_at": "2024-05-29 11:24:50.625",
//         "updated_at": "2024-05-29 11:24:50.625",
//         "createdby": "DUMMY_FOR_NOW",
//         "updatedby": "DUMMY_FOR_NOW"
//     },
//     {
//         "id": 11,
//         "options_type": "multiselect",
//         "question_description": "TBD",
//         "question_id": "GQ04",
//         "question_name": "What are the key features or functionalities you want to include?",
//         "question_type": "initial",
//         "created_at": "2024-05-29 11:24:50.625",
//         "updated_at": "2024-05-29 11:24:50.625",
//         "createdby": "DUMMY_FOR_NOW",
//         "updatedby": "DUMMY_FOR_NOW"
//     },
//     {
//         "id": 12,
//         "options_type": "multiselect",
//         "question_description": "TBD",
//         "question_id": "GQ05",
//         "question_name": "What user roles do you want to include in your application?",
//         "question_type": "initial",
//         "created_at": "2024-05-29 11:24:50.625",
//         "updated_at": "2024-05-29 11:24:50.625",
//         "createdby": "DUMMY_FOR_NOW",
//         "updatedby": "DUMMY_FOR_NOW"
//     }
// ]


// {
//     "id": 74,
//     "createdby": "aaush",
//     "updatedby": "aaush",
//     "appid": null,
//     "flowid": null,
//     "userid": null,
//     "created_at": "2024-04-05T09:39:34.730+00:00",
//     "updated_at": "2024-04-05T09:39:34.730+00:00",
//     "question_id": "GQ74",
//     "question_description": "TBD",
//     "question_name": "Should access control dynamically adapt based on changing conditions and user behavior?",
//     "question_type": "General",
//     "option_type": "RadioButton"
// }

export const questionData = [
  {
    id: 8,
    options_type: "text",
    question_description: "TBD",
    question_id: "GQ01",
    question_name: "What is the name of your  application?",
    question_type: "initial",
    created_at: "2024-05-29 11:24:50.625",
    updated_at: "2024-05-29 11:24:50.625",
    createdby: "DUMMY_FOR_NOW",
    updatedby: "DUMMY_FOR_NOW",
  },
  {
    id: 9,
    options_type: "select",
    question_description: "TBD",
    question_id: "GQ02",
    question_name: "What kind of application you want to build ?",
    question_type: "initial",
    created_at: "2024-05-29 11:24:50.625",
    updated_at: "2024-05-29 11:24:50.625",
    createdby: "DUMMY_FOR_NOW",
    updatedby: "DUMMY_FOR_NOW",
  },
  {
    id: 10,
    options_type: "select",
    question_description: "TBD",
    question_id: "GQ03",
    question_name: "What kind of Mobile App ?",
    question_type: "initial",
    created_at: "2024-05-29 11:24:50.625",
    updated_at: "2024-05-29 11:24:50.625",
    createdby: "DUMMY_FOR_NOW",
    updatedby: "DUMMY_FOR_NOW",
  },
  {
    id: 11,
    options_type: "select",
    question_description: "TBD",
    question_id: "GQ04",
    question_name: "What do you want to Automate ?",
    question_type: "initial",
    created_at: "2024-05-29 11:24:50.625",
    updated_at: "2024-05-29 11:24:50.625",
    createdby: "DUMMY_FOR_NOW",
    updatedby: "DUMMY_FOR_NOW",
  },
  {
    id: 12,
    options_type: "multiselect",
    question_description: "TBD",
    question_id: "GQ05",
    question_name: "How many types of users will you have ?",
    question_type: "initial",
    created_at: "2024-05-29 11:24:50.625",
    updated_at: "2024-05-29 11:24:50.625",
    createdby: "DUMMY_FOR_NOW",
    updatedby: "DUMMY_FOR_NOW",
  },
];
