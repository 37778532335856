import React from 'react';
import { useStore } from '../../store';

const NavigationConfig = () => {
  const { navElements, addNavElement } = useStore();

  return (
    <section className="p-6 max-w-2xl mx-auto bg-slate-100 rounded-lg shadow-md">
      <h4 className="text-xl font-bold mb-4 text-slate-700">Navigation Configuration</h4>
      {navElements.map((_, index) => (
        <NavElement key={index} index={index} />
      ))}
      <div className="mt-4">
        <button onClick={addNavElement} className="p-2 bg-green-500 text-white rounded mr-2 hover:bg-green-600">
          Add Navigation Item
        </button>
        <button onClick={()=>console.log("route to preview of the app")
        } className="p-2 bg-blue-500 text-white rounded mr-2 hover:bg-blue-600">
          Preview
        </button>
        <button onClick={()=>console.log(navElements)
        } className="p-2 bg-blue-500 text-white rounded hover:bg-blue-600">
          Save Configuration
        </button>
      </div>
    </section>
  );
};

const NavElement = ({ index }) => {
  const { navActions, pages, updateNavElement, removeNavElement, navElements } = useStore();
  const navElement = navElements[index];

  const handleChange = (field, value) => {
    updateNavElement(index, field, value);
  };

  return (
    <div className="mb-4 p-4 bg-slate-50 rounded shadow-sm">
      <label className="block text-sm font-medium text-slate-700 mb-2">
        Link Name
        <input
          placeholder="Enter link name"
          value={navElement.name}
          onChange={(e) => handleChange('name', e.target.value)}
          className="mt-1 p-2 border border-slate-300 rounded w-full"
        />
      </label>

      <label className="block text-sm font-medium text-slate-700 mb-2">
        Action
        <select
          value={navElement.action}
          onChange={(e) => handleChange('action', e.target.value)}
          className="mt-1 p-2 border border-slate-300 rounded w-full"
        >
          <option value="">Select Action</option>
          {navActions.map((item, idx) => (
            <option key={idx} value={item}>
              {item}
            </option>
          ))}
        </select>
      </label>

      <label className="block text-sm font-medium text-slate-700 mb-2">
        Page
        <select
          value={navElement.page}
          onChange={(e) => handleChange('page', e.target.value)}
          className="mt-1 p-2 border border-slate-300 rounded w-full"
        >
          <option value="">Select Page</option>
          <option value="not configured yet">Not Configured Yet</option>
          {Object.keys(pages).map((item, idx) => (
            <option key={idx} value={item}>
              {item}
            </option>
          ))}
        </select>
      </label>

      <button
        onClick={() => removeNavElement(index)}
        className="mt-2 p-2 bg-red-500 text-white rounded hover:bg-red-600"
      >
        Remove
      </button>
      
      {/* Show Configure Page button if the selected page is "not configured yet" */}
      {navElement.page === "not configured yet" && (
        <button
          className="mt-2 space-x-4 p-2 bg-yellow-500 text-white rounded hover:bg-yellow-600"
          onClick={() => console.log(`Configure page for ${navElement.name}`)}
        >
          Configure Page
        </button>
      )}
    </div>
  );
};

export default NavigationConfig;
