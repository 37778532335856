import React, { useState } from "react";
import { Button, Modal } from "antd";
import "./formBuilder.css";

const AddNewData = ({
  isModalOpen,
  setIsModalOpen,
  handleElementData,
  setInputs,
  inputs,
  removeInput,
  index,
}) => {
  const [error, setError] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    // Check if any input field is empty
    if (inputs.some((value) => value.trim() === "")) {
      setError(true);
    } else {
      setError(false);
      // Call your submit handler
      handleElementData(event, index, "AddedNewRecords");
    }
  };

  return (
    <Modal
      title="Add field Names"
      open={isModalOpen}
      onCancel={() => setIsModalOpen(false)}
      footer={[
        <Button key="submit" type="primary" onClick={handleSubmit}>
          Submit
        </Button>,
      ]}
    >
      <form>
        <div id="inputsContainer">
          <Button type="primary" onClick={() => setInputs([...inputs, ""])}>
            Add Input +
          </Button>
          <br />
          <br />
          {/* Display input fields dynamically */}
          {inputs.map((value, idx) => (
            <div key={idx}>
              <input
                type="text"
                value={value}
                className="inputHeight"
                onChange={(e) => {
                  const newInputs = [...inputs];
                  newInputs[idx] = e.target.value;
                  setInputs(newInputs);
                }}
              />
              &nbsp;
              <Button type="primary" danger onClick={() => removeInput(idx)}>
                Delete
              </Button>
              <br />
              <br />
            </div>
          ))}
          {error && (
            <p style={{ color: "red" }}>
              Please fill all input fields otherwise delete.
            </p>
          )}
        </div>
      </form>
    </Modal>
  );
};

export default AddNewData;
