'use client';
import React, { useState } from 'react';
import { useStore } from '../../store';
import Notification from '../../services/Notification';
const PaymentConfig= () => {
    const {selectedPaymentMethods} = useStore();
    const {updatePaymentMethod,setShowPaymentConfigFalse} = useStore()
    
    const [currentMethod, setCurrentMethod] = useState(null);

    const handleSelectMethod = (method) => {
        setCurrentMethod(method);
    };

    const handleSaveConfiguration = async () => {
        // Send configuration to server this will be moved to global state and then imported here to make code neat
        await fetch('/api/saveConfiguration', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(selectedPaymentMethods),
        });
        Notification.success('success')
    };

    const handleFieldChange = (fieldName, value) => {
        if (currentMethod) {
            updatePaymentMethod(currentMethod.name, fieldName, value);
        }
    };

    return (
        <div className='flex flex-col gap-16 items-center justify-center h-svh w-full'>
            <button
            onClick={()=>setShowPaymentConfigFalse()}
    className='px-4 py-2 bg-slate-600 text-slate-100 rounded-md hover:bg-slate-500 transition-colors duration-300'
>
    Add Method
</button>
        <section className='flex flex-col md:flex-row  w-full items-center justify-center p-6 bg-slate-800 h-2/3 md:w-2/3 rounded-md'>
            {/* Selected Payment Methods Section */}
            <div className='w-full md:w-1/3 p-6 bg-slate-700 rounded-lg shadow-md'>
                <h2 className='text-2xl font-bold text-slate-100 mb-6 text-center'>Selected Payment Methods</h2>
                <div className='flex flex-col gap-4'>
                    {selectedPaymentMethods.length === 0 ? (
                        <p className='text-slate-300 text-center'>No payment methods selected.</p>
                    ) : (
                        selectedPaymentMethods.map((method, index) => (
                            <div
                                key={index}
                                className={`flex items-center p-4 rounded-lg shadow-md cursor-pointer transition-all duration-300 ${currentMethod && currentMethod.name === method.name ? 'bg-slate-600 border-slate-500' : 'bg-slate-700 border-slate-600 hover:bg-slate-600'}`}
                                onClick={() => handleSelectMethod(method)}
                            >
                                <img src={method.imageUrl} alt={method.name} className='w-16 h-16 mr-4 rounded-full' />
                                <div>
                                    <h3 className='text-lg font-semibold text-slate-100'>{method.name}</h3>
                                    <p className='text-slate-300'>{method.fields.length} Fields</p>
                                </div>
                            </div>
                        ))
                    )}
                </div>
            </div>

            {/* Configuration Form Section */}
            <div className='w-full md:w-2/3 p-6 flex flex-col items-center'>
                <h2 className='text-2xl font-bold text-slate-100 mb-6'>Configure Payment Method</h2>
                {currentMethod ? (
                    <div className='bg-slate-100 p-6 rounded-lg shadow-md w-full max-w-md'>
                        {currentMethod.fields.map((field, index) => (
                            <div key={index} className='mb-4'>
                                <label className='block text-slate-700 mb-1'>{field.label}</label>
                                <input
                                    type={field.type}
                                    value={field.value || ''}
                                    onChange={(e) => handleFieldChange(field.name, e.target.value)}
                                    className='w-full p-2 border border-slate-300 rounded-md'
                                />
                            </div>
                        ))}
                        <button
                            onClick={handleSaveConfiguration}
                            className='mt-6 px-6 py-3 bg-slate-600 text-slate-100 rounded-md hover:bg-slate-500 transition-colors duration-300'
                        >
                            Save Configuration
                        </button>
                    </div>
                ) : (
                    <p className='text-slate-300'>Select a payment method to configure.</p>
                )}
            </div>
        </section>
        </div>
    );
};

export default PaymentConfig;
