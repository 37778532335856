import React, {useState} from "react";
import {IoMdClose} from "react-icons/io";
import {getInputType} from "../../../utils/utils/helper";
import {useSelector} from "react-redux";
import {DELETE_DATA_API, UPDATE_DATA_API} from "../../../utils/utils/constants";

const ActionPopup = ({ rowData, closeFunction, action, reloadData ,tableName}) => {
  const userData = useSelector(store=>store.user)
  const {applicationName} = userData
  const [updatedData, setUpdatedData] = useState(rowData);
  const [editedCols, setEditedCols] = useState({
    schemaName: applicationName,
    tableName: tableName,
    id: rowData['id'],
    columnsToUpdate: {},
  });
  const editDataHandler = async () => {
    const url = UPDATE_DATA_API;

    // console.log("UPDATED DATA IS :::", updatedData);
    console.log("editedCols DATA IS :::", editedCols);
    //here add UpdateAPI Logic

    const response = await fetch(url, {
      method: "PUT", // Specifying the method
      headers: {
        "Content-Type": "application/json", // Specifying the content type
      },
      body: JSON.stringify(editedCols), // Converting the JavaScript object to a JSON string
    });

    //     {
    //     "schemaName": "applicationname",
    //     "tableName": "admin",
    //     "id": 3,
    //     "columnsToUpdate":  {
    //         "userid": 10209222,
    //         "appid": 2022222,
    //         "folwid": 302222,
    //         "name": "AAUSHHHHHH",
    //         "salary": 55002939930
    //     }
    // }
    closeFunction(false);
    reloadData();

    // const data = await response.json(); // not work as shilpa is still workin on this
    // console.log('Update successful:', data);
  };

  const inputEditHandler = (e, inputField) => {
    let newValue;
    const originalValue = rowData[inputField];
    const inputValue = e.target.value;
  
    // Check for an empty string and handle accordingly
    if (inputValue === '') {
      newValue = inputValue; // Keep it as an empty string or set a default value
    } else if (typeof originalValue === 'number') {
      newValue = isNaN(Number(inputValue)) ? 0 : Number(inputValue);
    } else {
      newValue = inputValue;
    }
  
    // Update editedCols with the correctly typed value
    editedCols.columnsToUpdate[inputField] = newValue;
  
    // Update the local state for re-rendering the input with the new value
    setUpdatedData(prev => ({
      ...prev,
      [inputField]: newValue,
    }));
  };
  
  const deleteDataHandler = async () => {
    const pk = rowData["id"];
    const schemaName =applicationName;
    const response = await fetch(
      `${DELETE_DATA_API}/${schemaName}/${tableName}/${pk}`,
      {
        method: "DELETE",
      }
    );
    closeFunction(false);
    // const data = await response.json();

    const data = response;
    console.log("Delete response:", data);
    reloadData();
    // Handle success response
  };
  return (
    <div className="fixed  bg-black shadow-lg  flex justify-center items-center bg-opacity-50 inset-0">
      <div className="bg-white  p-4 rounded-lg relative w-6/12 flex flex-col">
        <button
          className="absolute top-0 right-0 text-2xl px-1"
          onClick={() => {
            setUpdatedData(rowData);
            closeFunction(false);
          }}
        >
          <IoMdClose />
        </button>
        <div className="grid grid-cols-2 ml-20">
          {Object.keys(updatedData).filter(key => key !== "id").map((data) => (
            <div className="my-2">
              <label className="font-bold"> {data}</label>
              <br></br>
              <input
                className="text-sm border-2 border-gray-400 p-2 rounded-lg w-8/12 mr-2"
                placeholder={updatedData[data]}
                required
                type={getInputType(updatedData[data])}
                // value={`${action == "delete" ? rowData[data] : ""}`}
                value={updatedData[data]}
                onChange={(e) => inputEditHandler(e, data)}
              />
            </div>
          ))}
        </div>
        {action == "edit" ? (
          <button
            className="text-xl bg-green-600 text-white p-2 font-semibold rounded-md m-2"
            onClick={editDataHandler}
          >
            {" "}
            Edit the selected data!{" "}
          </button>
        ) : (
          <button
            className="text-xl bg-red-600 text-white p-2 font-semibold rounded-md m-2"
            onClick={deleteDataHandler}
          >
            {" "}
            Delete the selected data!{" "}
          </button>
        )}
      </div>
    </div>
  );
};

export default ActionPopup;
