import React from "react";

const InputField = ({
  label,
  type,
  name,
  value,
  onChange,
  checked,
  placeholder,
}) => {
  // Defining classes for different input types (checkbox/radio and others)
  const baseInputClasses =
    "w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline transition duration-300 ease-in-out";

  const checkboxRadioClasses =
    "form-checkbox h-4 w-4 text-blue-600 transition duration-300 ease-in-out cursor-pointer rounded-lg ";

  return (
    <div className="mb-4">
      {type === "checkbox" || type === "radio" ? (
        <label className="inline-flex items-center">
          <input
            type={type}
            name={name}
            value={value}
            checked={checked}
            onChange={onChange}
            className={checkboxRadioClasses}
          />
          <span className="ml-2 text-sm text-gray-700">{label}</span>
        </label>
      ) : (
        <div>
          <label
            className="block mb-2 text-sm font-medium text-gray-700"
            htmlFor={name}
          >
            {label}
          </label>
          <input
            id={name}
            type={type}
            name={name}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            className={`${baseInputClasses} ${
              type === "number" ? "appearance-none" : ""
            }`}
          />
        </div>
      )}
    </div>
  );
};

export default InputField;