import { useState, useEffect, useCallback } from "react";
import useComponentStore from "../../../../../store/useComponentsStore";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa6";

const CarouselComponent = () => {
    const { carouselData } = useComponentStore();
    const [currentSlide, setCurrentSlide] = useState(0);
    const [isPaused, setIsPaused] = useState(false);
    const totalSlides = carouselData?.length || 0;

    const goToNextSlide = useCallback(() => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides);
    }, [totalSlides]);

    const goToPreviousSlide = useCallback(() => {
        setCurrentSlide((prevSlide) =>
            prevSlide === 0 ? totalSlides - 1 : prevSlide - 1
        );
    }, [totalSlides]);

    useEffect(() => {
        if (isPaused || totalSlides === 0) return;
        const interval = setInterval(goToNextSlide, 3000);
        return () => clearInterval(interval);
    }, [goToNextSlide, isPaused, totalSlides]);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === "ArrowRight") goToNextSlide();
            if (e.key === "ArrowLeft") goToPreviousSlide();
        };
        window.addEventListener("keydown", handleKeyDown);
        return () => window.removeEventListener("keydown", handleKeyDown);
    }, [goToNextSlide, goToPreviousSlide]);

    return (
        <div
            className="relative overflow-hidden h-[400px] w-full rounded-lg shadow-lg"
            onMouseEnter={() => setIsPaused(true)}
            onMouseLeave={() => setIsPaused(false)}
        >
            {totalSlides === 0 ? (
                <p>No carousel data available.</p>
            ) : (
                <>
                    <div className="flex transition-transform duration-500 ease-in-out w-full">
                        {carouselData.map((item, index) => (
                            <div
                                key={index}
                                className={`min-w-full flex flex-col items-center justify-center transition-opacity duration-500 ease-in-out ${
                                    index === currentSlide ? "opacity-100 z-10" : "opacity-0"
                                }`}
                                style={{
                                    transform: `translateX(${-(currentSlide * 100)}%)`,
                                }}
                            >
                                <img
                                    src={item.imageSrc}
                                    alt={item.altText}
                                    loading="lazy"
                                    className="w-full h-full object-cover rounded-lg"
                                />
                                <div
                                    className="bottom-0 left-0 right-0 bg-dark bg-opacity-50 p-5 text-white"
                                    style={{ position: "relative", top: "-63%" }}
                                >
                                    <h3
                                        className="text-xl my-2 text-center font-semibold"
                                        style={{ color: item.titleColor }}
                                    >
                                        {item.title}
                                    </h3>
                                    <p
                                        className="text-center"
                                        style={{ color: item.descriptionColor }}
                                    >
                                        {item.description}
                                    </p>
                                    {item.altText && (
                                        <span className="text-sm" style={{ color: item.altTextColor }}>
                                            {item.altText}
                                        </span>
                                    )}
                                </div>
                                <div className="relative -top-[60%]">
                                    {item.buttons &&
                                        item.buttons.map((button, buttonIndex) => (
                                            <button
                                                key={buttonIndex}
                                                className={`p-2 rounded mr-3 ${
                                                    button.alignment === "left"
                                                        ? "mr-auto"
                                                        : button.alignment === "center"
                                                        ? "mx-auto"
                                                        : "ml-auto"
                                                }`}
                                                style={{ backgroundColor: button.color }}
                                            >
                                                {button.text || `Button ${buttonIndex + 1}`}
                                            </button>
                                        ))}
                                </div>
                            </div>
                        ))}
                    </div>
                    <button
                        onClick={goToPreviousSlide}
                        className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-white text-black rounded-full p-2 shadow-lg z-20"
                    >
                        <FaArrowLeft />
                    </button>
                    <button
                        onClick={goToNextSlide}
                        className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-white text-black rounded-full p-2 shadow-lg z-20"
                    >
                        <FaArrowRight />
                    </button>
                    {/* Indicator buttons */}
                    <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
                        {carouselData.map((_, index) => (
                            <button
                                key={index}
                                className={`w-3 h-3 rounded-full ${
                                    index === currentSlide ? "bg-blue-600" : "bg-gray-400"
                                }`}
                                onClick={() => setCurrentSlide(index)}
                            />
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

export default CarouselComponent;
