import React, {useState} from "react";
import {CiTrash} from "react-icons/ci";
import {useSelector} from "react-redux";

const NavbarConfigurator = ({ onSave }) => {
  const [logoUrl, setLogoUrl] = useState("");
  const [logoPosition, setLogoPosition] = useState("left");
  const [navLinks, setNavLinks] = useState([{ text: "", url: "" }]);
  const [buttonText, setButtonText] = useState("");
  const [buttonUrl, setButtonUrl] = useState("");
  const [buttonStyle, setButtonStyle] = useState(
    "bg-blue-500 text-white hover:bg-blue-700"
  );
  const [buttonPosition, setButtonPosition] = useState("none");

  console.log("NAVLINKS",navLinks)
  const selector =  useSelector(store=>store.user)
  const {colorTheme} =selector 
  console.log(colorTheme)

  const formSubmitHandler = () => {
    const data = {
      navbar: {
        style: {
          backgroundColor: colorTheme.primaryColor,
          color: colorTheme.textColor,
          hoverColor: colorTheme.secondaryColor,
          activeColor: colorTheme.textColor,
          fontFamily: "Arial, sans-serif",
          fontSize: "16px",
          padding: "10px 20px",
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
          transition: "background-color 0.3s ease",
        },
        position: {
          type: "fixed",
          location: "top",
        },
        logo: {
          // imageUrl:
          //   "https://cdn.dribbble.com/userupload/11764588/file/original-4165ef475e94ba4104940d4cdab8f760.png?resize=400x300",
          imageUrl:logoUrl,
          link: "/home",
          altText: "Project Logo",
          style: {
            width: "50px",
            height: "auto",
          },
        },
        links:navLinks,
        buttons: [
          {
            text: buttonText,
            url: buttonUrl,
            style: {
              backgroundColor: colorTheme.secondaryColor,
              color: colorTheme.textColor,
              border: "none",
              padding: "10px 20px",
              borderRadius: "5px",
              cursor: "pointer",
              hoverBackgroundColor: "#0056b3",
            },
          },
        ],
      
      },
    };
  
  console.log(data)

  };

  const handleNavLinkChange = (index, key, value) => {
    const updatedLinks = [...navLinks];
    updatedLinks[index][key] = value;
    setNavLinks(updatedLinks);
  };

  const addNavLink = () => {
    setNavLinks([...navLinks, { text: "", url: "" }]);
  };

  const removeNavLink = (index) => {
    setNavLinks(navLinks.filter((_, idx) => idx !== index));
  };

  const handleSubmit = () => {
    const config = {
      logo: { url: logoUrl, position: logoPosition },
      navLinks,
      button: {
        text: buttonText,
        url: buttonUrl,
        style: buttonStyle,
        position: buttonPosition,
      },
    };
    onSave(config);
  };

  return (
    <div className="p-4">
      <h2 className="text-xl font-semibold mb-2 text-center border-y border-gray-200">
        NAVBAR CONFIGURATION FORM
      </h2>
      <h3 className="font-semibold">Logo Settings:</h3>

      <div className="flex gap-4 p-2 bg-gray-300 rounded-md">
        <div>
          <label htmlFor="logoUrl">Select Logo Image:</label>
          <select
            id="logoUrl"
            value={logoUrl}
            onChange={(e) => setLogoUrl(e.target.value)}
            className="ml-2 border rounded p-1 w-full"
          >
            <option value="Image1">Image1</option>
            <option value="Img2">Project Image</option>
            <option value="Img3">Logo</option>
          </select>
        </div>
        <div>
          <label htmlFor="logoPosition">Select logo position:</label>
          <select
            id="logoPosition"
            value={logoPosition}
            onChange={(e) => setLogoPosition(e.target.value)}
            className="ml-2 border rounded p-1 w-full"
          >
            <option value="left">Left</option>
            <option value="middle">Middle</option>
            <option value="right">Right</option>
          </select>
        </div>
      </div>

      <div className="mt-4">
        <h3 className="font-semibold">Navigation Links:</h3>
        <div className="p-2 bg-gray-300 rounded-md">
          {navLinks.map((link, index) => (
            <div key={index} className="flex items-center mb-2 w-6/12">
              <input
                type="text"
                placeholder="Text"
                value={link.text}
                onChange={(e) =>
                  handleNavLinkChange(index, "text", e.target.value)
                }
                className="border rounded p-1 mr-2"
              />
              <select
                id="selectUrl"
                value={navLinks[index]['url']}
                onChange={(e) =>
                  handleNavLinkChange(index, "url", e.target.value)
                }
                className="ml-2 border rounded p-1 w-full"
              >
                <option value="">Select screen </option>
                <option value="Homepage">Home </option>
                <option value="Aboutpage">About </option>
                <option value="Contactuspage">Contact us </option>
              </select>
              <button
                onClick={() => removeNavLink(index)}
                className="ml-2 text-red-800 bg-red-200 rounded-lg p-2"
              >
                <CiTrash />
              </button>
            </div>
          ))}

          <button
            onClick={addNavLink}
            className="text-white rounded-lg font-semibold p-2 bg-gray-700 "
          >
            Add +
          </button>
        </div>
      </div>

      <div className="mt-4">
        <h3 className="font-semibold">Button settings:</h3>
        <div className="p-2 bg-gray-300 rounded-md">
          <div className="mt-4">
            <label htmlFor="buttonText">Button Text:</label>
            <input
              id="buttonText"
              type="text"
              value={buttonText}
              onChange={(e) => setButtonText(e.target.value)}
              className="ml-2 border rounded p-1"
            />
            <select
              id="selectUrl"
              value={buttonUrl}
              onChange={(e) => setButtonUrl(e.target.value)}
              className="ml-2 border rounded p-1 w-4/12"
            >
              <option value="">Select button action </option>
              <option value="Homepage">Home </option>
              <option value="Aboutpage">About </option>
              <option value="Contactuspage">Contact us </option>
            </select>
          </div>
        </div>
      </div>

      <button
        onClick={formSubmitHandler}
        className="mt-4 bg-blue-500 text-white p-2 rounded hover:bg-blue-700"
      >
        Save Configuration
      </button>
    </div>
  );
};

export default NavbarConfigurator;
