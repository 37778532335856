import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { AiOutlineSetting, AiOutlineHome, AiOutlinePicture } from "react-icons/ai";
import { BsLayers, BsFileText } from "react-icons/bs";
import { FiLayout } from "react-icons/fi";
import { MdOutlineDashboard } from "react-icons/md";
import { AiOutlineForm } from "react-icons/ai";
import { AiOutlineFileText } from "react-icons/ai";

const Welcome = () => {
  const routes = [
  "/config-steps/welcome",
  "/config-steps/onboarding",
  "/config-steps/header",
  // "/config-steps/footer",
  // "/config-steps/form",
  // "/config-steps/components",
  // "/config-steps/page",
  // "/config-steps/assets",
  // "/config-steps/styles",
];
  const location = useLocation();
   const navigate = useNavigate();
  const application_name = localStorage.getItem("application_name");

  const [isVisible, setIsVisible] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const handleScroll = () => {
    const currentScrollY = window.scrollY;
    setIsVisible(currentScrollY <= lastScrollY);
    setLastScrollY(currentScrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollY]);
const NavigationButtons = () => {
  const currentIndex = routes.indexOf(location.pathname);

  // Calculate previous and next paths
  const prevPath = currentIndex > 0 ? routes[currentIndex - 1] : null;
  const nextPath = currentIndex < routes.length - 1 ? routes[currentIndex + 1] : null;

  return (
    <div className="flex justify-between mt-4 px-4">
      {/* {prevPath && (
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded"
          onClick={() => navigate(prevPath)}
        >
          Back
        </button>
      )}
      {nextPath && (
        <button
          className="bg-blue-500 text-white px-4 py-2 rounded ml-auto"
          onClick={() => navigate(nextPath)}
        >
          Next
        </button>
      )} */}
      {prevPath && (
        <button
          className="fixed bottom-4 left-4 bg-blue-500 text-white px-4 py-2 rounded shadow-lg"
          onClick={() => navigate(prevPath)}
        >
          Back
        </button>
      )}
      {nextPath && (
        <button
          className="fixed bottom-4 right-4 bg-blue-500 text-white px-4 py-2 rounded shadow-lg"
          onClick={() => navigate(nextPath)}
        >
          Next
        </button>
      )}
    </div>
  );
};
  return (
    <div className="flex flex-col bg-color-primary-light min-h-screen items-center gap-4">
      {/* Header */}
      <div className="w-full bg-gradient-to-r from-blue-500 to-indigo-600 text-white text-center p-3 shadow-md">
        <p className="text-lg font-medium text-gray-800">
          <span className="font-semibold">
            {application_name ? application_name : "Your app name will be here"}
          </span>
        </p>
      </div>

      {/* Navigation with Gradient, Icons, and Adjusted Sizes */}
      <div
        className={`flex flex-wrap  justify-center  items-center  rounded-lg md:rounded-full gap-6 py-5 px-8 backdrop-blur-md bg-white/50 border
        border-white/20 shadow-xl transition-all duration-100 ease-in-out transform
            ${isVisible ? "translate-y-0 opacity-100" : "-translate-y-full opacity-0"}`}
      >
        {[
          { to: "/config-steps/welcome", text: "Welcome", icon: <AiOutlineHome /> },
          { to: "/config-steps/onboarding", text: "Onboarding", icon: <FiLayout /> },
          { to: "/config-steps/header", text: "Header", icon: <MdOutlineDashboard /> },
          // { to: "/config-steps/footer", text: "Footer", icon: <AiOutlineFileText /> },
          // { to: "/config-steps/form", text: "Form", icon: <AiOutlineForm /> },
          // { to: "/config-steps/components", text: "Components", icon: <BsLayers /> },
          // { to: "/config-steps/page", text: "Page", icon: <BsFileText /> },
          // { to: "/config-steps/assets", text: "Assets", icon: <AiOutlinePicture /> },
          // { to: "/config-steps/styles", text: "Styles", icon: <AiOutlineSetting /> },
        ].map(({ to, text, icon }) => (
          <div key={to} className="flex flex-col items-center">
            <Link
              to={to}
              className={`flex items-center gap-2 text-sm font-medium px-4 py-2 rounded-full transition-all duration-200
                ${
                  location.pathname === to
                    ? "bg-gradient-to-r from-purple-500 to-indigo-500 text-white"
                    : "text-gray-800 bg-white/50 hover:bg-gradient-to-r hover:from-purple-400 hover:to-indigo-400 hover:text-white"
                }
                shadow-lg transform hover:scale-110 hover:shadow-xl`}
            >
              <span className="text-lg sm:text-xl md:text-2xl lg:text-2xl">{icon}</span>
              <span className="hidden sm:inline">{text}</span>
            </Link>
          </div>
        ))}
      </div>
      <Outlet />
      <NavigationButtons />
    </div>
  );
};

export default Welcome;
