import React, {useState} from "react";
import {dummy_layout} from "../../../../assets/images";
import {layoutData} from "../../../preview/layout";


const LayoutPopup = ({ imgList, onClose, setSelectedLayout,setComponentsData }) => {
  const [selectedImageId, setSelectedImageId] = useState(null);

  
function findComponentsById( givenId) {
  for (let i = 0;i<layoutData.length;i++){
    if(layoutData[i].layoutId ===givenId){
      return layoutData[i].layoutComponents
    }
  }
 
  return []; 
}

  const handleImageClick = (layoutId) => {
    setSelectedImageId(layoutId);
    
  };

  const handleSubmit = () => {
    setSelectedLayout(selectedImageId);
    let data = findComponentsById(selectedImageId)
    setComponentsData(data)
    onClose();
  };

  return (
    <div className="fixed inset-0  z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-3/4 max-w-3xl">
        <h2 className="text-xl font-bold mb-4">Select an Image</h2>
        <div className="grid grid-cols-3 gap-4">
          {layoutData.map((item) => (
            <div
              key={item.layoutId}
              className={`p-2 border-2 w-40 h-40 cursor-pointer ${
                selectedImageId === item.layoutId
                  ? "border-blue-500"
                  : "border-gray-300"
              }`}
              onClick={() => handleImageClick(item.layoutId)}
            >
              <img
                src={dummy_layout}
                alt={`layout${item.layoutId}`}
                className="w-full h-full"
              />
            </div>
          ))}
        </div>
        <div className="flex w-full justify-center mt-4">
          <button
            onClick={handleSubmit}
            className="px-4 w-full py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-700"
          >
            Select layout
          </button>
        </div>
        <p>You can configure the components according to your requirements in components section.</p>
       
      </div>
    </div>
  );
};

export default LayoutPopup;
