import React from "react";
import { FiPhone, FiMail, FiMapPin, FiHome, FiUser, FiGlobe } from "react-icons/fi";
import useComponentStore from "../../../../../store/useComponentsStore";
const CallToAskComponent = () => {
    const { callToAskData } = useComponentStore();

    // Function to get the icon component by name with dynamic styles
    const getIconByName = (iconName, iconStyle) => {
        const defaultStyle = {
            background: iconStyle?.background || "orange",
            padding: "12px",
            height: iconStyle?.height || "60px",
            width: iconStyle?.width || "60px",
            borderRadius: iconStyle?.borderRadius || "12px",
            color: iconStyle?.color || "white",
        };

        switch (iconName) {
            case 'phone':
                return <FiPhone style={defaultStyle} size={40} />;
            case 'email':
                return <FiMail style={defaultStyle} size={40} />;
            case 'address':
                return <FiMapPin style={defaultStyle} size={40} />;
            case 'user':
                return <FiUser style={defaultStyle} size={40} />;
            case 'home':
                return <FiHome style={defaultStyle} size={40} />;
            case 'globe':
                return <FiGlobe style={defaultStyle} size={40} />;
            default:
                return <FiPhone style={defaultStyle} size={40} />;
        }
    };

    return (
        <div className={`relative pb-6 mb-10 text-${callToAskData.textAlign}`} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            {/* Title Section */}
            <h1
                className={`font-extrabold mb-0 text-${callToAskData.textAlign}`}
                style={{ color: callToAskData.titleColor, fontFamily: callToAskData.titleFontFamily }}
            >
                {callToAskData.title}
            </h1>
            <h5
                className={`font-extrabold uppercase text-${callToAskData.textAlign}`}
                style={{ color: callToAskData.subtitleColor, fontFamily: callToAskData.subtitleFontFamily }}
            >
                {callToAskData.subtitle}
            </h5>

            {/* Call Section */}
            <div className="flex items-center mb-4">
                {getIconByName(callToAskData.callIcon || 'phone', {
                    background: callToAskData.callIconBgColor,
                    height: callToAskData.callIconHeight,
                    width: callToAskData.callIconWidth,
                    color: callToAskData.callIconColor,
                    borderRadius: callToAskData.callIconBorderRadius
                })}
                <div>
                    <p style={{ color: callToAskData.subtitleColor, margin: "12px 0",  padding:"0px 12px"}}>
                        {callToAskData.enquiryTypeCall || "General Enquiry"}
                    </p>
                    <p style={{ color: callToAskData.subtitleColor, margin: "12px 0", padding: "0px 12px" }}>
                        {/* <strong>Phone:</strong> */}
                         {callToAskData.phone || "Your Phone Number"}
                    </p>
                </div>
            </div>

            {/* Email Section */}
            <div className="flex items-center mb-4">
                {getIconByName(callToAskData.emailIcon || 'email', {
                    background: callToAskData.emailIconBgColor,
                    height: callToAskData.emailIconHeight,
                    width: callToAskData.emailIconWidth,
                    color: callToAskData.emailIconColor,
                    borderRadius: callToAskData.emailIconBorderRadius
                })}
                <div>
                    <p style={{ color: callToAskData.subtitleColor, margin: "12px 0" , padding: "0px 12px" }}>
                        {/* <strong>Enquiry Type:</strong>  */}
                        {callToAskData.enquiryTypeEmail || "Support Enquiry"}
                    </p>
                    <p style={{ color: callToAskData.subtitleColor, margin: "12px 0", padding: "0px 12px" }}>
                        {/* <strong>Email:</strong> */}
                         {callToAskData.email || "Your Email Address"}
                    </p>
                </div>
            </div>

            {/* Address Section */}
            <div className="flex items-center mb-4">
                {getIconByName(callToAskData.addressIcon || 'address', {
                    background: callToAskData.addressIconBgColor,
                    height: callToAskData.addressIconHeight,
                    width: callToAskData.addressIconWidth,
                    color: callToAskData.addressIconColor,
                    borderRadius: callToAskData.addressIconBorderRadius
                })}
                <div>
                    <p style={{ color: callToAskData.subtitleColor, margin: "12px 0", padding: "0px 12px" }}>
                        {/* <strong>Enquiry Type:</strong>  */}
                        {callToAskData.enquiryTypeOffice || "Office Visit"}
                    </p>
                    <p style={{ color: callToAskData.subtitleColor, margin: "12px 0", padding: "0px 12px" }}>
                        {/* <strong>Address:</strong> */}
                         {callToAskData.address || "Your Address"}
                    </p>
                </div>
            </div>

        </div>
    );
};

export default CallToAskComponent;
