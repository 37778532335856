import {configureStore} from "@reduxjs/toolkit";
import dashboardSlice from "./dashboardSlice";
import userSlice from "./userSlice";
// import authorizationSlice from "./authorizationSlice";


const appStore = configureStore({
    reducer:{
        dashboard:dashboardSlice,
        user:userSlice,
        // authorization:authorizationSlice,
    }

})

export default appStore;