import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import routes from "../routes";

class AuthLayout extends React.Component {
  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.path}
            element={<prop.component key={key} />}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  render() {
    return (
      <div className="flex justify-center ">
        <Routes>
          {this.getRoutes(routes)}
          <Route path="*" element={<Navigate to="/auth/signup" />} />
        </Routes>
      </div>
    );
  }
}

export default AuthLayout;
