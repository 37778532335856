import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuthStore from "../store/useAuthStore";
import "./welcomepage.css";

const WelcomePage = () => {
  const navigate = useNavigate();
  const [showRemaining, setShowRemaining] = useState(false);
  const { isAuthenticated, login, logout } = useAuthStore();

  const handleProceedClick = () => {
    navigate("/config-steps/welcome");
  };

  const token = localStorage.getItem("token");
  //  console.log("Current token:", token);

  useEffect(() => {
    if (!token && !isAuthenticated) {
      navigate("/auth/signin", { replace: true });
    }
  }, [token, navigate]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowRemaining(true); // Show remaining text after 2 seconds (time of typing animation)
    }, 2000);

    return () => clearTimeout(timer); // Cleanup on component unmount
  }, []);

  return (
    <div className="flex items-center justify-center min-h-screen w-full bg-color-primary-light">
      <div className="relative flex flex-col items-center justify-center text-center">
        <h1 className="mb-5 text-2xl text-gray-300 font-bold flex items-center whitespace-nowrap">
          {" "}
          {/* Added whitespace-nowrap */}
          Thanks for Choosing{" "}
          <div className="typing-container inline-flex ml-3">
            {" "}
            {/* Reduced margin */}
            <div className="typed-out">
              <span className="text-color-white">
                MUDUMB<span className="text-color-secondary">AI</span>
              </span>
            </div>
          </div>
        </h1>
        {showRemaining && ( // Conditional rendering for remaining text
          <>
            <p className="mb-5 text-lg text-white opacity-0 animate-slideIn">
              Modern Unification for Development, Upkeep, and Maintenance of
              Business Applications and Infrastructure
            </p>
            <button
              onClick={handleProceedClick}
              className="px-4 py-2 text-lg text-white bg-color-secondary rounded hover:bg-orange-500 font-semibold focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 mt-5 opacity-0 animate-slideIn delay-500"
            >
              Let's start
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default WelcomePage;
