import React, {useState} from "react";

const BannerConfiguration = () => {
  const [bannerPosition, setBannerPosition] = useState("IRTF");
  const [paragraphText, setParagraphText] = useState("");
  const [headingText, setHeadingText] = useState("");
  const [subheadingText, setSubheadingText] = useState("");
  const [selectedImage, setSelectedImage] = useState("");

  const handleSubmit = () => {
    const compData = {
      banner: {
        image: {
          src: selectedImage,
          position: bannerPosition === "IRTF" ? "right" : "left",
        },
        heading: {
          text: headingText,
          color: "#FFD700",
          fontSize: "2.5rem",
        },
        subheading: {
          text: subheadingText,
          color: "#ffffff",
          fontSize: "3.5rem",
        },
        paragraph: {
          text: paragraphText,
          color: "#cccccc",
          fontSize: "1rem",
        },
        button: {
          text: "Order Now",
          backgroundColor: "#FFC107",
          color: "#000000",
          link: "/order",
        },
        contact: {
          phone: "000 123 456 789",
          website: "www.website.com",
        },
        backgroundColor: "#222831",
      },
    };
    // Axios.post()
  };

  return (
    <div className="">
      {" "}
      <h2 className="text-xl font-semibold mb-2 text-center border-y border-gray-200">
        BANNER CONFIGURATION FORM
      </h2>
      <div className="flex justify-start bg-gray-300 rounded-md">
        <div className=" p-2 rounded-md  ">
          <h1 className="font-semibold"> Banner and Textarea positions:</h1>

          <select
            value={bannerPosition}
            onChange={(e) => setBannerPosition(e.target.value)}
            className="p-2 rounded-md px-2 border border-gray-300"
            name=""
            id=""
          >
            <option value="IRTF">Image right Textarea Left</option>
            <option value="ILTR">Image left Textarea Right</option>
          </select>
        </div>

        <div className=" p-2 rounded-md px-5  ">
          <h1 className="font-semibold"> Select banner image:</h1>

          <select
            value={selectedImage}
            onChange={(e) => setSelectedImage(e.target.value)}
            className="p-2 rounded-md w-full px-2 border border-gray-300"
            name=""
            id=""
          >
            <option value="IMAGE1">IMAGE-1</option>
            <option value="IMAGE2">IMAGE-2</option>
            <option value="IMAGE3">IMAGE-3</option>
            <option value="IMAGE4">IMAGE-4</option>
          </select>
        </div>
      </div>
      <div className=" justify-start bg-gray-300 rounded-md  my-2 ">
        <div className="w-5/12 p-4">
          <h1 className="font-semibold">Textarea Inputs</h1>
          <input
            value={headingText}
            onChange={(e) => setHeadingText(e.target.value)}
            type="text"
            className=" my-2 border border-gray-200 p-2 rounded-md w-full"
            placeholder="Heading Text"
          />
          <input
            value={subheadingText}
            onChange={(e) => setSubheadingText(e.target.value)}
            type="text"
            className=" my-2 border border-gray-200 p-2 rounded-md w-full"
            placeholder="Sub-Heading Text"
          />
          <textarea
            value={paragraphText}
            onChange={(e) => setParagraphText(e.target.value)}
            className="p-2 my-2 border border-gray-200  rounded-md w-full"
            placeholder="Paragraph Text"
          />
        </div>
      </div>
      <button
        // onClick={handleSubmit}
        className="mt-4 bg-blue-500 text-white p-2 rounded hover:bg-blue-700"
      >
        Save Configuration
      </button>
    </div>
  );
};

export default BannerConfiguration;
