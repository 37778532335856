// export const sortButton = (arr) => {
//   const newArray = [];
//   arr.forEach((item, index) => {
//     if (item.FormElement === "Button") {
//       newArray.push(item);
//     } else {
//       newArray.unshift(item);
//     }
//   });
//   return newArray;
// };

//abhishek

export const sortButton = (arr) => {
  const newArray = [];
  let buttonField = null;
  arr.forEach((item, index) => {
    if (item.FormElement != "Button") {
      newArray.push(item);
    } else {
      buttonField = item;
    }
  });
  if (buttonField) newArray.push(buttonField);
  return newArray;
};
