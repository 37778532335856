import TextForm, { TextPreview, getDefaultTextConfig } from "./TextForm";
import ButtonForm, {
  ButtonPreview,
  getDefaultButtonConfig,
} from "./ButtonForm";
import ImageForm, { ImagePreview, getDefaultImageConfig } from "./ImageForm";
import DropdownForm, {
  DropdownPreview,
  getDefaultDropdownConfig,
} from "./DropdownForm";
import AutocompleteForm, {
  AutocompletePreview,
  getDefaultAutocompleteConfig,
} from "./AutocompleteForm";
import TextInputForm, {
  TextInputPreview,
  getDefaultInputConfig,
} from "./TextInputForm";
import TextAreaForm, {
  TextAreaPreview,
  getDefaultTextAreaConfig,
} from "./TextAreaForm";
import CardForm, { CardPreview, getDefaultCardConfig } from "./CardForm";

const formComponents = {
  Text: {
    form: TextForm,
    preview: TextPreview,
    getDefaultConfig: getDefaultTextConfig,
  },
  Button: {
    form: ButtonForm,
    preview: ButtonPreview,
    getDefaultConfig: getDefaultButtonConfig,
  },
  Image: {
    form: ImageForm,
    preview: ImagePreview,
    getDefaultConfig: getDefaultImageConfig,
  },
  Dropdown: {
    form: DropdownForm,
    preview: DropdownPreview,
    getDefaultConfig: getDefaultDropdownConfig,
  },
  Autocomplete: {
    form: AutocompleteForm,
    preview: AutocompletePreview,
    getDefaultConfig: getDefaultAutocompleteConfig,
  },
  Input: {
    form: TextInputForm,
    preview: TextInputPreview,
    getDefaultConfig: getDefaultInputConfig,
  },
  Textarea: {
    form: TextAreaForm,
    preview: TextAreaPreview,
    getDefaultConfig: getDefaultTextAreaConfig,
  },
  Card: {
    form: CardForm,
    preview: CardPreview,
    getDefaultConfig: getDefaultCardConfig,
  },
  // Add other components as needed
};

export default formComponents;
