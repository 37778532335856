import React from 'react';
import {Button, Row, Upload} from 'antd';
import {UploadOutlined} from '@ant-design/icons';

const message ={}
const UploadData = ({calledFrom, label}) => {
  const props = {
    name: 'file',
    action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info) {
      console.log(info);
      if (info.file.status !== 'uploading') {
      }
      if (info.file.status === 'done') {
        console.log(info.file, info.fileList);

        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <div>
    <Row>
    <label>{label}</label>
    </Row>
    <Row>
    <Upload {...props} accept = {calledFrom === "Audio" ? "audio/*" : calledFrom === "Image" ? "image/*" : "video/*" }>
      <Button className='uploadStyle' icon={<UploadOutlined />}>{`Upload ${calledFrom}`}</Button>
    </Upload>
    </Row>
    </div>
  );
};

export default UploadData;
