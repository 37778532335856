import React from "react";
import {Select} from "antd";
import "./multiSelectDropdown.css"

const MultiSelectDropdown = ({defaultValue, onChange, optionLabelProp, options}) => {
  return (
    <div>
      <Select mode="multiple" 
        className="visbleFor" 
       defaultValue={defaultValue}
       onChange={onChange}
       optionLabelProp={optionLabelProp}
       options={options}
      />
    </div>
  );
};

export default MultiSelectDropdown;
