import React, {useEffect, useState} from "react";
import {RxCross2} from "react-icons/rx";
import {motion} from "framer-motion";
import Api from "../../../../services/Api";
import Notification from "../../../../services/Notification";

const ABACConditionList = ({
  blockId,
  condition,
  onConditionChange,
  reload,
}) => {
  const [selectedAttributeValue, setSelectedAttributeValue] = useState(
    condition.expectedValue
  );
  const [selectedColumnNames, setSelectedColumnNames] = useState([]);

  const handleAttributeValueChange = (e) => {
    const { name, value } = e.target;
    setSelectedAttributeValue(value);
    onConditionChange(blockId, condition.conditionId, { [name]: value });
  };

  const handleOthersAttribute = (e) => {
    const { name, value } = e.target;
    console.log(
      "Handling others attribute change for:",
      name,
      "new value:",
      value
    );
    onConditionChange(blockId, condition.conditionId, { [name]: value });
  };

  const fetchSelectedCols = async () => {
    Api.get("table-data?schemaName=mudumbai&tableName=attributes").send(
      (response) => {
        if (response !== undefined) {
          setSelectedColumnNames(response);
          
        } else {
          Notification.error("Error", "An error occurred while getting data.");
        }
      }
    );
  };

  useEffect(() => {
    fetchSelectedCols();
  }, [reload]);

  return (
    <div className="flex justify-between border-b-2 p-2 border-gray-400 m-2">
      {condition.conditionId === 1 ? (
        <div className="text-lg font-bold w-16 px-2 mx-2">IF</div>
      ) : (
        <select
          value={condition.conditionType}
          onChange={handleAttributeValueChange}
          name="conditionType"
          className="px-2 mx-2 border border-gray-400"
        >
          <option value="AND">AND</option>
          <option value="OR">OR</option>
        </select>
      )}

      <div className="flex flex-col w-full">
        <div className="flex w-full justify-center items-center">
          <select
            value={condition.selectedAttribute}
            onChange={handleAttributeValueChange}
            name="attributeId"
            className="px-2 mx-2 border border-gray-400"
          >
            <option value="">Select attribute</option>
            {selectedColumnNames.map((col) => (
              <option key={col.attributeid} value={col.attributeid}>
                {col.attribute_name}
              </option>
            ))}
          </select>
          <select
            value={condition.operationValue}
            onChange={handleAttributeValueChange}
            name="operationValue"
            className="px-2 mx-2 border border-gray-400"
          >
            <option value="">Select operation</option>
            <option value="Equals">Equals</option>
            <option value="Greater">Greater</option>
            <option value="Lesser">Lesser</option>
          </select>
          <select
            value={selectedAttributeValue}
            onChange={handleAttributeValueChange}
            name="expectedValue"
            className="px-2 mx-2 border border-gray-400"
          >
            <option value="">Select Attribute value</option>
            <option value="Manager">Manager</option>
            <option value="Customer">Customer</option>
            <option value="Admin">Admin</option>
            <option value="Others">Others</option>
          </select>

          {selectedAttributeValue === "Others" && (
            <input
              type="text"
              className="px-2 mx-2 border border-gray-300"
              placeholder="Enter attribute"
              name="otherAttributes" // Make sure this name matches your data structure
              onChange={handleOthersAttribute}
            />
          )}
        </div>
      </div>
    </div>
  );
};

let dropIn = {
  hidden: {
    opacity: 1,
    y: "-100vh",
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.1,
      type: "spring",
      daming: 100,
      stiffness: 500,
    },
  },
  exit: {
    y: "100vh",
    opacity: 0,
  },
};
const TestPopup = ({ closeFunction, message }) => {
  return (
    <div className="fixed inset-0 z-10 bg-black bg-opacity-50 flex justify-center items-center">
      <motion.div
        // animate={{ width: "250px" }}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
        className="flex flex-col items-center text-gray-400 bg-white shadow-xl rounded-xl p-8 space-y-4"
      >
        <div className=" rounded-full bg-green-500 text-white p-4">
          Lquas tempora, qui, alias sequi. Temporibus in nulla repudiandae eum
          unde!
        </div>

        <button
          onClick={closeFunction}
          className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-700 focus:ring-opacity-50"
        >
          Test
        </button>
      </motion.div>
    </div>
  );
};

const ABAC = ({ block, onDeleteBlock, onBlockChange, onConditionChange,reload }) => {
  const handleBlockAttributeChange = (e) => {
    const { name, value } = e.target;
    onBlockChange(block.blockId, { ...block, [name]: value });
  };

  const handleAddCondition = () => {
    const newCondition = {
      conditionType: "IF",
      attributeId: "",
      operationValue: "",
      expectedValue: "",
      otherAttributes: "",
      created_at: null,
      created_by: null,
      updated_at: null,
      updated_by: null,
    };

    // const updatedConditions = [...block.conditions, newCondition]; // Spread into a new array to ensure immutability
    const updatedBlock = {
      ...block,
      conditions: [...block.conditions, newCondition],
    };

    onBlockChange(block.blockId, updatedBlock);
  };

  return (
    <div className="bg-gray-200 rounded-md  px-4 py-1 m-1 relative">
      {block.blockId !== 1 && (
        <button
          onClick={() => onDeleteBlock(block.blockId)}
          className="absolute hover:bg-gray-500 top-0 right-0"
        >
          <RxCross2 />
        </button>
      )}

      <div className="flex my-2">
        <label className="text-xs pr-2" htmlFor={`Required-${block.blockId}`}>
          Required
        </label>
        <input
          type="checkbox"
          name="Required"
          id={`Required-${block.blockId}`}
          checked={block.required}
          onChange={(e) => {
            const updatedBlock = { ...block, required: e.target.checked };
            onBlockChange(block.blockId, updatedBlock);
          }}
        />

        <label
          className="text-xs pl-5 pr-2"
          htmlFor={`EnableConditions-${block.blockId}`}
        >
          Enable Conditions
        </label>
        <input
          type="checkbox"
          name="EnableConditions"
          id={`EnableConditions-${block.blockId}`}
          checked={block.enableCondition}
          onChange={(e) => {
            const updatedBlock = {
              ...block,
              enableCondition: e.target.checked,
            };
            onBlockChange(block.blockId, updatedBlock);
          }}
        />
      </div>

      <div className="flex mt-2 border-b-2 border-gray-500 pb-2">
        <input
          type="text"
          value={block.ruleName}
          onChange={handleBlockAttributeChange}
          name="ruleName"
          className="border border-gray-300 text-sm px-2 py-1 w-full mr-2"
          placeholder="Enter rule name"
        />
        <input
          type="text"
          value={block.description}
          onChange={handleBlockAttributeChange}
          name="description"
          className="border border-gray-300 text-sm px-2 py-1 w-full"
          placeholder="Enter description"
        />
      </div>

      {Array.isArray(block.conditions) &&
        block.conditions.map((condition) => (
          <ABACConditionList
            reload={reload}
            key={condition.conditionId}
            condition={condition}
            onConditionChange={onConditionChange}
            blockId={block.blockId}
          />
        ))}
      <button
        onClick={handleAddCondition}
        className="text-sky-blue text-2xl hover:bg-sky-200"
      >
        +
      </button>
    </div>
  );
};

export default ABAC;
