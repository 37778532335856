// src/components/previews/AutocompletePreview.js
import React, { useState } from "react";

const AutocompletePreview = ({ config }) => {
  const { suggestions, inputValue } = config;
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);

  const handleInputChange = (e) => {
    const query = e.target.value;
    const filtered = suggestions
      .filter((suggestion) =>
        suggestion.toLowerCase().includes(query.toLowerCase())
      )
      .slice(0, 5); // Limit to 5 suggestions
    setFilteredSuggestions(filtered);
  };

  return (
    <div>
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        placeholder="Start typing..."
      />
      {filteredSuggestions.length > 0 && (
        <ul>
          {filteredSuggestions.map((suggestion, idx) => (
            <li key={idx}>{suggestion}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default AutocompletePreview;
