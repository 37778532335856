import {createSlice} from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "userData",
  initialState: {
    applicationName: "public",
    userName: "DUMMY_FOR_NOW",
    applicationType: "STATIC",
    DYNAMIC_APP_MENUS: [],
    // DYNAMIC_APP_MENUS: ["/process_design", "/Integration", "/BussinessLogic"],
    colorTheme: {
      primaryColor: "#1302ff",
      secondaryColor: "#4206A6",
      textColor: "#fffff",
    },
  },
  // initialState: { applicationName: "", userName: "DUMMY_FOR_NOW",applicationType:"STATIC", DYNAMIC_APP_MENUS :[''] },
  reducers: {
    updateApplicationName: (state, action) => {
      console.log("STATE VALUE", action.payload);
      state.applicationName = action.payload;
    },
  },
});

export const { updateApplicationName } = userSlice.actions;

export default userSlice.reducer;
