import React, {useState} from 'react'
import {CiTrash} from 'react-icons/ci'

const SideBarConfigForm = () => {
    const  [sidebar, setSidebar] = useState([{id:0, text: "Home", screen: "" }])

    const addSidebar = ()=>{
        setSidebar([...sidebar,{id:sidebar.length,text:"",screen:""}])
    }

    const deleteSidebar = (id)=>{
        let updatedData= sidebar.filter((data)=>data.id !== id);
        setSidebar(updatedData)
    }
    const handleSidebarChange = (id,e)=>{
        console.log(id,"ID")

        let data = [...sidebar]
        console.log(data)
        console.log("ERERERE",data[id] )
        data[id][e.target.name] = e.target.value
        setSidebar(data)
    }

  return (
    <div className='p-2'>
      <h2 className="text-xl font-semibold mb-2 text-center border-y border-gray-200 ">
        SIDEBAR CONFIGURATION FORM
      </h2>

      <div className="mt-4">
        <h3 className="font-semibold">Sidebar Links:</h3>
        <div className="p-2 bg-gray-300 rounded-md">
          {sidebar.map((bar, index) => (
            <div key={bar.id} className="flex items-center mb-2 w-6/12">
              <input
                type="text"
                name="text"
                placeholder="Text"
                value={bar.text}
                onChange={(e) =>
                  handleSidebarChange(bar.id, e)
                }
                className="border rounded p-1 mr-2"
              />
              <select
                id="selectScreen"
                name="screen"
                value={bar.screen}
                onChange={(e) =>
                    handleSidebarChange(bar.id, e)
                  }
                className="ml-2 border rounded p-1 w-full"
              >
                <option value="">Select screen for body</option>
                <option value="Homepage">Home </option>
                <option value="Aboutpage">About </option>
                <option value="Contactuspage">Contact us </option>
              </select>
              <button
                onClick={() => deleteSidebar(bar.id)}
                className="ml-2 text-red-800 bg-red-200 rounded-lg p-2"
              >
                <CiTrash />
              </button>
            </div>
          ))}

          <button
            onClick={addSidebar}
            className="text-white rounded-lg font-semibold p-2 bg-gray-700 "
          >
            Add +
          </button>
        </div>
      </div>
      <button
        // onClick={handleSubmit}
        className="mt-4 bg-blue-500 text-white p-2 rounded hover:bg-blue-700"
      >
        Save Configuration
      </button>
    </div>
  )
}

export default SideBarConfigForm
