// src/components/forms/TextForm.js
import React from "react";
import TextPreview from "../previews/TextPreview";

export const getDefaultTextConfig = () => ({
  content: "Sample Text",
  fontSize: "16px",
  color: "#333333",
  alignment: "left",
});

const TextForm = ({ config, onConfigChange }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    onConfigChange({ ...config, [name]: value });
  };

  return (
    <div>
      <h3>Text Settings</h3>
      <label>
        Content:
        <input
          type="text"
          name="content"
          value={config.content}
          onChange={handleChange}
        />
      </label>
      <label>
        Font Size:
        <input
          type="text"
          name="fontSize"
          value={config.fontSize}
          onChange={handleChange}
        />
      </label>
      <label>
        Font Color:
        <input
          type="color"
          name="color"
          value={config.color}
          onChange={handleChange}
        />
      </label>
      <label>
        Alignment:
        <select
          name="alignment"
          value={config.alignment}
          onChange={handleChange}
        >
          <option value="left">Left</option>
          <option value="center">Center</option>
          <option value="right">Right</option>
        </select>
      </label>
    </div>
  );
};

export default TextForm;
export { TextPreview };
