import {PaymentElement,useStripe,useElements} from '@stripe/react-stripe-js';

const CheckoutForm = () => {
  // get stripe and elements
  const stripe = useStripe();
  const elements = useElements();
  // check if they exist
  if (!stripe || !elements) {
    return
  }
  // form handler
  const handleSubmit=async(e)=>{
    e.preventDefault()
    try {
      const result = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        confirmParams: {
          return_url: "https://example.com/order/123/complete",
        },
      });
      if (result.error) {
        // Show error to your customer (for example, payment details incomplete)
        console.log(result.error.message);
      } else {
      //  redirect
      }
    } catch (error) {
      console.log(error);
      
    }
    
  }

  return (
    <form onSubmit={handleSubmit} className='bg-slate-950  w-2/5 p-6 rounded-md flex flex-col gap-8 mx-auto'>
      <PaymentElement  />
      <button className='bg-blue-500 p-2 text-white'>Submit</button>
    </form>
  );
};
export default CheckoutForm
