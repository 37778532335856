import React from 'react';
import './InputField.css';

const InputField = ({label,type,name,value,onChange,placeholder,disabled,options}) => {
  return (
    <div className='inp-user'>
       {type === 'select' ?(
              <>

              <select
                 name={name}
                 value={value}
                 onChange={onChange}
                 disabled={disabled}
              >
                 <option value="" disabled className="drop-label">
                   {label}
                 </option>
                     {options.map((option) => (
                         <option key={option} value={option}>
                            {option}
                         </option>
                     ))}
              </select>
                </>

              ):(
              <>
                    <input
                      type={type}
                      name={name}
                      value={value}
                      onChange={onChange}
                      placeholder={placeholder}
                      disabled={disabled}
                     />
                   <label>{label}</label>
              </>

              )}


    </div>
  );
};

export default InputField;