import React from 'react'
import {IoIosArrowBack, IoIosArrowForward} from "react-icons/io";


const Pagination = ({tableData,setCurrentPage,itemsPerPage,currentPage}) => {

  // Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="  w-4/12 mt-4 flex ">
    <button
        onClick={() => paginate(currentPage - 1)}
        className="page-item  p-2 rounded-md  mx-1 my-1 border border-black text-xs  bg-skt-blue text-white"
      >
        <IoIosArrowBack/>
      </button>
        <div className="border border-black  flex">
      {[...Array(Math.ceil(tableData.length / itemsPerPage)).keys()].map(
        (number) => (
          <button
            key={number + 1}
            onClick={() => paginate(number + 1)}
            className={`page-item  px-2 rounded-md  mx-1 my-1 border border-black text-xs ${
              currentPage == number + 1 ? "bg-skt-blue text-white" : " "
            }`}
          >
            {number + 1}
          </button>
        )
      )}
      </div>

      <button
        onClick={() => paginate(currentPage + 1)}
        className="page-item  p-2 rounded-md  mx-1 my-1 border border-black text-xs  bg-skt-blue text-white"
      >
        <IoIosArrowForward/>
      </button>
    </div>
  )
}

export default Pagination
