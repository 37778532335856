// import React, { useState, useRef, useEffect } from "react";
// import formComponents from "./components/forms/formComponents";
// import { db } from "./firebaseConfig";
// import { doc, setDoc } from "firebase/firestore";

// const ComponentConfigPage = () => {
//   const [selectedComponent, setSelectedComponent] = useState("Text");
//   const [config, setConfig] = useState(
//     formComponents["Text"].getDefaultConfig()
//   );
//   const [componentName, setComponentName] = useState("");
//   const [status, setStatus] = useState("");

//   const formContainerRef = useRef(null);
//   const previewContainerRef = useRef(null);

//   const handleComponentChange = (e) => {
//     const componentName = e.target.value;
//     setSelectedComponent(componentName);
//     setConfig(formComponents[componentName].getDefaultConfig());
//   };

//   const handleConfigChange = (newConfig) => {
//     setConfig(newConfig);
//   };

//   const handleSave = async () => {
//     if (!componentName.trim()) {
//       setStatus("Please enter a unique name for the component.");
//       return;
//     }

//     const configToSave = { ...config, componentType: selectedComponent };

//     try {
//       // Save the main component configuration
//       await setDoc(doc(db, "Component", componentName), configToSave);

//       // Save nested components with unique names
//       for (let i = 0; i < config.nestedComponents.length; i++) {
//         const nestedComponent = config.nestedComponents[i];
//         const componentType = nestedComponent.type;
//         let uniqueName = `${componentName}_${componentType}`;

//         // Check for duplicate component types within the nestedComponents array
//         const duplicates = config.nestedComponents.filter(
//           (item) => item.type === componentType
//         );

//         // Append a number if there are multiple components of the same type
//         if (duplicates.length > 1) {
//           uniqueName = `${uniqueName}${i + 1}`;
//         }

//         const nestedConfigToSave = {
//           ...nestedComponent.config,
//           componentType: componentType,
//         };

//         await setDoc(doc(db, "Component", uniqueName), nestedConfigToSave);
//       }

//       setStatus("Configuration and nested components saved successfully!");
//     } catch (error) {
//       console.error("Error saving configuration:", error);
//       setStatus("Failed to save configuration.");
//     }
//   };

//   const syncScroll = () => {
//     if (formContainerRef.current && previewContainerRef.current) {
//       previewContainerRef.current.scrollTop =
//         formContainerRef.current.scrollTop;
//     }
//   };

//   useEffect(() => {
//     const formEl = formContainerRef.current;
//     formEl?.addEventListener("scroll", syncScroll);

//     return () => {
//       formEl?.removeEventListener("scroll", syncScroll);
//     };
//   }, []);

//   const SelectedForm = formComponents[selectedComponent].form;
//   const SelectedPreview = formComponents[selectedComponent].preview;

//   return (
//     <div style={{ display: "flex", gap: "20px", padding: "20px" }}>
//       {/* Form Container with Scroll */}
//       <div
//         ref={formContainerRef}
//         style={{
//           flex: 1,
//           maxHeight: "80vh",
//           overflowY: "auto",
//           paddingRight: "10px",
//         }}
//       >
//         <h1>Configure Component</h1>

//         <label>
//           Component Name:
//           <input
//             type="text"
//             placeholder="Enter unique component name"
//             value={componentName}
//             onChange={(e) => setComponentName(e.target.value)}
//             style={{ display: "block", marginBottom: "10px", width: "100%" }}
//           />
//         </label>

//         <label>Select Component:</label>
//         <select
//           value={selectedComponent}
//           onChange={handleComponentChange}
//           style={{ marginBottom: "20px", display: "block" }}
//         >
//           {Object.keys(formComponents).map((component) => (
//             <option key={component} value={component}>
//               {component}
//             </option>
//           ))}
//         </select>

//         <SelectedForm config={config} onConfigChange={handleConfigChange} />

//         <div style={{ marginTop: "20px" }}>
//           <button
//             onClick={() => setStatus("Preview Updated")}
//             style={{ marginRight: "10px" }}
//           >
//             Preview
//           </button>
//           <button onClick={handleSave}>Save Configuration</button>
//         </div>

//         <p>{status}</p>
//       </div>

//       {/* Preview Container with Synchronized Scroll */}
//       <div
//         ref={previewContainerRef}
//         style={{
//           flex: 1,
//           maxHeight: "80vh",
//           overflowY: "auto",
//           padding: "10px",
//           border: "1px solid #ccc",
//         }}
//       >
//         <h2>Preview</h2>
//         <SelectedPreview config={config} />
//       </div>
//     </div>
//   );
// };

// export default ComponentConfigPage;

/// the above code is working 100%///
//below coode has logic to save in firestore/mongodb/postgres at a time we have to comment //
// import React, { useState, useRef, useEffect } from "react";
// import formComponents from "./components/forms/formComponents";
// import { db } from "./firebaseConfig";
// import { doc, setDoc } from "firebase/firestore";

// const ComponentConfigPage = () => {
//   const [selectedComponent, setSelectedComponent] = useState("Text");
//   const [config, setConfig] = useState(
//     formComponents["Text"].getDefaultConfig()
//   );
//   const [componentName, setComponentName] = useState("");
//   const [status, setStatus] = useState("");

//   const formContainerRef = useRef(null);
//   const previewContainerRef = useRef(null);

//   const handleComponentChange = (e) => {
//     const componentName = e.target.value;
//     setSelectedComponent(componentName);
//     setConfig(formComponents[componentName].getDefaultConfig());
//   };

//   const handleConfigChange = (newConfig) => {
//     setConfig(newConfig);
//   };

//   // const handleMongoSave = async () => {
//   //   const apiUrl = `http://5.XX.XXX.XXXX:8080/api/component/save-component?saveToPostgres=false&saveToMongo=true`;
//   //   const mainComponentPayload = {
//   //     appId: "98451bbc-1922-481b-8a6b-1d2402cb5570",
//   //     compTempID: "temp456",
//   //     pageId: "page789",
//   //     compName: componentName,
//   //     compType: selectedComponent,
//   //     visibilityRule: "rule1",
//   //     visible: true,
//   //     enabled: true,
//   //     version: "1.0",
//   //     createDateTime: new Date().toISOString(),
//   //     json: config,
//   //   };

//   //   try {
//   //     // Send main component configuration to MongoDB
//   //     const response = await fetch(apiUrl, {
//   //       method: "POST",
//   //       headers: { "Content-Type": "application/json" },
//   //       body: JSON.stringify(mainComponentPayload),
//   //     });
//   //     if (!response.ok) {
//   //       throw new Error("Failed to save the main component to MongoDB.");
//   //     }

//   //     // Save nested components as separate documents in MongoDB
//   //     for (let i = 0; i < config.nestedComponents.length; i++) {
//   //       const nestedComponent = config.nestedComponents[i];
//   //       const componentType = nestedComponent.type;
//   //       let uniqueName = `${componentName}_${componentType}`;

//   //       const duplicates = config.nestedComponents.filter(
//   //         (item) => item.type === componentType
//   //       );

//   //       if (duplicates.length > 1) {
//   //         uniqueName = `${uniqueName}${i + 1}`;
//   //       }

//   //       const nestedComponentPayload = {
//   //         ...mainComponentPayload,
//   //         compName: uniqueName,
//   //         compType: componentType,
//   //         json: nestedComponent.config,
//   //       };

//   //       await fetch(apiUrl, {
//   //         method: "POST",
//   //         headers: { "Content-Type": "application/json" },
//   //         body: JSON.stringify(nestedComponentPayload),
//   //       });
//   //     }

//   //     setStatus("Configuration and nested components saved to MongoDB!");
//   //   } catch (error) {
//   //     console.error("Error saving configuration to MongoDB:", error);
//   //     setStatus("Failed to save configuration to MongoDB.");
//   //   }
//   // };

//   const handleSave = async () => {
//     if (!componentName.trim()) {
//       setStatus("Please enter a unique name for the component.");
//       return;
//     }

//     const configToSave = { ...config, componentType: selectedComponent };

//     try {
//       // Save the main component configuration to Firestore
//       await setDoc(doc(db, "Component", componentName), configToSave);

//       // Save nested components with unique names to Firestore
//       for (let i = 0; i < config.nestedComponents.length; i++) {
//         const nestedComponent = config.nestedComponents[i];
//         const componentType = nestedComponent.type;
//         let uniqueName = `${componentName}_${componentType}`;

//         const duplicates = config.nestedComponents.filter(
//           (item) => item.type === componentType
//         );

//         if (duplicates.length > 1) {
//           uniqueName = `${uniqueName}${i + 1}`;
//         }

//         const nestedConfigToSave = {
//           ...nestedComponent.config,
//           componentType: componentType,
//         };

//         await setDoc(doc(db, "Component", uniqueName), nestedConfigToSave);
//       }

//       setStatus("Configuration and nested components saved to Firestore!");

//       // Call handleMongoSave to save to MongoDB as well
//       await handleMongoSave();

//       setStatus("Configuration saved to both Firestore and MongoDB!");
//     } catch (error) {
//       console.error("Error saving configuration:", error);
//       setStatus("Failed to save configuration.");
//     }
//   };

//   const syncScroll = () => {
//     if (formContainerRef.current && previewContainerRef.current) {
//       previewContainerRef.current.scrollTop =
//         formContainerRef.current.scrollTop;
//     }
//   };

//   useEffect(() => {
//     const formEl = formContainerRef.current;
//     formEl?.addEventListener("scroll", syncScroll);

//     return () => {
//       formEl?.removeEventListener("scroll", syncScroll);
//     };
//   }, []);

//   const SelectedForm = formComponents[selectedComponent].form;
//   const SelectedPreview = formComponents[selectedComponent].preview;

//   return (
//     <div style={{ display: "flex", gap: "20px", padding: "20px" }}>
//       {/* Form Container with Scroll */}
//       <div
//         ref={formContainerRef}
//         style={{
//           flex: 1,
//           maxHeight: "80vh",
//           overflowY: "auto",
//           paddingRight: "10px",
//         }}
//       >
//         <h1>Configure Component</h1>

//         <label>
//           Component Name:
//           <input
//             type="text"
//             placeholder="Enter unique component name"
//             value={componentName}
//             onChange={(e) => setComponentName(e.target.value)}
//             style={{ display: "block", marginBottom: "10px", width: "100%" }}
//           />
//         </label>

//         <label>Select Component:</label>
//         <select
//           value={selectedComponent}
//           onChange={handleComponentChange}
//           style={{ marginBottom: "20px", display: "block" }}
//         >
//           {Object.keys(formComponents).map((component) => (
//             <option key={component} value={component}>
//               {component}
//             </option>
//           ))}
//         </select>

//         <SelectedForm config={config} onConfigChange={handleConfigChange} />

//         <div style={{ marginTop: "20px" }}>
//           <button
//             onClick={() => setStatus("Preview Updated")}
//             style={{ marginRight: "10px" }}
//           >
//             Preview
//           </button>
//           <button onClick={handleSave}>Save Configuration</button>
//         </div>

//         <p>{status}</p>
//       </div>

//       {/* Preview Container with Synchronized Scroll */}
//       <div
//         ref={previewContainerRef}
//         style={{
//           flex: 1,
//           maxHeight: "80vh",
//           overflowY: "auto",
//           padding: "10px",
//           border: "1px solid #ccc",
//         }}
//       >
//         <h2>Preview</h2>
//         <SelectedPreview config={config} />
//       </div>
//     </div>`
//   );
// };

// export default ComponentConfigPage;
//below code is an extenstion
import React, { useState, useRef, useEffect } from "react";
import formComponents from "../components/forms/formComponents";
import { db } from "../firebase/firebaseConfig";
import { collection, getDocs, doc, getDoc, setDoc } from "firebase/firestore";

const ComponentConfigPage = () => {
  const [selectedComponent, setSelectedComponent] = useState("Text");
  const [config, setConfig] = useState(
    formComponents["Text"].getDefaultConfig()
  );
  const [componentName, setComponentName] = useState("");
  const [status, setStatus] = useState("");
  const [userConfiguredComponents, setUserConfiguredComponents] = useState([]);

  const formContainerRef = useRef(null);
  const previewContainerRef = useRef(null);

  // Fetch user-configured components from Firestore on component mount
  useEffect(() => {
    const fetchUserConfiguredComponents = async () => {
      const querySnapshot = await getDocs(collection(db, "Component"));
      const components = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUserConfiguredComponents(components);
    };

    fetchUserConfiguredComponents();
  }, []);

  // Handle component selection change
  const handleComponentChange = async (e) => {
    const componentName = e.target.value;
    setSelectedComponent(componentName);

    // Check if it's a user-configured component
    const userComponent = userConfiguredComponents.find(
      (c) => c.id === componentName
    );

    if (userComponent) {
      // Load user-configured component from Firestore
      try {
        const docRef = doc(db, "Component", componentName);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setConfig(docSnap.data().json);
          setComponentName(componentName); // Populate name field with the selected component's name
        } else {
          setStatus("No configuration found for the selected component.");
        }
      } catch (error) {
        console.error("Error fetching user-configured component:", error);
        setStatus("Failed to load component configuration.");
      }
    } else {
      // Load the default config for basic/advanced components
      const defaultConfig = formComponents[componentName]?.getDefaultConfig();
      if (defaultConfig) {
        setConfig(defaultConfig);
      } else {
        setStatus("Selected component configuration is not available.");
      }
    }
  };

  const handleConfigChange = (newConfig) => {
    setConfig(newConfig);
  };

  const handleSave = async () => {
    if (!componentName.trim()) {
      setStatus("Please enter a unique name for the component.");
      return;
    }

    const configToSave = { ...config, componentType: selectedComponent };

    try {
      // Save the main component configuration to Firestore
      await setDoc(doc(db, "Component", componentName), configToSave);

      // Additional save logic if necessary...

      setStatus("Configuration saved successfully!");
    } catch (error) {
      console.error("Error saving configuration:", error);
      setStatus("Failed to save configuration.");
    }
  };

  const syncScroll = () => {
    if (formContainerRef.current && previewContainerRef.current) {
      previewContainerRef.current.scrollTop =
        formContainerRef.current.scrollTop;
    }
  };

  useEffect(() => {
    const formEl = formContainerRef.current;
    formEl?.addEventListener("scroll", syncScroll);
    return () => {
      formEl?.removeEventListener("scroll", syncScroll);
    };
  }, []);

  const SelectedForm = formComponents[selectedComponent]?.form;
  const SelectedPreview = formComponents[selectedComponent]?.preview;

  return (
    <div style={{ display: "flex", gap: "20px", padding: "20px" }}>
      <div
        ref={formContainerRef}
        style={{
          flex: 1,
          maxHeight: "80vh",
          overflowY: "auto",
          paddingRight: "10px",
        }}
      >
        <h1>Configure Component</h1>

        <label>
          Component Name:
          <input
            type="text"
            placeholder="Enter unique component name"
            value={componentName}
            onChange={(e) => setComponentName(e.target.value)}
            style={{ display: "block", marginBottom: "10px", width: "100%" }}
          />
        </label>

        <label>Select Component:</label>
        <select
          value={selectedComponent}
          onChange={handleComponentChange}
          style={{ marginBottom: "20px", display: "block" }}
        >
          <optgroup label="Basic & Advanced Components">
            {Object.keys(formComponents).map((component) => (
              <option key={component} value={component}>
                {component}
              </option>
            ))}
          </optgroup>
          <optgroup label="User-Configured Components">
            {userConfiguredComponents.map((userComp) => (
              <option key={userComp.id} value={userComp.id}>
                {userComp.id}
              </option>
            ))}
          </optgroup>
        </select>

        {SelectedForm && (
          <SelectedForm config={config} onConfigChange={handleConfigChange} />
        )}

        <div style={{ marginTop: "20px" }}>
          <button
            onClick={() => setStatus("Preview Updated")}
            style={{ marginRight: "10px" }}
          >
            Preview
          </button>
          <button onClick={handleSave}>Save Configuration</button>
        </div>

        <p>{status}</p>
      </div>

      <div
        ref={previewContainerRef}
        style={{
          flex: 1,
          maxHeight: "80vh",
          overflowY: "auto",
          padding: "10px",
          border: "1px solid #ccc",
        }}
      >
        <h2>Preview</h2>
        {SelectedPreview && <SelectedPreview config={config} />}
      </div>
    </div>
  );
};

export default ComponentConfigPage;
// import React, { useState, useEffect } from "react";
// import { db } from "./firebaseConfig";
// import { collection, getDocs, doc, getDoc, setDoc } from "firebase/firestore";
// import formComponents from "./components/forms/formComponents";

// const ComponentConfigPage = () => {
//   const [componentNames, setComponentNames] = useState([]);
//   const [selectedComponent, setSelectedComponent] = useState("");
//   const [config, setConfig] = useState(null);
//   const [status, setStatus] = useState("");

//   useEffect(() => {
//     // Fetch all component names for the dropdown menu
//     const fetchComponentNames = async () => {
//       try {
//         const querySnapshot = await getDocs(collection(db, "Component"));
//         const names = querySnapshot.docs.map((doc) => doc.id);
//         setComponentNames(names);
//       } catch (error) {
//         console.error("Error fetching component names:", error);
//         setStatus("Failed to load component names.");
//       }
//     };

//     fetchComponentNames();
//   }, []);

//   const handleSelectChange = async (e) => {
//     const docName = e.target.value;
//     setSelectedComponent(docName);

//     if (docName) {
//       try {
//         const docRef = doc(db, "Component", docName);
//         const docSnapshot = await getDoc(docRef);

//         if (docSnapshot.exists()) {
//           const fetchedConfig = docSnapshot.data();

//           // Determine the form based on the componentType from the fetched config
//           const componentType = fetchedConfig.componentType;
//           if (formComponents[componentType]) {
//             // Set config for editing and load the appropriate form based on componentType
//             setConfig(fetchedConfig);
//             setStatus("Configuration loaded successfully!");
//           } else {
//             setConfig(null);
//             setStatus("No form available for this component type.");
//           }
//         } else {
//           setConfig(null);
//           setStatus("No configuration found.");
//         }
//       } catch (error) {
//         console.error("Error fetching configuration:", error);
//         setStatus("Failed to load configuration.");
//       }
//     } else {
//       setConfig(null);
//       setStatus("");
//     }
//   };

//   const handleConfigChange = (newConfig) => {
//     setConfig(newConfig);
//   };

//   const handleSaveChanges = async () => {
//     if (!selectedComponent) {
//       setStatus("Please select a component to save.");
//       return;
//     }

//     try {
//       await setDoc(doc(db, "Component", selectedComponent), config);
//       setStatus("Configuration saved successfully!");
//     } catch (error) {
//       console.error("Error saving configuration:", error);
//       setStatus("Failed to save configuration.");
//     }
//   };

//   const getFormComponent = () => {
//     if (!config || !config.componentType) return null;

//     const { componentType } = config;
//     const FormComponent = formComponents[componentType]?.form;

//     return FormComponent ? (
//       <FormComponent config={config} onConfigChange={handleConfigChange} />
//     ) : (
//       <p>Form not available for this component type.</p>
//     );
//   };

//   return (
//     <div>
//       <h1>Edit Existing Component</h1>
//       <select onChange={handleSelectChange} value={selectedComponent}>
//         <option value="">Select a component</option>
//         {componentNames.map((name) => (
//           <option key={name} value={name}>
//             {name}
//           </option>
//         ))}
//       </select>
//       <p>{status}</p>
//       {config && (
//         <div>
//           <h2>Component Editor</h2>
//           {getFormComponent()}
//           <button onClick={handleSaveChanges}>Save Changes</button>
//         </div>
//       )}
//     </div>
//   );
// };

// export default ComponentConfigPage;
