import React from "react";

const SidebarBodyConfigForm = () => {
  return (
    <div className="bg-gray-300 p-4 rounded-md">
      <div className="flex justify-start bg-gray-300 rounded-md ">
      <h1 className="font-semibold">Body Name</h1>
          <input
            // value={headingText}
            // onChange={(e) => setHeadingText(e.target.value)}
            type="text"
            className=" my-2 border border-gray-200 p-2 rounded-md w-full"
            placeholder="Heading Text"
          />
          
        </div>{" "}

        <select
            // value={selectedImage}
            // onChange={(e) => setSelectedImage(e.target.value)}
            className="p-2 rounded-md w-full px-2 border border-gray-300"
            name=""
            id=""
          >
            <option value="IMAGE1">Grid Design 2x2</option>
            <option value="IMAGE2">Box Design</option>
            <option value="IMAGE3">Form</option>
          </select>
    </div>
  );
};

export default SidebarBodyConfigForm;
