import React, { useState } from "react";
import "./FooterConfigForm.css";
import axios from "axios";
import { Select } from "antd";
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaLinkedin,
  FaYoutube,
  FaTrash,
  FaExclamationCircle,
} from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import useFooterStore from "../../store/useFooterStore";
import useHeaderStore from "../../store/useHeaderStore";
const { Option } = Select;

const MultiSelectDropdown = ({ defaultValue, onChange, options }) => {
  console.log(defaultValue, options);
  return (
    <Select
      mode="multiple"
      className="visibleFor"
      defaultValue={defaultValue}
      onChange={onChange}
      options={options}
      placeholder="Select social media icons"
    />
  );
};

const FooterConfigForm = () => {
  const [styleCustomization, setStyleCustomization] = useState(false);
  const [iconsSelected, setIconsSelected] = useState(false);
  const [isError, setIsError] = useState(false);
  // adding links to social icons
  const [iconLinks, setIconLinks] = useState({});

  const setFormConfiguration = useFooterStore(
    (state) => state.setFormConfiguration
  );
  const formConfiguration = useFooterStore((state) => state.formConfiguration);
  // reusing logo and logo text
  const headerformConfiguration = useHeaderStore(
    (state) => state.formConfiguration
  );
  const { logoText, logoUrl } = headerformConfiguration;
  console.log("logoText, logoUrl", logoText, logoUrl);

  const navigate = useNavigate();
  console.log("hey", formConfiguration, iconsSelected);

  const handleAddLink = (index, heading) => {
    const obj = formConfiguration.links[index];
    const linksOfHeading = [...obj[heading], ""];
    obj[heading] = linksOfHeading;

    setFormConfiguration((prevState) => ({
      ...prevState,
      links: [...prevState.links],
    }));
  };

  const handleRemoveLink = (index, heading, linkIndex) => {
    const obj = formConfiguration.links[index];
    const linksOfHeading = [...obj[heading]];
    linksOfHeading.splice(linkIndex, 1);
    obj[heading] = linksOfHeading;

    setFormConfiguration((prevState) => ({
      ...prevState,
      links: [...prevState.links],
    }));
  };

  const handleLinkInputChange = (event, index, heading, linkIndex) => {
    const obj = formConfiguration.links[index];
    const linksOfHeading = [...obj[heading]];
    linksOfHeading[linkIndex] = event.target.value;
    obj[heading] = linksOfHeading;
    setFormConfiguration((prevState) => ({
      ...prevState,
      links: [...prevState.links],
    }));
  };

  const handleAddHeading = () => {
    let heading = "";
    setFormConfiguration((prevState) => ({
      ...prevState,
      links: [...prevState.links, { [heading]: [] }],
    }));
  };

  const handleRemoveHeading = (index) => {
    setFormConfiguration((prevState) => {
      const updatedLinks = [...prevState.links];
      updatedLinks.splice(index, 1);
      return {
        ...prevState,
        links: updatedLinks,
      };
      setIconLinks(updatedLinks);
    });
  };

  const handleHeadingInputChange = (event, index, heading) => {
    if (isError == true) {
      setIsError(false);
    }
    setFormConfiguration((prevState) => {
      const newHeading = event.target.value;
      const obj = formConfiguration.links[index];
      obj[newHeading] = obj[heading];
      delete obj[heading];
      return {
        ...prevState,
        links: [...prevState.links],
      };
    });
  };

  const socialMediaOptions = [
    {
      value: "facebook",
      label: (
        <span>
          <FaFacebook />{" "}
        </span>
      ),
    },
    {
      value: "twitter",
      label: (
        <span>
          <FaTwitter />{" "}
        </span>
      ),
    },
    {
      value: "instagram",
      label: (
        <span>
          <FaInstagram />
        </span>
      ),
    },
    {
      value: "linkedin",
      label: (
        <span>
          <FaLinkedin />{" "}
        </span>
      ),
    },
    {
      value: "youtube",
      label: (
        <span>
          <FaYoutube />
        </span>
      ),
    },
  ];

  const handleIconChange = (value) => {
    setFormConfiguration((prevState) => ({
      ...prevState,
      socialMediaIcons: [...value],
    }));
  };

  const validateData = (event) => {
    event.preventDefault();
    let hasError = false;
    formConfiguration.links.map((ele) => {
      if (Object.keys(ele)[0] == "") {
        setIsError(true);
        hasError = true;
        console.log("if");
      }
    });
    if (!hasError) {
      navigate("/footer-preview");
    }
  };

  const handleCheckboxChange = (e) => {
    if (e.target.checked) {
      setFormConfiguration((prevState) => ({
        ...prevState,
        logoText: logoText,
        logoUrl: logoUrl,
      }));
    } else {
      // Optionally, if unchecked, you can reset the logoText and logoUrl
      setFormConfiguration((prevState) => ({
        ...prevState,
        logoText: "",
        logoUrl: "",
      }));
    }
  };

  const handleLinkChange = (icon, link) => {
    setIconLinks((prevLinks) => ({
      ...prevLinks,
      [icon]: link,
    }));

    // Update formConfiguration with the new array of links
    setFormConfiguration((prevState) => {
      const updatedLinks = Object.values({
        ...iconLinks,
        [icon]: link,
      }).filter(Boolean); // Filter out any empty or undefined links

      return {
        ...prevState,
        socialMediaLinks: updatedLinks,
      };
    });
  };

  return (
    <>
      <div className="contn">
        <div className="formw">
          <div className="header-form">
            <h1 className="formt">Footer Configuration</h1>
          </div>

          <div className="formf">
            <fieldset>
              <legend>Logo and Branding</legend>
              <label className="label1">Use site Name as Text Logo</label>
              {/* <div className="logo-input"> */}
              <div className="w-full flex justify-start gap-4">
                <input
                  type="checkbox"
                  id="accept"
                  onChange={handleCheckboxChange}
                  className="w-4 h-4 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                />
                <label htmlFor="accept" className="text-sm text-gray-700">
                  I agree to send the logo and text
                </label>
                <span className="focus-border"></span>
              </div>
            </fieldset>
            <fieldset>
              <legend>Footer Links Configuration</legend>
              <button
                className="text-white bg-blue-500 hover:bg-blue-700 rounded-md px-2 py-4"
                onClick={handleAddHeading}
              >
                NewHeading
              </button>
              {formConfiguration.links.map((ele, index) => {
                let heading = Object.keys(ele)[0];
                const linksOfHeading = ele[heading];
                return (
                  <div key={index}>
                    <div className="relative">
                      <div className="flex gap-1">
                        <input
                          type="text"
                          className={`border p-2 ${
                            isError && heading == ""
                              ? "border-red-500"
                              : "border-gray-300"
                          }`}
                          value={heading}
                          onChange={(event) =>
                            handleHeadingInputChange(event, index, heading)
                          }
                          placeholder="Enter heading link"
                        />
                        <button
                          className="add-btn"
                          onClick={(event) => handleAddLink(index, heading)}
                        >
                          AddLink
                        </button>
                        <button
                          className="remove-btn"
                          onClick={() => handleRemoveHeading(index)}
                        >
                          <FaTrash />
                        </button>
                      </div>
                      {isError && heading == "" && (
                        <>
                          <FaExclamationCircle className="absolute right-60 top-2.5 text-red-500" />
                          <p className="mt-0 text-left text-red-400 text-sm">
                            Please enter the heading name
                          </p>
                        </>
                      )}
                    </div>
                    {linksOfHeading.map((link, linkIndex) => (
                      <div key={index} className="input-group">
                        <div className="footer-input">
                          <input
                            type="text"
                            className="custom-input"
                            value={link}
                            onChange={(event) =>
                              handleLinkInputChange(
                                event,
                                index,
                                heading,
                                linkIndex
                              )
                            }
                            placeholder="Enter footer link"
                          />
                          <span className="focus-border2"></span>
                        </div>
                        <button
                          className="remove-btn"
                          onClick={() =>
                            handleRemoveLink(index, heading, linkIndex)
                          }
                        >
                          <FaTrash />
                        </button>
                      </div>
                    ))}
                  </div>
                );
              })}
            </fieldset>
            <fieldset>
              <legend>Social Media Icons</legend>
              <label className="lbl06">
                <input
                  className="smopt"
                  type="radio"
                  name="socialMediaIcons"
                  value="yes"
                  onChange={() => setIconsSelected(true)}
                />
                Yes
              </label>
              <label className="lbl07">
                <input
                  className="smopt"
                  type="radio"
                  name="socialMediaIcons"
                  value="no"
                  onChange={() => {
                    setIconsSelected(false);
                    // Reset selected icons
                    setFormConfiguration({
                      socialMediaIcons: [],
                    });
                  }}
                />
                No
              </label>

              {iconsSelected && (
                <div className="socln">
                  <MultiSelectDropdown
                    defaultValue={[]}
                    onChange={handleIconChange}
                    options={socialMediaOptions}
                  />
                  {formConfiguration.socialMediaIcons.length > 0 && (
                    <div className="selected-icons mt-2">
                      <h3>Selected Icons:</h3>
                      <ul>
                        {formConfiguration.socialMediaIcons.map((icon) => (
                          <li key={icon}>
                            {icon === "facebook" && <FaFacebook />}
                            {icon === "twitter" && <FaTwitter />}
                            {icon === "instagram" && <FaInstagram />}
                            {icon === "linkedin" && <FaLinkedin />}
                            {icon === "youtube" && <FaYoutube />}
                            {icon.charAt(0).toUpperCase() + icon.slice(1)}
                            {/* <span className="ml-2">
                              {icon.charAt(0).toUpperCase() + icon.slice(1)}
                            </span> */}
                            <input
                              type="text"
                              placeholder={`Enter link for ${icon}`}
                              value={iconLinks[icon] || ""}
                              onChange={(e) =>
                                handleLinkChange(icon, e.target.value)
                              }
                              className="border p-1 rounded w-full max-w-xs ml-2"
                            />
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              )}
            </fieldset>

            <fieldset>
              <legend>Contact Information</legend>
              <div className="cntin">
                <div className="email-phone">
                  <div className="email-filed">
                    <input
                      className=""
                      type="text"
                      placeholder="Email"
                      value={formConfiguration.contactInfo.email}
                      onChange={(e) =>
                        setFormConfiguration((prevState) => ({
                          ...prevState,
                          contactInfo: {
                            ...prevState.contactInfo,
                            email: e.target.value,
                          },
                        }))
                      }
                    />
                  </div>
                  <div className="phone-field">
                    <input
                      className="ctphn"
                      type="text"
                      placeholder="Phone Number"
                      value={formConfiguration.contactInfo.phone}
                      onChange={(e) =>
                        setFormConfiguration((prevState) => ({
                          ...prevState,
                          contactInfo: {
                            ...prevState.contactInfo,
                            phone: e.target.value,
                          },
                        }))
                      }
                    />
                  </div>
                </div>
                <div className="address">
                  <div className="">
                    <input
                      className=""
                      type="text"
                      placeholder="Address"
                      value={formConfiguration.contactInfo.address}
                      onChange={(e) =>
                        setFormConfiguration((prevState) => ({
                          ...prevState,
                          contactInfo: {
                            ...prevState.contactInfo,
                            address: e.target.value,
                          },
                        }))
                      }
                    />
                  </div>
                </div>
                <input
                  className="mt-4 shadow-lg p-2 border-t"
                  type="text"
                  placeholder="Timings"
                  value={formConfiguration.contactInfo.timings}
                  onChange={(e) =>
                    setFormConfiguration((prevState) => ({
                      ...prevState,
                      contactInfo: {
                        ...prevState.contactInfo,
                        timings: e.target.value,
                      },
                    }))
                  }
                />
              </div>
            </fieldset>

            <fieldset>
              <legend>Customization of Style and Color</legend>
              <label className="lbl19">
                <input
                  className="stopt"
                  type="radio"
                  name="styleCustomization"
                  value="yes"
                  onChange={() => setStyleCustomization(true)}
                />
                Yes
              </label>
              <label className="lbl20">
                <input
                  className="stopt"
                  type="radio"
                  name="styleCustomization"
                  value="no"
                  onChange={() => {
                    setStyleCustomization(false);
                    setFormConfiguration({
                      textColor: "#000000",
                      backgroundColor: "#ffffff",
                    });
                  }}
                />
                No
              </label>
              {styleCustomization && (
                <div className="customization-options">
                  <label className="bgcolor">Background Color:</label>
                  <input
                    type="color"
                    value={formConfiguration.backgroundColor}
                    onChange={(e) =>
                      setFormConfiguration({ backgroundColor: e.target.value })
                    }
                  />
                  <label className="txtcolor">Text Color:</label>
                  <input
                    type="color"
                    value={formConfiguration.textColor}
                    onChange={(e) =>
                      setFormConfiguration({ textColor: e.target.value })
                    }
                  />
                </div>
              )}
            </fieldset>
          </div>
        </div>
      </div>
      <Link
        className="mx-auto block text-center p-3 text-white bg-green-500 rounded-full mt-4 w-32"
        to="/footer-preview"
        onClick={validateData}
      >
        Preview
      </Link>
    </>
  );
};

export default FooterConfigForm;
