import React from 'react';

// Mock data as it would be imported or fetched
const navbarConfig = {
  "style": {
    "backgroundColor": "#333",
    "color": "#ffffff",
    "hoverColor": "#555",
    "fontFamily": "Arial, sans-serif",
    "fontSize": "16px",
    "padding": "10px 20px"
  },
  "position": {
    "type": "fixed",
    "location": "top"
  },
  "logo": {
    "imageUrl": "https://www.google.com/url?sa=i&url=https%3A%2F%2Fwww.canva.com%2Flogos%2Ftemplates%2Fsports%2F&psig=AOvVaw0UpwcCOKjIpro1u78LDR6E&ust=1715331591831000&source=images&cd=vfe&opi=89978449&ved=0CBIQjRxqFwoTCLDqy6KagIYDFQAAAAAdAAAAABAE",
    "link": "/home",
    "altText": "Website Logo",
    "style": {
      "width": "50px",
      "height": "auto"
    }
  },
  "links": [
    {
      "text": "Home",
      "url": "/home",
      "target": "_self",
      "style": {
        "fontWeight": "bold"
      }
    },
    // other links...
  ],
  "buttons": [
    {
      "text": "Login",
      "url": "/login",
      "style": {
        "backgroundColor": "#007BFF",
        "color": "#ffffff",
        "border": "none",
        "padding": "10px 20px",
        "borderRadius": "5px"
      }
    }
  ],
  "responsive": {
    "breakpoint": "768px",
    "toggleButton": {
      "icon": "path/to/menu-icon.png",
      "style": {
        "color": "#ffffff",
        "fontSize": "24px"
      }
    },
    "closeIcon": "path/to/close-icon.png"
  }
};

const Preview_Nav = () => {
  return (
    <nav style={{
      backgroundColor: navbarConfig.style.backgroundColor,
      color: navbarConfig.style.color,
      fontFamily: navbarConfig.style.fontFamily,
      padding: navbarConfig.style.padding
    }} className={`w-full ${navbarConfig.position.type === 'fixed' ? 'fixed' : 'relative'} ${navbarConfig.position.location === 'top' ? 'top-0' : 'bottom-0'}`}>
      <div className="flex justify-between items-center">
        <a href={navbarConfig.logo.link}>
          <img src={navbarConfig.logo.imageUrl} alt={navbarConfig.logo.altText} style={navbarConfig.logo.style} />
        </a>
        <ul className="flex">
          {navbarConfig.links.map((link, index) => (
            <li key={index} className="mx-2 hover:bg-gray-600 p-2 rounded">
              <a href={link.url} target={link.target} style={link.style}>{link.text}</a>
            </li>
          ))}
        </ul>
        {navbarConfig.buttons.map((button, index) => (
          <button key={index} style={button.style} className="mx-2 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            {button.text}
          </button>
        ))}
      </div>
    </nav>
  );
};

export default Preview_Nav;
