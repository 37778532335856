import React, {useState} from "react";

const Accordion = ({ accordionContent }) => {
  // State to keep track of the currently active accordion index
  const [activeIndex, setActiveIndex] = useState(null);

  // Function to handle clicks on accordion headers
  const handleAccordionClick = (index) => {
    // Toggle the active index. If the clicked index is already active, set it to null to close it
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="flex flex-col">
      {/* Accordion headers */}
      <div className="flex  w-100 text-sm">
        {accordionContent.map((content, index) => (
          <h3
            key={index}
            className={`flex-grow text-center p-1 cursor-pointer border border-skt-blue
                        ${
                          activeIndex === index
                            ? "text-skt-blue font-bold bg-sky-100 "
                            : "bg-white"
                        } 
                        hover:bg-skt-blue hover:text-white 
                        `}
            onClick={() => handleAccordionClick(index)}
          >
            {content.name}
          </h3>
        ))}
      </div>

      {/* Accordion content */}
      <div className="">
        {activeIndex !== null && (
          <div className="rounded-lg shadow-lg p-4 bg-white">
            {React.createElement(accordionContent[activeIndex].component)}
          </div>
        )}
      </div>
    </div>
  );
};

export default Accordion;
