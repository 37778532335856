import React, {useState} from "react";
import SelectMultipleComponents from "./SelectMultipleComponents";
import {layout1, layout2, layout3, layout4, layout5, layout6,} from "../../../../assets/images";
import LayoutPopup from "./LayoutPopup";

const imgList = [
  {
    layoutId: 1,
    layoutImg: layout1,
  },
  {
    layoutId: 2,
    layoutImg: layout2,
  },
  {
    layoutId: 3,
    layoutImg: layout3,
  },
  {
    layoutId: 4,
    layoutImg: layout4,
  },
  {
    layoutId: 5,
    layoutImg: layout5,
  },
  {
    layoutId: 6,
    layoutImg: layout6,
  },
];

const DUMMY_COMPONENT_TABLE_DATA = [
  { componentID: 12, componentName: "Navbar" },
  { componentID: 13, componentName: "Carousel" },
  { componentID: 14, componentName: "Banner" },
  { componentID: 15, componentName: "2 x Grid" },
  { componentID: 16, componentName: "3 x Grid" },
  { componentID: 17, componentName: "Body" },
  { componentID: 18, componentName: "Body - with Sidebar" },
  { componentID: 19, componentName: "Footer" },
  { componentID: 20, componentName: "Form" },
  { componentID: 21, componentName: "Cards carousel" },
  { componentID: 22, componentName: "Tab section" },
  { componentID: 23, componentName: "Accordion section" },
  { componentID: 24, componentName: "Signup/SignIn form" },
];

const DUMMY_SCREEN_DATA = [
  {
    screenID: 1,
    screenName: "HomeScreen",
    screenType: "multiple-component-screen",
    screenComponents: [
      { componentId: 12, componentName: "Navbar" },
      { componentId: 13, componentName: "Carousel" },
      { componentId: 19, componentName: "Cards carousel" },
      { componentId: 16, componentName: "Body" },
      { componentId: 17, componentName: "2 x Grid" },
      { componentId: 18, componentName: "Footer" },
    ],
  },
  {
    screenID: 2,
    screenName: "AboutScreen",
    screenType: "single-component-screen",
    screenComponents: [{ componentID: 19, componentName: "Form" }],
    formName: "AboutForm",
  },
  {
    screenID: 3,
    screenName: "ContactScreen",
    screenType: "single-component-screen",
    screenComponents: [{ componentID: 19, componentName: "Form" }],
    formName: "ContactForm",
  },
];
const Screens = () => {
  const [showForm, setShowForm] = useState(false);
  const [screenData, setScreenData] = useState(DUMMY_SCREEN_DATA);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedLayout, setSelectedLayout] = useState(null);
  const [componentsData, setComponentsData] = useState([]);


  const [newScreen, setNewScreen] = useState({
    screenName: "",
    screenType: "",
    screenComponents: [{ componentID: "", componentName: "" }],
  });
  const handleAddScreen = () => {
    if (
      !newScreen.screenName ||
      !newScreen.screenType ||
      !newScreen.screenComponents.length
    ) {
      alert("Please complete the form before adding a screen.");
      return;
    }

    console.log("Adding screen with components:", newScreen.screenComponents);

    const newScreenData = {
      screenID: screenData.length + 1,
      screenName: newScreen.screenName,
      screenType: newScreen.screenType,
      screenComponents: newScreen.screenComponents.map((comp) => {
        console.log("Looking for componentID:", comp.componentID);
        const component = DUMMY_COMPONENT_TABLE_DATA.find(
          (c) => c.componentID === parseInt(comp.componentID)
        );
        console.log("Found component:", component);
        return {
          componentId: comp.componentID,
          componentName: component
            ? component.componentName
            : "Unknown Component",
        };
      }),
    };

    setScreenData([...screenData, newScreenData]);
    setShowForm(false);
    setNewScreen({
      screenName: "",
      screenType: "",
      screenComponents: [],
    });
  };

  return (
    <div>
      <div className="flex justify-between mb-4">
        {isPopupOpen && (
          <LayoutPopup
          setComponentsData={setComponentsData}
            setSelectedLayout={setSelectedLayout}
            imgList={imgList}
            onClose={() => setIsPopupOpen(false)}
          />
        )}
        <div>
          <h1 className="text-xl font-semibold">SCREENS</h1>
          <p className="text-blue-500 font-semibold text-xs">
            Create and Edit your screens, then assign these screens to your
            routes.
          </p>
        </div>
      </div>

      <div className="shadow-lg p-4 ">
        <div className="flex">
          <input
            type="text"
            placeholder="Screen Name"
            value={newScreen.screenName}
            onChange={(e) =>
              setNewScreen({ ...newScreen, screenName: e.target.value })
            }
            className="block w-3/12 p-2 border rounded mb-2"
          />
          {/* <select
            value={newScreen.screenType} // Corrected from newScreen.screenName to newScreen.screenType
            onChange={(e) =>
              setNewScreen({ ...newScreen, screenType: e.target.value })
            }
            className="block w-3/12 ml-4 p-2 border rounded mb-2"
          >
            <option value="">Select Screen Type</option>
            <option value="single-component-screen">
              Single-Component Screen
            </option>
            <option value="multiple-component-screen">
              Multiple-Component Screen
            </option>
          </select> */}
          <button
            onClick={() => setIsPopupOpen(true)}
            className="p-2 mb-2 ml-10  text-gray-500 rounded-lg border border-gray-300"
          >
            Select Layout
          </button>
          {selectedLayout && (
            <div className=" text-xl ml-10 text-center font-semibold">
              Selected layout :{selectedLayout}
            </div>
          )}
           {selectedLayout && 
           <button className=" bg-sky-400 p-2 ml-10 hover:bg-sky-600 text-white font-semibold rounded  ">Preview Layout</button>
           }
          {newScreen.screenType == "single-component-screen" && (
            <select
              value={newScreen.screenComponents[0]?.componentID || ""}
              onChange={(e) =>
                setNewScreen({
                  ...newScreen,
                  screenComponents: [
                    {
                      componentID: parseInt(e.target.value), // Ensure this matches the type in your component data array
                      componentName:
                        e.target.options[e.target.selectedIndex].text,
                    },
                  ],
                })
              }
              className="block w-3/12 ml-4 p-2 border rounded mb-2"
            >
              <option value="">Select Component</option>
              {DUMMY_COMPONENT_TABLE_DATA.map((component) => (
                <option
                  key={component.componentID}
                  value={component.componentID}
                >
                  {component.componentName}
                </option>
              ))}
            </select>
          )}
        </div>
        {newScreen.screenType == "multiple-component-screen" && (
          <div className="w-6/12">
            <SelectMultipleComponents
              components={newScreen.screenComponents}
              setComponents={(comps) =>
                setNewScreen({ ...newScreen, screenComponents: comps })
              }
            />
          </div>
        )}
        <button
          onClick={handleAddScreen}
          className="rounded-md p-1 w-3/12 bg-sky-500 text-white hover:bg-sky-700"
        >
          + Add New Screen
        </button>
      </div>

      <div className="mt-4">
        <div className="shadow-md">
          {/* <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Serial No.
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Screen Name
                </th>

                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Selected Component
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {screenData.map((screen, index) => (
                <tr key={screen.screenID}>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                    {index + 1}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {screen.screenName}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {screen.screenComponents.length < 2 ? (
                      <span className="mr-2 p-1 text-[10px] bg-gray-400 text-white">
                        {screen.screenComponents[0].componentName}
                      </span>
                    ) : (
                      screen.screenComponents.map((comp) => (
                        <span
                          key={index}
                          className="mr-2 p-1 text-[10px] bg-gray-400 text-white"
                        >
                          {comp.componentName || "Component not found"}
                        </span>
                      ))
                    )}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                    <button className="text-indigo-600 hover:text-indigo-900 mr-4">
                      Edit
                    </button>
                    <button className="text-red-600 hover:text-red-900">
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table> */}

          <div className="flex flex-col">
            {componentsData && 
            <div className=" font-semibold text-blue-600 py-2 text-center">
              Component List for the selected layout
            </div>
            }
            {componentsData && 
              componentsData.map((comp) => (
                <div className="border p-3 border-gray-200 ">{comp.type}</div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Screens;
