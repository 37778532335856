import React, {useRef, useState} from "react";
import MultiSelectDropdown from "../../../MultiSelect/MultiSelectDropdown";
import ScreenInputList from "./ScreenInputList";
//   // Dummy data for options
const userRoles = [
  { label: "Admin", value: "admin" },
  { label: "Manager", value: "manager" },
  { label: "Employee", value: "employee" },
  { label: "Customer", value: "customer" },
  { label: "Shopkeeper", value: "shopkeeper" },
  { label: "Moderator", value: "moderator" },
  { label: "Contributor", value: "contributor" },
  { label: "Subscriber", value: "subscriber" },
  { label: "Guest", value: "guest" },
  { label: "Analyst", value: "analyst" },
];

//   // Handler for when the selected values change
//   const handleChange = (newValues) => {
//     setSelectedValues(newValues);
//   };

const ScreenFlow = () => {
  const screenFlowRef = useRef();

  const [selectedUserRolse, setSelectedUserRoles] = useState([]);

  const [screens, setScreens] = useState([""]);

  const handleScreenFlowSubmit = () => {
    const screenFlowData = {
      screenFlow: screenFlowRef.current.value,
      allowedRoles: selectedUserRolse,
      Screens: screens,
    };

    const jsonData = JSON.stringify(screenFlowData);
    console.log(jsonData);

    //again empty all the inputFields
    screenFlowRef.current.value = ''
    setScreens([""])
    setSelectedUserRoles([])

  };

  const handleChange = (newValues) => {
    setSelectedUserRoles(newValues);
  };
  return (
    <div>
      <h2 className="font-semibold text-xs text-left">
        Screen flow refers to the sequence of screens a user navigates through
        to complete a series of tasks or steps within an application, ensuring a
        smooth and logical progression from start to finish.
      </h2>
      <h2 className="font-semibold text-xs text-left mt-3">
        Add sequence of steps by clicking in + icon,
      </h2>
      <h2 className="font-semibold text-xs text-left text-red-600 ">
        Note: the order you add same way screens will be rendered.. the order
        can be changed before you confirm.
      </h2>

      <div className="flex justify-between mt-2">
        <div className="flex flex-col w-6/12 text-xs font-semibold text-gray-500 mr-2">
          <label htmlFor="screenFlowName  ">
            Give a name to your Screen Flow
          </label>

          <input
            ref={screenFlowRef}
            type="text"
            id="screenFlowName"
            className="p-[6px] w-full  text-black border-gray-300 border "
          />
        </div>
        <div className="flex flex-col  text-xs font-semibold text-gray-500 w-6/12">
          <label htmlFor="dropDown ">Assign to</label>

          <MultiSelectDropdown
            id="dropDown"
            className="w-full border-gray-300 border"
            defaultValue={userRoles[0]["label"]} // Pre-selected options
            onChange={handleChange}
            optionLabelProp="label"
            options={userRoles}
          />
        </div>
      </div>

      <div className="w-4/12">
        <ScreenInputList screens={screens} setScreens={setScreens} />
      </div>
      <button
        onClick={handleScreenFlowSubmit}
        className="px-2 text-sm  border-2 border-skt-blue text-skt-blue rounded-full hover:bg-skt-blue hover:text-white "
      >
        Save and create new
      </button>
    </div>
  );
};

export default ScreenFlow;
