import React from "react";
import { hero3, hero4 } from "../../assets/images";
import {
  PiNumberSquareOneLight,
  PiNumberSquareThreeLight,
  PiNumberSquareTwoLight,
} from "react-icons/pi";
import { FaCode, FaLaughWink } from "react-icons/fa";
import { MdSupportAgent } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const Homepage = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    company: "",
    message: "",
  });
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });

    // Clear the error message for this field as the user types
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: "",
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Check for empty fields
    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.phone) newErrors.phone = "Phone is required";
    if (!formData.company) newErrors.company = "Company is required";
    if (!formData.message) newErrors.message = "Message is required";

    setErrors(newErrors);

    // If no errors, proceed with form submission (e.g., send data to an API)
    if (Object.keys(newErrors).length === 0) {
      console.log("Form data:", formData);
    }
  };

  return (
    <main className="bg-">
      <section id="home" className="relative">
        <div className="blob1 "></div>

        <div className="blob2"></div>

        <div className="container py-20  ">
          <div className="flex flex-col items-center z-20 md:flex-row">
            <div className="text-center mb-12 md:text-left md:w-1/2 md:pr-10">
              <h1 className="title mb-4">Build Apps Without Code!</h1>
              <p className="leading-relaxed mb-10">
                Are you ready to turn your ideas into reality without writing a
                single line of code? Look no further! MUDUMBAI empowers
                individuals with all levels of technical expertise to create
                custom mobile and web applications effortlessly.
              </p>
              <button
                onClick={(event) => {
                  event.preventDefault();
                  navigate("/auth/signup");
                }}
                className="bg-color-secondary text-white px-9 py-3 rounded-md capitalize font-bold hover:opacity-80 ease-in duration-200"
              >
                Get Started
              </button>
            </div>

            <div className="md:w-1/2">
              <img src={hero4} alt="" />
            </div>
          </div>
        </div>
      </section>

      <section id="features" className="bg-color-primary-light text-white">
        <div className="container py-20">
          <div className="text-center m-auto mb-20 md:w-1/2">
            <h4 className="font-bold text-color-secondary mb-4">
              Our Features
            </h4>
            <h1 className="title">
              Code-Free, Stress-Free: MUDUMBAI's Promise.
            </h1>
          </div>

          <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-12 lg:gap-8 px-4 sm:px-6 lg:px-8">
            <div className="border-2 border-solid border-color-gray text-center py-20 px-5 rounded-2xl cursor-pointer hover:bg-color-primary-dark ease-in duration-200">
              <div className="bg-color-secondary inline-block rounded-2xl py-5 px-6">
                <FaCode className="text-4xl text-white" />
              </div>
              <h3 className="text-xl font-bold py-4">No-code Platform</h3>
              <p className="leading-relaxed">
                that allows anyone, regardless of their coding experience, to
                design, develop, and deploy fully functional applications.
              </p>
            </div>

            <div className="border-2 border-solid border-color-gray text-center py-20 px-5 rounded-2xl cursor-pointer hover:bg-color-primary-dark ease-in duration-200">
              <div className="bg-color-secondary inline-block rounded-2xl py-5 px-6">
                <FaLaughWink className="text-4xl text-white" />
              </div>
              <h3 className="text-xl font-bold py-4">Too convenient</h3>
              <p className="leading-relaxed">
                With MUDUMBAI, building apps is as easy as filling out a form.
              </p>
            </div>

            <div className="border-2 border-solid border-color-gray text-center py-20 px-5 rounded-2xl cursor-pointer hover:bg-color-primary-dark ease-in duration-200">
              <div className="bg-color-secondary inline-block rounded-2xl py-5 px-6">
                <MdSupportAgent className="text-4xl text-white" />
              </div>
              <h3 className="text-xl font-bold py-4">Support 24/7</h3>
              <p className="leading-relaxed">
                Our dedicated support team is here to help you every step of the
                way.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="saving-money">
        <div className="container py-20 relative">
          <div className="blob1"></div>

          <div className="blob2"></div>

          <div className="flex flex-col items-center justify-between md:flex-row">
            <div className="mb-12 md:w-1/2">
              <img src={hero3} alt="" />
            </div>

            <div className="text-center md:text-left md:w-1/2 md:ml-20">
              <h4 className="font-bold text-color-secondary mb-4">
                Crafting Dreams, One Click at a Time.
              </h4>
              <h1 className="title mb-4">
                We help you to turn your vision into reality!
              </h1>
              <p className="leading-relaxed mb-10">
                Are you ready to turn your ideas into reality without writing a
                single line of code? Look no further! MUDUMBAI empowers
                individuals with all levels of technical expertise to create
                custom mobile and web applications effortlessly.
              </p>
              <button className="btn">Read More</button>
            </div>
          </div>
        </div>
      </section>

      <section id="how-it-works" className="bg-color-primary-light text-white">
        <div className="container py-20">
          <div className="text-center m-auto mb-20 md:w-1/2">
            <h4 className="font-bold text-color-secondary mb-4">
              How It Works
            </h4>
            <h1 className="title">Effortless Website Creation</h1>
          </div>

          <div className="flex flex-col items-center justify-between space-y-6 md:flex-row md:space-y-0 md:space-x-6">
            <div className="text-center cursor-pointer">
              <div className="relative bg-color-primary-dark inline-block px-6 py-3 rounded-lg cursor-pointer hover:bg-color-secondary ease-in duration-200">
                <p className="text-6xl text-white">
                  <PiNumberSquareOneLight />
                </p>
              </div>
              <h3 className="text-xl font-bold py-4">Signup with Mudumbai</h3>
              <p className="leading-relaxed">
                create your mudumbai account and help us to know about your
                requirements.
              </p>
            </div>

            <div className="text-center cursor-pointer">
              <div className="relative bg-color-primary-dark inline-block px-6 py-3 rounded-lg cursor-pointer hover:bg-color-secondary ease-in duration-200">
                <p className="text-6xl text-white">
                  <PiNumberSquareTwoLight />
                </p>
              </div>
              <h3 className="text-xl font-bold py-4">Setup Your Dashboard</h3>
              <p className="leading-relaxed">
                We provide your easy to use dashboard , customize it according
                to project
              </p>
            </div>
            <div className="text-center cursor-pointer">
              <div className="relative bg-color-primary-dark inline-block px-6 py-3 rounded-lg cursor-pointer hover:bg-color-secondary ease-in duration-200">
                <p className="text-6xl text-white">
                  <PiNumberSquareThreeLight />
                </p>
              </div>
              <h3 className="text-xl font-bold py-4">Enjoy The Features!</h3>
              <p className="leading-relaxed">
                Dive into a vast collection of templates designed to suit every
                taste and purpose
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="contact">
        <div className="container py-20">
          <div className="text-center m-auto mb-20 md:w-1/2">
            <h4 className="font-bold text-color-secondary mb-4">
              Have A Question
            </h4>
            <h1 className="title">Get In Touch</h1>
          </div>

          <form
            onSubmit={handleSubmit}
            className="w-full m-auto text-center md:w-2/3"
          >
            <div className="text-color-primary-dark grid gap-6 mb-6 md:grid-cols-2">
              <div>
                <input
                  type="text"
                  name="name"
                  className="bg-gray-50 border border-gray-300 text-sm rounded-lg block w-full p-3 focus:outline-none focus:border-color-secondary"
                  placeholder="Name"
                  value={formData.name}
                  onChange={handleChange}
                />
                {errors.name && (
                  <p className="text-red-500 text-sm">{errors.name}</p>
                )}
              </div>

              <div>
                <input
                  type="email"
                  name="email"
                  className="bg-gray-50 border border-gray-300 text-sm rounded-lg block w-full p-3 focus:outline-none focus:border-color-secondary"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                />
                {errors.email && (
                  <p className="text-red-500 text-sm">{errors.email}</p>
                )}
              </div>

              <div>
                <input
                  type="tel"
                  name="phone"
                  className="bg-gray-50 border border-gray-300 text-sm rounded-lg block w-full p-3 focus:outline-none focus:border-color-secondary"
                  placeholder="Phone"
                  value={formData.phone}
                  onChange={handleChange}
                />
                {errors.phone && (
                  <p className="text-red-500 text-sm">{errors.phone}</p>
                )}
              </div>

              <div>
                <input
                  type="text"
                  name="company"
                  className="bg-gray-50 border border-gray-300 text-sm rounded-lg block w-full p-3 focus:outline-none focus:border-color-secondary"
                  placeholder="Company"
                  value={formData.company}
                  onChange={handleChange}
                />
                {errors.company && (
                  <p className="text-red-500 text-sm">{errors.company}</p>
                )}
              </div>
            </div>

            <div>
              <textarea
                rows="4"
                name="message"
                className="text-color-primary-dark bg-gray-50 border border-gray-300 text-sm rounded-lg block w-full p-3 focus:outline-none focus:border-color-secondary"
                placeholder="Message"
                value={formData.message}
                onChange={handleChange}
              ></textarea>
              {errors.message && (
                <p className="text-red-500 text-sm">{errors.message}</p>
              )}
            </div>

            <button className="btn mt-10">
              Send Message
            </button>
          </form>
        </div>
      </section>
    </main>
  );
};

export default Homepage;