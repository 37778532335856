// src/components/forms/ButtonForm.js
import React from "react";
import ButtonPreview from "../previews/ButtonPreview";

export const getDefaultButtonConfig = () => ({
  label: "Click Me",
  backgroundColor: "#007bff",
  textColor: "#ffffff",
  padding: "10px 20px",
  borderRadius: "5px",
});

const ButtonForm = ({ config, onConfigChange }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    onConfigChange({ ...config, [name]: value });
  };

  return (
    <div>
      <h3>Button Settings</h3>
      <label>
        Label:
        <input
          type="text"
          name="label"
          value={config.label}
          onChange={handleChange}
        />
      </label>
      <label>
        Background Color:
        <input
          type="color"
          name="backgroundColor"
          value={config.backgroundColor}
          onChange={handleChange}
        />
      </label>
      <label>
        Text Color:
        <input
          type="color"
          name="textColor"
          value={config.textColor}
          onChange={handleChange}
        />
      </label>
      <label>
        Padding:
        <input
          type="text"
          name="padding"
          value={config.padding}
          onChange={handleChange}
        />
      </label>
      <label>
        Border Radius:
        <input
          type="text"
          name="borderRadius"
          value={config.borderRadius}
          onChange={handleChange}
        />
      </label>
    </div>
  );
};

export default ButtonForm;
export { ButtonPreview };
