// src/components/forms/InputForm.js
import React from "react";
import TextInputPreview from "../previews/TextInputPreview";

export const getDefaultInputConfig = () => ({
  placeholder: "Enter text",
  value: "",
  width: "200px",
});

const TextInputForm = ({ config, onConfigChange }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    onConfigChange({ ...config, [name]: value });
  };

  return (
    <div>
      <h3>Input Settings</h3>
      <label>
        Placeholder:
        <input
          type="text"
          name="placeholder"
          value={config.placeholder}
          onChange={handleChange}
        />
      </label>
      <label>
        Value:
        <input
          type="text"
          name="value"
          value={config.value}
          onChange={handleChange}
        />
      </label>
      <label>
        Width:
        <input
          type="text"
          name="width"
          value={config.width}
          onChange={handleChange}
        />
      </label>
    </div>
  );
};

export default TextInputForm;
export { TextInputPreview };
