import React, { useEffect, useState } from "react";
import FormBuilder from "./FormBuilder";
import FormName from "./FormName";
import FormPreview from "./FormPreview";
import useFormStore from "../../../store/useFormStore";
const FormConfiguration = () => {
  const { step, decrementStep } = useFormStore();

  return (
    <div className="h-auto w-full flex flex-col mb-36">
      <section className="pt-28 flex first-letter:items-center justify-center">
        {step === 0 && <FormName />}
        {step === 1 && <FormBuilder />}
        {step === 2 && <FormPreview />}
      </section>
      <div className="flex justify-center items-center">
        {step != 0 && (
          <button
            onClick={decrementStep}
            className="px-6 py-3 mt-5 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-blue-100 transition-all duration-300 ease-in-out transform hover:scale-105 flex items-center space-x-2"
          >
            Back
          </button>
        )}
      </div>
    </div>
  );
};

export default FormConfiguration;