import React from "react";
import "./Footer4.css";
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaLinkedin,
  FaYoutube,
} from "react-icons/fa";
const FooterTemplate4 = ({ config }) => {
  if (!config) {
    return (
      <footer className="footer-template">
        <div className="footer-section">
          <div className="footer-column logo-section">
            <h2>LOGO</h2>
            <div className="social-icons">
              <span>🔗</span>
              <span>🔗</span>
              <span>🔗</span>
              <span>🔗</span>
            </div>
          </div>
          <div className="footer-column">
            <h3>Quick Links</h3>
            <a href="#">Link 1</a>
            <a href="#">Link 2</a>
            <a href="#">Link 3</a>
            <a href="#">Link 4</a>
          </div>
          <div className="footer-column">
            <h3>Contact Info</h3>
            <p>📞 General Phone</p>
            <p>✉️ General Email</p>
            <p>🌐 General Website</p>
          </div>
        </div>
      </footer>
    );
  }
  const socialMediaData = config?.socialMediaIcons.map((icon, index) => ({
    id: index + 1,
    icon: icon,
    link: config?.socialMediaLinks[index] || "", // Use an empty string if there's no corresponding link
  }));
  console.log("socialMediaData", socialMediaData);
  return (
    <footer className="footer-template">
      <div className="flex items-center gap-2">
        <img src={config?.logoUrl} alt="abcd" className="w-12 h-12" />
        <span className="logo">{config?.logoText}</span>
      </div>
      <div className="footer-section">
        <div className="footer-column logo-section">
          <h2>LOGO</h2>
          <div className="social-icons">
            {socialMediaData?.map((item, index) => (
              <a href={item.link} target="_blank" rel="noopener noreferrer" key={index}>
                {item.icon === "facebook" && <FaFacebook />}
                {item.icon === "instagram" && <FaInstagram />}
                {item.icon === "linkedin" && <FaLinkedin />}
                {item.icon === "twitter" && <FaTwitter />}
                {item.icon === "youtube" && <FaYoutube />}
              </a>
            ))}
          </div>
        </div>
        {config.links.map((ele, index) => {
          const heading = Object.keys(ele)[0];
          const linksOfHeading = ele[heading];
          return (
            <div key={index} className="footer-column">
              <h4>{heading}</h4>
              {linksOfHeading.map((link, index) => (
                <a href="#" key={index}>
                  {link}
                </a>
              ))}
            </div>
          );
        })}
        <div className="footer-column">
          <h3>Contact Info</h3>
          <p>📞 {config.contactInfo.phone}</p>
          <p>✉️ {config.contactInfo.email}</p>
        </div>
      </div>
    </footer>
  );
};

export default FooterTemplate4;
