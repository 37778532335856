import React from 'react';
import {FaCheck} from "react-icons/fa";

const SuccessPopup = ({ closeFunction, message }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className='flex flex-col items-center text-gray-400 bg-white shadow-xl rounded-xl p-8 space-y-4'>
        <div className=" rounded-full bg-green-500 text-white text-3xl p-4">
          <FaCheck />
        </div>
        <h2 className='text-lg text-green-500 font-bold'>Success!</h2>
        <p className='text-sm text-center'>{message}</p>
        <button onClick={closeFunction} className='bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-700 focus:ring-opacity-50'>
          Okay
        </button>
      </div>
    </div>
  );
}

export default SuccessPopup;
