
import React from 'react';
import emailjs from "@emailjs/browser";
import Notification from '../services/Notification';

const SendEmail = async ({serviceId, templateId, userId,templateParams,verifyToken}) => {
    
    try{
        await emailjs.send(
            serviceId,
            templateId,
            templateParams,
            userId
        );
       
        console.log("enail sent ");
        

    }catch(error){

        Notification.error("Error","Failed to send email");
       console.error("Email sending error:", error);
        

    }
};

export default SendEmail