import React from "react";
import useComponentStore from "../../../../../store/useComponentsStore";

const ImageComponent = () => {
  const imageData = useComponentStore((state) => state.imageData);
  console.log("w", imageData.width);
  console.log("h", imageData.height);
  return (
    <div>
      <div className="md-8 p-6 rounded-lg shadow-lg bg-gray-200">
        <img
          src={imageData.imageSrc}
          alt="Default image"
          style={{
            width: `${imageData.width}px`,
            height: `${imageData.height}px`,
          }}
          className={`object-cover border border-red-500  ${
            imageData.shadow ? "shadow-[0px_6px_12px_rgba(0,0,0,0.7)]" : ""
          }`}
        />
      </div>
    </div>
  );
};

export default ImageComponent;
