"use client";
import { useParams } from "react-router-dom";
import useFormStore from "../../../store/useFormStore";
import { useState } from "react";
import Notification from "../../../services/Notification";

export default function FormName() {
  const { setFormName, setFormDescription, incrementStep, formConfiguration } =
    useFormStore();

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const name = formData.get("formName");
    const description = formData.get("formDescription");
    setFormName(name);
    setFormDescription(description);
    incrementStep();
  };
  console.log("this is the form configuration");
  console.log(formConfiguration);
  return (
    <div className="md:w-1/3 h-auto  w-full mx-auto p-4 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold mb-4 text-gray-800">
        Form Details
      </h2>

      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="w-full">
          <label
            htmlFor="formName"
            className="block text-sm font-medium w-full text-gray-700 mb-1"
          >
            Form Name
          </label>
          <input
            type="text"
            id="formName"
            name="formName"
            required
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 "
            placeholder="Enter form name"
            defaultValue={formConfiguration.formName}
          />
        </div>
        <div>
          <label
            htmlFor="formDescription"
            className="block text-sm w-full font-medium text-gray-700 mb-1"
          >
            Form Description
          </label>
          <textarea
            id="formDescription"
            name="formDescription"
            rows={3}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Enter form description"
            defaultValue={formConfiguration.formDescription}
          ></textarea>
        </div>
        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors"
        >
          Next
        </button>
      </form>
    </div>
  );
}
