// src/components/previews/TextPreview.js
import React from "react";

const TextPreview = ({ config }) => {
  const { content, fontSize, color, alignment } = config;

  return (
    <div
      style={{
        fontSize: fontSize || "16px",
        color: color || "#333333",
        textAlign: alignment || "left",
        padding: "10px",
      }}
    >
      {content || "Sample Text"}
    </div>
  );
};

export default TextPreview;
