import React, {useEffect, useState} from "react";
import "./dropdown.css";

const Dropdown = ({ options, onSelect, disabled, value }) => {
  const [selectedOption, setSelectedOption] = useState(value || "");

  useEffect(() => {
    setSelectedOption(value || "");
  }, [value]);

  const handleSelect = (option) => {
    setSelectedOption(option);
    onSelect(option);
  };

  return (
    <div className="dropdown">
      <select
        className="dropdownStyle"
        value={selectedOption}
        onChange={(e) => handleSelect(e.target.value)}
        disabled={disabled}
      >
        <option value="">Select an option</option>
        {options.map((option, index) => (
          <option key={index} value={option}>
            {option}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Dropdown;
