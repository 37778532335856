'use client';
import { useStore } from '../../store';
import { paymentMethods } from '../../utils/utils/paymentMethods';
import PaymentConfig from '../../components/payment/PaymentConfig';
const   PaymentMethods=()=> {
  const {  setShowPaymentConfigTrue, showPaymentConfig,selectedPaymentMethods,removePaymentMethod,addPaymentMethod}=useStore()
  const handleSelect = (method) => {
    if (isSelected(method)) {
      removePaymentMethod(method);
    } else {
      addPaymentMethod(method);
    }
  };

  const handleConfigure = () => {
    
  };

  const isSelected = (method) =>
    selectedPaymentMethods.some((selected) => selected.name === method.name);

  return (
    <>
    {!showPaymentConfig?
    <section className="flex flex-col items-center justify-center p-6 bg-slate-900 min-h-screen">
      <div className="w-full max-w-4xl lg:w-1/3 bg-slate-800 rounded-lg shadow-lg overflow-hidden">
        <h3 className="text-3xl font-extrabold text-slate-100 mb-8 text-center">Select Your Payment Methods</h3>
        <div className="flex flex-wrap justify-center gap-6 mb-8 p-4">
          {paymentMethods.map((item, index) => (
            <div
              key={index}
              className={`flex items-center p-6 border rounded-lg shadow-lg cursor-pointer transition-all duration-300 transform ${isSelected(item)
                ? 'bg-slate-600 border-slate-500 scale-105'
                : 'bg-slate-700 border-slate-600 hover:bg-slate-600'
                }`}
              onClick={() => handleSelect(item)}
            >
              <img src={item.imageUrl} alt={item.name}  className="mr-4 rounded-full w-[60px] h-[60px]" />
              <h4 className={`text-lg font-semibold ${isSelected(item) ? 'text-slate-300' : 'text-slate-200'
                }`}>{item.name}</h4>
            </div>
          ))}
        </div>
        {selectedPaymentMethods.length > 0 && (
          <div className="flex justify-end p-4">
            <button
              onClick={()=>  setShowPaymentConfigTrue()}
              className="px-5 py-2 bg-slate-600 text-slate-100 rounded-md shadow-md hover:bg-slate-500 transition-all duration-300 text-sm"
            >
              Configure
            </button>
          </div>
        )}
      </div>
    </section>:
    <PaymentConfig/>
}
    </>
  );

}
export default PaymentMethods