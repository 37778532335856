import React from "react";
import {Select} from "antd";
// import "./multiSelectDropdown.css"





// BELOW IS THE RENDERING CODE FOR THIS COMPONENT

// const [selectedValues, setSelectedValues] = useState([]);
//   // Dummy data for options
//   const optionsData = [
//     { label: 'Option 1', value: 'option1' },
//     { label: 'Option 2', value: 'option2' },
//     { label: 'Option 3', value: 'option3' },
//     // Add more options as needed
//   ];

//   // Handler for when the selected values change
//   const handleChange = (newValues) => {
//     setSelectedValues(newValues);
//   };


//  <MultiSelectDropdown
// defaultValue={['option1']} // Pre-selected options
// onChange={handleChange}
// optionLabelProp="label"
// options={optionsData}
// />




const MultiSelectDropdown = ({defaultValue, onChange, optionLabelProp, options}) => {
  return (
    <div className="">
      <Select mode="multiple" 
        className="w-full rounded-none" 
       defaultValue={defaultValue}
       onChange={onChange}
       optionLabelProp={optionLabelProp}
       options={options}
      />
    </div>
  );
};

export default MultiSelectDropdown;
