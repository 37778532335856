import React from "react";
import { GoogleLogin } from "@react-oauth/google";
const Googlelogin = () => {
  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <GoogleLogin />
      </div>
    </>
  );
};
export default Googlelogin;
