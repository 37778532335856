import React from 'react';

const Link2 = () => {
  return (
    <div>

      <p>Please configure this page</p>
    </div>
  );
}

export default Link2;