import Workflow from "./components/Admin/WorkFlow/WorkFlow";
import SignInForm from "./components/SignIn/SignInForm";
import SignupForm from "./components/Signup/SignupForm";
import ApiIntegration from "./components/Admin/ApiIntegration/ApiIntegration";
import Security from "./components/Admin/Security/Security";
import BussinessLogic from "./components/Admin/BussinessLogic/BussinessLogic";
import UserProfile from "./components/userProfile/UserProfile";

// import HomePage from "./components/HomePage/HomePage";
import Forms from "./components/Admin/UserInterface/Forms/Forms";
import Styles from "./components/Admin/UserInterface/Styles/Styles";
import { GiProcessor } from "react-icons/gi";
import { BsClipboardData } from "react-icons/bs";
import { LuLayoutDashboard } from "react-icons/lu";
import { GoWorkflow } from "react-icons/go";
import { GrUserAdmin } from "react-icons/gr";
import { AiOutlineApi } from "react-icons/ai";
import { MdOutlineSecurity } from "react-icons/md";
import { SiSimpleanalytics } from "react-icons/si";
import ProcessDesign from "./components/Admin/ProcessDesign/ProcessDesign";
import UserInterFace from "./components/Admin/UserInterface/UserInterFace";
import Admin from "./components/Admin/Admin/Admin";
import Assets from "./components/Admin/Admin/Assets/Assets";
import ManageData from "./components/Admin/DataDesign/ManageData";
import CreateData from "./components/Admin/DataDesign/CreateData";
import DataDesign from "./components/Admin/DataDesign/DataDesign";
import ScreenFlow from "./components/Admin/ProcessDesign/ScreenFlow/ScreenFlow";
import WorkFlow from "./components/Admin/ProcessDesign/WorkFlow/WorkFlow";
import Authentication from "./components/Admin/Security/Authentication/Authentication";
import Authorization from "./components/Admin/Security/Authorization/Authorization";
import FormBuilder from "./components/Admin/formBuilder/FormBuilder";
import BusinessRules from "./components/Admin/Security/Authorization/businessRules/BusinessRules";
import ManageRoutes from "./components/Admin/UserInterface/Routes/ManageRoutes";
import Screens from "./components/Admin/UserInterface/Screens/Screens";
import Components from "./components/Admin/UserInterface/Components/Components";
import preview from "./components/Admin/preview/preview";
import ForgotPassword from "./components/SignIn/ForgotPassword";
import ResetPassword from "./components/SignIn/ResetPassword";
import FormConfiguration from "./components/Admin/formBuilder/FormConfiguration";

const routes = [
  {
    path: "/data_design",
    name: "Data Design",
    layout: "/admin",
    menuIcon: <BsClipboardData />,
    component: DataDesign,
    nextPage: "process_design",
    prevPage: "none",
    children: [
      {
        path: "/createData",
        name: "Create Data",

        component: CreateData,
        layout: "/admin",
        menuIcon: <LuLayoutDashboard />,
      },
      {
        path: "/manageData",
        name: "Manage Data",

        component: ManageData,
        layout: "/admin",
        menuIcon: <LuLayoutDashboard />,
      },
    ],
  },
  {
    path: "/process_design",
    name: "Process Design",
    layout: "/admin",
    menuIcon: <GiProcessor />,
    component: ProcessDesign,

    nextPage: "UserInterface",
    prevPage: "data_design",
    children: [
      {
        path: "/Workflow",
        name: "Workflow",

        component: WorkFlow,
        layout: "/admin",
        menuIcon: <LuLayoutDashboard />,
      },
      {
        path: "/ScreenFlow",
        name: "Screen Flow",

        component: ScreenFlow,
        layout: "/admin",
        menuIcon: <LuLayoutDashboard />,
      },
      {
        path: "/manageroutes",
        name: "Manage Routes",

        component: ManageRoutes,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/UserInterface",
    name: "UserInterface",
    nextPage: "Styles",
    prevPage: "process_design",
    layout: "/admin",
    menuIcon: <LuLayoutDashboard />,
    component: UserInterFace,
    children: [
      {
        path: "/Forms",
        name: "Forms",
        component: FormConfiguration,
        layout: "/admin",
      },
      {
        path: "/Components",
        name: "Components",

        component: Components,
        layout: "/admin",
      },
      {
        path: "/Screens",
        name: "Screens",

        component: Screens,
        layout: "/admin",
      },
    ],
  },

  {
    path: "/Styles",
    name: "Styles",
    nextPage: "Admin",
    prevPage: "UserInterface",
    component: Styles,
    layout: "/admin",
    menuIcon: <GoWorkflow />,
  },
  {
    path: "/Admin",
    name: "Admin",
    nextPage: "Data",
    prevPage: "Styles",
    component: Admin,
    layout: "/admin",
    menuIcon: <GrUserAdmin />,
    children: [
      {
        path: "/Configuration",
        name: "Configuration",

        component: Forms,
        layout: "/admin",
      },
      {
        path: "/UserManagement ",
        name: "User Management ",

        component: Styles,
        layout: "/admin",
      },
      {
        path: "/BulkActions ",
        name: "Bulk Actions ",

        component: Styles,
        layout: "/admin",
      },
      {
        path: "/Assets ",
        name: "Assets ",

        component: Assets,
        layout: "/admin",
      },
      {
        path: "/preview ",
        name: "Preview ",

        component: preview,
        layout: "/admin",
      },
    ],
  },

  {
    path: "/Integration",
    name: "Integration",
    nextPage: "Security",
    prevPage: "Data",
    component: ApiIntegration,
    layout: "/admin",
    menuIcon: <AiOutlineApi />,
  },
  {
    path: "/Security",
    name: "Security",
    nextPage: "BussinessLogic",
    prevPage: "Integration",
    component: Security,
    layout: "/admin",
    menuIcon: <MdOutlineSecurity />,
    children: [
      {
        path: "/Authentication",
        name: "Authentication",

        component: Authentication,
        layout: "/admin",
      },
      {
        path: "/Authorization ",
        name: "Authorization ",

        component: Authorization,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/BussinessLogic",
    name: "BussinessLogic",
    nextPage: "none",
    prevPage: "Security",

    component: BussinessLogic,
    layout: "/admin",
    menuIcon: <SiSimpleanalytics />,
    children: [
      {
        path: "/ApprovalFlow",
        name: "Approval flow",

        component: Forms,
        layout: "/admin",
      },
      {
        path: "/BusinessValidations ",
        name: "Business Validations ",

        component: Styles,
        layout: "/admin",
      },
      {
        path: "/businessRules ",
        name: "Business Rules ",

        component: BusinessRules,
        layout: "/admin",
      },
    ],
  },
  {
    path: "/signup",
    name: "Sign Up",
    component: SignupForm,
    layout: "/auth",
  },
  {
    path: "/signin",
    name: "Sign In",
    component: SignInForm,
    layout: "/auth",
  },
  {
    path: "/forgotPassword",
    name: "forgot password",
    component: ForgotPassword,
    layout: "/auth",
  },
  {
    path: "/resetPassword",
    name: "reset password",
    component: ResetPassword,
    layout: "/auth",
  },
  {
    path: "/userDataEntry",
    name: "User Data Entry",
    component: UserProfile,
    layout: "/auth",
  },

 
];

export default routes;
