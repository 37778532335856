import {useState} from "react";
import RBAC from "./RBAC";
import ABAC from "./ABAC";
import Notification from "../../../../services/Notification";
import Api from "../../../../services/Api";
import TableAndColumnsSelect from "./TableAndColumnsSelect";
import {attributeSubmitHandler} from "./helper";

const Authorization = () => {
  const [rbacBlocks, setRbacBlocks] = useState([
    {
      // blockId: 1,
      ruleName: "",
      description: "",
      appId: "newApp",
      required: false,
      enableCondition: false,
      createdAt: null,
      createdBy: null,
      updatedAt: null,
      updatedBy: null,

      conditions: [
        {
          conditionId: 1,
          operator: "IF",
          attributeName: "role",
          operationValue: "",
          expectedValue: "",
          createdAt: null,
          createdBy: null,
          updatedAt: null,
          updatedBy: null,
        },
      ],
    },
  ]);
  const [abacBlocks, setAbacBlocks] = useState([
    {
      ruleName: "",
      description: "",
      appId: "newApp",
      required: false,
      enableCondition: false,
      createdAt: null,
      createdBy: null,
      updatedAt: null,
      updatedBy: null,
      conditions: [
        {
          conditionId: 1,
          operator: "IF",
          attributeId: "",
          operationValue: "",
          expectedValue: "",
          otherAttributes: "",
          createdAt: null,
          createdBy: null,
          updatedAt: null,
          updatedBy: null,
        },
      ],
    },
  ]);

  const [reload, setReload] = useState(false);

  const [tableColumnsSelector, setTableColumnsSelector] = useState([
    { key: 1 },
  ]);
  const [selectedColumnNames, setSelectedColumnNames] = useState([]);

  const [selectedOption, setSelectedOption] = useState("ABAC");

  const addAnotherTableCol = () => {
    const newKey = Date.now(); // Unique key using current timestamp
    setTableColumnsSelector([...tableColumnsSelector, { key: newKey }]);
  };

  const handleDeleteTCSelect = (key) => {
    setTableColumnsSelector(
      tableColumnsSelector.filter((tc) => tc.key !== key)
    );
  };

  const handleAddToSelectedCols = (newValues) => {
    setSelectedColumnNames((prevCols) => [
      ...prevCols,
      ...newValues.filter((val) => !prevCols.includes(val)), // Avoid duplicates
    ]);
  };

  const handleAddBlock = () => {
    if (selectedOption == "RBAC") {
      const newBlock = {
        ruleName: "",
        description: "",
        appId: "newApp",
        required: false,
        enableCondition: false,
        createdAt: null,
        createdBy: null,
        updatedAt: null,
        updatedBy: null,

        conditions: [
          {
            conditionId: 1,
            operator: "IF",
            attributeName: "role",
            operationValue: "",
            expectedValue: "",
            createdAt: null,
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
          },
        ],
      };
      setRbacBlocks([...rbacBlocks, newBlock]);
    } else {
      console.log("abac added", abacBlocks);
      const newBlock = {
        ruleName: "",
        description: "",
        appId: "newApp",
        required: false,
        enableCondition: false,
        createdAt: null,
        createdBy: null,
        updatedAt: null,
        updatedBy: null,
        conditions: [
          {
            conditionId: 1,
            operator: "IF",
            attributeId: "",
            operationValue: "",
            expectedValue: "",
            otherAttributes: "",
            createdAt: null,
            createdBy: null,
            updatedAt: null,
            updatedBy: null,
          },
        ],
      };
      setAbacBlocks([...abacBlocks, newBlock]);
    }
  };

  const handleDeleteBlock = (blockId) => {
    // Remove the block with the given ID
    setRbacBlocks(rbacBlocks.filter((block) => block.blockId !== blockId));
  };
  const handleBlockChange = (blockId, updatedBlock) => {
    setRbacBlocks(
      rbacBlocks.map((block) =>
        block.blockId === blockId ? updatedBlock : block
      )
    );
  };

  const handleAbacBlockChange = (blockId, updatedBlock) => {
    setAbacBlocks(
      abacBlocks.map((block) =>
        block.blockId === blockId ? updatedBlock : block
      )
    );
  };
  const handleAbacConditionChange = (
    blockId,
    conditionId,
    updatedCondition
  ) => {
    setAbacBlocks(
      abacBlocks.map((block) =>
        block.blockId === blockId
          ? {
              ...block,
              conditions: block.conditions.map((condition) =>
                condition.conditionId === conditionId
                  ? { ...condition, ...updatedCondition }
                  : condition
              ),
            }
          : block
      )
    );
  };

  const RBAC_submitHandler = async (data) => {
    Api.post("blocks")
      .params(data)
      .send(function (response) {
        if (response !== undefined) {
          Notification.success("Success", response);
          setRbacBlocks([
            {
              // blockId: 1,
              ruleName: "",
              description: "",
              required: false,
              enableCondition: false,
              createdAt: null,
              createdBy: null,
              updatedAt: null,
              updatedBy: null,

              conditions: [
                {
                  conditionId: 1,
                  operator: "IF",
                  attributeName: "role",
                  operationValue: "",
                  expectedValue: "",
                  createdAt: null,
                  createdBy: null,
                  updatedAt: null,
                  updatedBy: null,
                },
              ],
            },
          ]);
        } else {
          Notification.error(
            "Error",
            "An error occurred while creating new rule."
          );
        }
      });
  };

  const ABAC_submitHandler = async (data) => {
    Api.post("blocks")
      .params(data)
      .send(function (response) {
        if (response !== undefined) {
          Notification.success("Success", response);
          setAbacBlocks([
            {
              ruleName: "",
              description: "",
              appId: "newApp",
              required: false,
              enableCondition: false,
              createdAt: null,
              createdBy: null,
              updatedAt: null,
              updatedBy: null,
              conditions: [
                {
                  conditionId: 1,
                  operator: "IF",
                  attributeId: "",
                  operationValue: "",
                  expectedValue: "",
                  otherAttributes: "",
                  createdAt: null,
                  createdBy: null,
                  updatedAt: null,
                  updatedBy: null,
                },
              ],
            },
          ]);
        } else {
          Notification.error(
            "Error",
            "An error occurred while creating new rule."
          );
        }
      });
  };

  return (
    <div>
      {/* <h2 className="text-sm font-semibold ">Validation Conditions</h2> */}
      <div className="flex justify-between mb-2">
        <select
          onChange={(e) => {
            setSelectedOption(e.target.value);
          }}
          className="border-skt-blue border rounded-lg    px-1 font-semibold text-sm ml-1 py-1"
          name=""
          id=""
        >
          <option value="ABAC">ABAC</option>
          <option value="RBAC">RBAC</option>
        </select>
        <h2 className="font-semibold text-sm  mt-2 bg-yellow-200 w-64 pl-2">
          {selectedOption == "RBAC"
            ? "RBAC (Roll Based Access Control)"
            : "ABAC (Attribute Based Access Control)"}
        </h2>
        <button
          onClick={handleAddBlock}
          className="text-sky-blue text-sm p-2 border-2 border-gray-400 rounded-md hover:bg-gray-400"
        >
          +add another
        </button>
      </div>
      <div className="">
        {selectedOption == "ABAC" && (
          <div className="">
            {tableColumnsSelector.map((tableCol) => (
              <TableAndColumnsSelect
                key={tableCol.key}
                selectorKey={tableCol.key}
                onDelete={() => handleDeleteTCSelect(tableCol.key)}
                onAddToSelectedCols={handleAddToSelectedCols}
              />
            ))}
            <div className="flex justify-between">
              <button
                onClick={addAnotherTableCol}
                className="bg-gray-400 hover:bg-gray-500  rounded-lg text-xs font-semibold px-2 w-20 my-2"
              >
                + Add
              </button>
              <button
                onClick={()=>attributeSubmitHandler(selectedColumnNames,setReload)}
                className="bg-gray-400  hover:bg-gray-500 text-xs font-semibold px-2 w-48 py-2 rounded-lg mr-[70px] my-2"
              >
                save attributes
              </button>
            </div>
          </div>
        )}

        {selectedOption == "RBAC"
          ? rbacBlocks.map((block) => (
              <RBAC
                key={block.blockId}
                block={block}
                // blockKey={block.key}
                onDeleteBlock={handleDeleteBlock}
                onBlockChange={handleBlockChange}
              />
            ))
          : abacBlocks.map((block) => (
              <ABAC
                key={block.blockId}
                block={block}
                onDeleteBlock={() => handleDeleteBlock(block.blockId)}
                onBlockChange={handleAbacBlockChange}
                onConditionChange={handleAbacConditionChange}
                reload={reload}
              />
            ))}

        <button
          onClick={() => {
            if (selectedOption == "RBAC") {
              console.log("RBAC +++>>>", rbacBlocks);
              RBAC_submitHandler(rbacBlocks[0]);
            } else {
              console.log("ABAC +++>>>", abacBlocks);
              RBAC_submitHandler(abacBlocks[0]);

            }
          }}
          className="px-2 border-2 ml-2 hover:text-white hover:bg-skt-blue border-skt-blue rounded-lg "
        >
          Save Data
        </button>
      </div>
    </div>
  );
};

export default Authorization;
