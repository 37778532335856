import React from "react";
import {AiOutlinePlus} from "react-icons/ai";
import {GoTrash} from "react-icons/go";

const DUMMY_COMPONENT_TABLE_DATA = [
  { componentID: 12, componentName: "Navbar" },
  { componentID: 13, componentName: "Carousel" },
  { componentID: 13, componentName: "Banner" },
  { componentID: 14, componentName: "2 x Grid" },
  { componentID: 15, componentName: "3 x Grid" },
  { componentID: 16, componentName: "Body" },
  { componentID: 17, componentName: "Body  - with Sidebar" },
  { componentID: 18, componentName: "Footer" },
  { componentID: 19, componentName: "Form" },
  { componentID: 20, componentName: "Cards carousel" },
  { componentID: 21, componentName: "Tab section" },
  { componentID: 22, componentName: "Accordion section" },
  { componentID: 23, componentName: "Signup/SignIn form" },
];
const SelectMultipleComponents = ({ components, setComponents }) => {
  // Add a new component selector
  const handleAddClick = () => {
    const newComponents = [
      ...components,
      { componentID: "", componentName: "" },
    ];
    setComponents(newComponents);
  };

  // Delete an existing component selector
  const handleDeleteClick = (index) => {
    const newComponents = components.filter((_, i) => i !== index);
    setComponents(newComponents);
  };

  // Handle change of any selected component
  const handleChange = (index, event) => {
    const newComponents = components.map((comp, i) => {
      if (i === index) {
        return {
          ...comp,
          componentID: event.target.value,
          componentName: event.target.options[event.target.selectedIndex].text,
        };
      }
      return comp;
    });
    setComponents(newComponents);
  };

  return (
    <div className="flex flex-col items-center justify-center  mt-3">
      <div className="flex justify-between w-full bg-gray-400">
        <h2 className=" font-semibold w-full text-start p-2">
          Components
        </h2>

        <button
          onClick={() => handleAddClick(1)}
          className="p-1 text-blue-500 hover:text-blue-700 flex bg-gray-100"
        >
          <AiOutlinePlus size="1.5em" /> Add
        </button>
      </div>
      {components.map((comp, index) => (
        <div
          key={index}
          className="flex bg-gray-300 items-center  text-center p-2  justify-between w-full mb-2"
        >
          <div className="mr-2 text-xs  font-semibold">{index + 1}</div>
          <select
            value={comp.componentID}
            onChange={(e) => handleChange(index, e)}
            className="block w-full ml-4 p-2 text-sm border rounded"
          >
            <option value="">Select Component</option>
            {DUMMY_COMPONENT_TABLE_DATA.map((component) => (
              <option key={component.componentID} value={component.componentID}>
                {component.componentName}
              </option>
            ))}
          </select>

          <button
            onClick={() => handleDeleteClick(index)}
            className="p-1 text-red-500 hover:text-red-700"
            disabled={components.length === 1}
          >
            <GoTrash size="1.5em" />
          </button>
        </div>
      ))}
    </div>
  );
};

export default SelectMultipleComponents;
