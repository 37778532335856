import React, {useState} from "react";

const DUMMY_SCREEN_TABLE_DATA = [
  { screenID: 12, screenName: "HomeScreen" },
  { screenID: 13, screenName: "AboutScreen" },
  { screenID: 14, screenName: "ContactScreen" },
  { screenID: 15, screenName: "ServicesScreen" },
  { screenID: 16, screenName: "TestimonialsScreen" },
];
const DUMMY_ROUTE_DATA = [
    {
      routeID: 1,
      routeName: "home",
      routeDescription: "when user visits /home , show HomeScreen ",
      screen: "HomeScreen",
      screenID: 12,
    },
    {
      routeID: 2,
      routeName: "about",
      routeDescription: "when user visits /about , show AboutScreen ",
      screen: "AboutScreen",
      screenID: 13,
    },
    {
      routeID: 3,
      routeName: "contact",
      routeDescription: "when user visits /contact , show ContactScreen ",
      screen: "ContactScreen",
      screenID: 14,
    },
    {
      routeID: 4,
      routeName: "services",
      routeDescription: "when user visits /services , show ServicesScreen ",
      screen: "ServicesScreen",
      screenID: 15,
    },
  ];
  

// const ROUTE_DATA_INPUTS = ()
const ManageRoutes = () => {
    const [showModal, setShowModal] = useState(false);
    const [routeData, setRouteData] = useState(DUMMY_ROUTE_DATA);
    const [newRoute, setNewRoute] = useState({
      routeName: '',
      routeDescription: '',
      screenID: ''
    });
  
    const handleAddRoute = () => {
      const newRouteData = {
        routeID: routeData.length + 1,
        routeName: newRoute.routeName,
        routeDescription: newRoute.routeDescription,
        screen: DUMMY_SCREEN_TABLE_DATA.find(screen => screen.screenID === parseInt(newRoute.screenID))?.screenName,
        screenID: parseInt(newRoute.screenID),
      };
      setRouteData([...routeData, newRouteData]);
      setShowModal(false);
    };
  
    return (
      <div>
        <div className="flex justify-between mb-4">
          <div>
            <h1 className="text-xl font-semibold">MANAGE ROUTES</h1>
            <p className="text-blue-500 font-semibold text-xs">
              Manage all your routes of application here, Select respective screens for every route.
            </p>
          </div>
          <button onClick={() => setShowModal(true)} className="rounded-md p-2 bg-sky-500 text-white hover:bg-sky-700">
            + Create New Route
          </button>
        </div>
  
        {showModal && (
          <div className="absolute top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-5 rounded-lg">
              <h2 className="text-lg font-bold">Add New Route</h2>
              <input
                type="text"
                placeholder="Route Name"
                value={newRoute.routeName}
                onChange={e => setNewRoute({...newRoute, routeName: e.target.value})}
                className="block w-full p-2 border rounded mb-2"
              />
              <input
                type="text"
                placeholder="Route Description"
                value={newRoute.routeDescription}
                onChange={e => setNewRoute({...newRoute, routeDescription: e.target.value})}
                className="block w-full p-2 border rounded mb-2"
              />
              <select
                value={newRoute.screenID}
                onChange={e => setNewRoute({...newRoute, screenID: e.target.value})}
                className="block w-full p-2 border rounded mb-2"
              >
                <option value="">Select Screen</option>
                {DUMMY_SCREEN_TABLE_DATA.map(screen => (
                  <option key={screen.screenID} value={screen.screenID}>{screen.screenName}</option>
                ))}
              </select>
              <button onClick={handleAddRoute} className="bg-skt-light-blue hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                Add Route
              </button>
              <button onClick={() => setShowModal(false)} className="ml-4 bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded">
                Cancel
              </button>
            </div>
          </div>
        )}
  
        <div className="mt-4">
          <div className="shadow-md">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Serial No.
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Route Name
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Route Description
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Selected Screen
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {routeData.map((route, index) => (
                  <tr key={route.routeID}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                      {index + 1}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {route.routeName}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-xs text-gray-500">
                      {route.routeDescription}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {route.screen}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                      <button className="text-indigo-600 hover:text-indigo-900 mr-4">Edit</button>
                      <button className="text-red-600 hover:text-red-900">Delete</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };
  
  export default ManageRoutes;
  