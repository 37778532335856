// src/components/forms/AutocompleteForm.js
import React from "react";
import AutocompletePreview from "../previews/AutocompletePreview";

export const getDefaultAutocompleteConfig = () => ({
  suggestions: ["Apple", "Banana", "Cherry"],
  inputValue: "",
});

const AutocompleteForm = ({ config, onConfigChange }) => {
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    onConfigChange({ ...config, [name]: value });
  };

  return (
    <div>
      <h3>Autocomplete Settings</h3>
      <label>
        Suggestions (comma-separated):
        <input
          type="text"
          name="suggestions"
          value={config.suggestions.join(", ")}
          onChange={(e) =>
            onConfigChange({
              ...config,
              suggestions: e.target.value.split(",").map((opt) => opt.trim()),
            })
          }
        />
      </label>
      <label>
        Input Value:
        <input
          type="text"
          name="inputValue"
          value={config.inputValue}
          onChange={handleInputChange}
        />
      </label>
    </div>
  );
};

export default AutocompleteForm;
export { AutocompletePreview };
