import React from 'react'

const Authentication = () => {
  return (
    <div>
      AUTHNTICATION
    </div>
  )
}

export default Authentication
