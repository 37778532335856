import React, {useState} from "react";
import {CiEdit, CiTrash} from "react-icons/ci";
import ActionPopup from "./ActionPopup";

const DataTable = ({ tableData, currentItems, getTableDetails, tableName }) => {
  const [showActionPopup, setShowActionPopup] = useState(false);
  const [rowData, setRowData] = useState({});
  const [actionType, setActionType] = useState('');

  // Function to handle action button click
  const onActionClickHandler = (rowData, type) => {
    setActionType(type);
    setRowData(rowData);
    setShowActionPopup(true);
  };

  return (
    <div>
      {showActionPopup && (
        <ActionPopup
          rowData={rowData}
          closeFunction={setShowActionPopup}
          action={actionType}
          reloadData={getTableDetails}
          tableName={tableName}
        />
      )}

      <table className="min-w-full leading-normal">
        <thead>
          <tr className="text-left text-gray-700 bg-gray-100">
            {currentItems.length > 0 ? (
              // Render table headers based on the keys of the first row of currentItems, excluding the "id" column
              Object.keys(currentItems[0]).filter(key => key !== "id").map((columnName, index) => (
                <th key={index} className="p-2 border-b-2 border-gray-200">
                  {columnName}
                </th>
              ))
            ) : (
              <th
                colSpan={Object.keys(currentItems[0] || {}).length || 1}
                className="p-2 border-b-2 border-gray-200"
              >
                Please select a table to display data...
              </th>
            )}
            {/* Render "Actions" column if there are items in the table */}
            {currentItems.length > 0 && currentItems[0][Object.keys(currentItems[0])[0]] ? 
              <th className="p-2 border-b-2 border-gray-200">Actions</th> : ''}
          </tr>
        </thead>
        <tbody>
          {currentItems.length > 0 ? (
            // Render table rows
            currentItems.map((rowData, rowIndex) => (
              <tr key={rowIndex}>
                {Object.entries(rowData).filter(([key]) => key !== "id").map(([key, value], cellIndex) => (
                  <td key={cellIndex} className="p-2 border-b border-gray-400 bg-white text-xs">
                    {value}
                  </td>
                ))}
                {/* Render action buttons if there are items in the table */}
                {currentItems.length > 0 && currentItems[0][Object.keys(currentItems[0])[0]] ? 
                  <td className="p-2 border-b border-gray-400">
                    <div className="flex">
                      <span className="cursor-pointer mr-2 text-blue-600" onClick={() => onActionClickHandler(rowData, 'edit')}>
                        <CiEdit />
                      </span>
                      <span className="cursor-pointer mr-2 text-red-600" onClick={() => onActionClickHandler(rowData, 'delete')}>
                        <CiTrash />
                      </span>
                    </div>
                  </td> : ''}
              </tr>
            ))
          ) : (
            // Render "No data available" row if there are no items in the table
            <tr>
              <td colSpan={Object.keys(currentItems[0] || {}).length + 1} className="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                <p className="text-gray-900 whitespace-no-wrap">No data available</p>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default DataTable;
