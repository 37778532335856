import React from 'react'

const DataDesign = () => {
  return (
    <div className='text-center'>
      {/* <h1 className='text-3xl'>Data Design</h1> */}
    </div>
  )
}

export default DataDesign
