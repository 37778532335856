import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "./Header1.css";
import { FaCartShopping } from "react-icons/fa6";
import { FaSearch } from "react-icons/fa";
import useHeaderStore from "../../store/useHeaderStore";
import axios from "axios";
import Notification from "../../services/Notification";

const HeaderTemplate1 = ({ config }) => {
  const { pathname } = useLocation();
  // render the links as clickable or not depending on the url path name
  const { formConfiguration } = useHeaderStore();

  if (!config) {
    return (
      <header className="header-template">
        <div className="header-top">
          <div className="logo-section">
            <span className="logo">LOGO</span>
            <span className="deliver-to">
              Deliver to <strong>User Name</strong>
              <br />
              Location/Zip Code
            </span>
          </div>
          <div className="search-section">
            <select className="category-dropdown">
              <option>Category 1</option>
              {/* Add more categories here */}
            </select>
            <input type="text" className="search-bar" placeholder="Search..." />
            <button className="search-button">Search</button>
          </div>
          <div className="account-section">
            <div className="language-selection">Language</div>
            <div className="account-dropdown">
              Hello, User
              <br />
              <strong>Account & Options</strong>
            </div>
            <div className="orders-section">
              Returns
              <br />
              <strong>& Orders</strong>
            </div>
            <div className="cart-section">Cart (0)</div>
          </div>
        </div>
        <div className="header-bottom">
          <div className="navigation-links">
            <div className="nav-link">Link 1</div>
            <div className="nav-link">Link 2</div>
            <div className="nav-link">Link 3</div>
            <div className="nav-link">Link 4</div>
            <div className="nav-link">Link 5</div>
          </div>
        </div>
      </header>
    );
  }
  return (
    <>
      <header
        className={`w-full`}
        style={{
          backgroundColor: config.backgroundColor,
          color: config.textColor || "#000000",
        }}
      >
        <div className="header-top">
          <div className="logo-section">
            <div className="flex items-center gap-2">
              <img src={config?.logoUrl} alt="abcd" className="w-12 h-12" />
              <span className="logo">{config?.logoText}</span>
            </div>
            <span className="deliver-to">
              Deliver to <strong>User Name</strong>
              <br />
              Location/Zip Code
            </span>
          </div>
          {config.searchBar ? (
            <div className="flex items-center border rounded-lg shadow-md bg-white p-2">
              <FaSearch className="text-gray-500 mr-2" />{" "}
              {/* Add the search icon */}
              <input
                type="text"
                placeholder="Search..."
                className="w-full p-2 text-gray-700 border-none outline-none rounded-md focus:ring-2 focus:ring-blue-500"
              />
            </div>
          ) : null}
          <div className="account-section">
            <div className="language-selection">Language</div>
            <div className="account-dropdown">
              Hello, User
              <br />
              <strong>Account & Options</strong>
            </div>
            <div className="orders-section">
              Returns
              <br />
              <strong>& Orders</strong>
            </div>
            {config.cart ? <FaCartShopping size={24} /> : null}
          </div>
        </div>
        <div className="header-bottom">
          <div className="navigation-links">
            {config.navigationLinks.map((link) => {
              return (
                <div className="p-4 bg-white shadow-md rounded-lg mb-4">
                  <Link
                    to={`/${link.name.toLowerCase().replace(/\s+/g, "-")}`}
                    className="text-blue-600 hover:text-blue-800 font-semibold text-lg"
                  >
                    {link.name}
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </header>
    </>
  );
};

export default HeaderTemplate1;
