import React from 'react'
import './buttonUserForm.css'
const Button = ({type,onClick,label,disabled}) => {
  return (
    <div className='btn-user'>

        <button className='submit-buttonU'type={type} onClick={onClick}  disabled={disabled}>
            {label}
        </button>
    </div>
  )
}

export default Button