import React from "react";
import { Link } from "react-router-dom";
import { useLoaderData } from "react-router-dom";
import FormPreview from "./FormPreview";
import FormButtons from "./FormButtons";
const Forms = () => {
  const { formConfig: forms } = useLoaderData();
  console.log(forms[0]);
  if (!forms) {
    return <p>no forms</p>;
  }
  return (
    <div className="flex flex-col ">
      <h3 className="mt-6 font-bold">
        <Link>Add form</Link>
        Showing {forms.length} configured forms
      </h3>
      <div className="flex flex-wrap  justify-evenly p-6">
        {forms.map((form, index) => {
          console.log(form.elements.value);
          return (
            <div className="lg:w-2/5 md:h-2/3  w-full mt-4 ">
              <FormPreview config={form} />
              <FormButtons id={form.id} />
              <p>hey</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Forms;
