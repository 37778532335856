import React, { useEffect } from "react";
import RenderComponent from "./components/RenderComponent";
import useDataStore from "../../store/useDataStore";

// function RenderPage() {
const RenderPage = () => {
  const {
    componentsData,
    componentsMap,
    initializeComponents,
    pagesData,
    pagesMap,
    initializePages,
  } = useDataStore();

  useEffect(() => {
    console.log("heyy useeffect");
    initializeComponents();
    initializePages();
  }, []);

  console.log(componentsData, componentsMap, "hihello", pagesData, pagesMap);

  const renderPageC = (layout, regions) => {
    console.log("layout", layout, "regions", regions, regions["A"].length);
    return (
      <div className={`flex gap-4 mt-2`}>
        <div>
          {regions["A"].map((element, index) => {
            console.log("element", element);
            let indexValue = pagesMap[element];
            console.log("iu1", indexValue, pagesMap);
            if (indexValue) {
              const pageObj = pagesData[indexValue].json;
              return renderPageC(pageObj.layout, pageObj.regions);
            }
            if (!indexValue) {
              indexValue = componentsMap[element];
              console.log("iu", indexValue);
              const { compTempID: templateId, json: data } =
                componentsData[indexValue];
              console.log(componentsData[indexValue], templateId, data);
              return (
                <RenderComponent
                  key={index}
                  templateId={templateId}
                  data={data}
                />
              );
            }
          })}
        </div>
        <div>
          {!(layout == "single-column" || layout == "single") &&
            regions["B"].map((element, index) => {
              let indexValue = pagesMap[element];
              if (indexValue) {
                const pageObj = pagesData[indexValue].json;
                return renderPageC(pageObj.layout, pageObj.regions);
              }
              if (!indexValue) {
                indexValue = componentsMap[element];
                console.log("iu", indexValue);
                const { compTempID: templateId, json: data } =
                  componentsData[indexValue];
                return (
                  <RenderComponent
                    key={index}
                    templateId={templateId}
                    data={data}
                  />
                );
              }
            })}
        </div>
        <div>
          {layout == "three-column" &&
            regions["C"].map((element, index) => {
              let indexValue = pagesMap[element];
              if (indexValue) {
                const pageObj = pagesData[indexValue].json;
                return renderPageC(pageObj.layout, pageObj.regions);
              }
              if (!indexValue) {
                indexValue = componentsMap[element];
                console.log("iu", indexValue);
                const { compTempID: templateId, json: data } =
                  componentsData[indexValue];
                return (
                  <RenderComponent
                    key={index}
                    templateId={templateId}
                    data={data}
                  />
                );
              }
            })}
        </div>
      </div>
    );
  };

  const renderData = {
    layout: "two-column",
    regions: {
      A: [
        "service card1",
        "service card2",
        "service card3",
        "service card4",
        "about us title and sub",
        "about us para1",
        "training-card1",
        "training_cards_list",
        "register card comp card",
      ],
      B: ["training_cards_list", "service card4"],
    },
  };

  return (
    <div>
      {componentsData != null &&
        renderPageC(renderData.layout, renderData.regions)}
      {componentsData == null && "hi"}
    </div>
  );
};

export default RenderPage;