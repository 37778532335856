import {create} from "zustand";
import Notification from "../services/Notification";
import customInstance from "../axios/axiosInstance";
import axios from "axios";

const useHeaderStore = create((set) => ({
  selectedTemplate: null,
  setSelectedTemplate: (template) => set({ selectedTemplate: template }),
  resetSelectedTemplate: () => set({ selectedTemplate: null }),
  formConfiguration: {
    logoUrl: "",
    logoText: "",
    searchTable: "",
    navigationLinks: [],
    searchBar: false,
    cart: false,
    userAccount: false,
    primaryColor: "",
    secondaryColor: "",
    fontFamily: "",
    fontSize: "",
    headerLayout: "",
    darkMode: false,
    lightMode: false,
    customCss: "",
    backgroundColor: "",
    textColor: "",
    userAccount: false,
    socialMediaLinks: [],
    iconColor: "",
    selectedCategories: [],
    showCategories: false,
    searchFields: [],
    loginEnabled: false,
    signupEnabled: false,
    isActive: false,
  },
  allCategories: [], // Move this outside of formConfiguration for clarity
  setFormConfiguration: (newConfig) =>
    set((state) => ({
      formConfiguration: {
        ...state.formConfiguration,
        ...newConfig,
      },
    })),

  // Add the setAllCategories function
  setAllCategories: (categories) => set({ allCategories: categories }),

  toggleShowCategories: () =>
    set((state) => ({
      formConfiguration: {
        ...state.formConfiguration,
        showCategories: !state.formConfiguration.showCategories,
      },
    })),

  updateSelectedCategories: (categories) =>
    set((state) => ({
      formConfiguration: {
        ...state.formConfiguration,
        selectedCategories: categories,
      },
    })),

  toggleCategory: (category) =>
    set((state) => {
      const isSelected = state.formConfiguration.selectedCategories.some(
        (selectedCategory) => selectedCategory.id === category.id
      );
      // If category is selected, remove it; otherwise, add it
      const updatedCategories = isSelected
        ? state.formConfiguration.selectedCategories.filter(
            (selectedCategory) => selectedCategory.id !== category.id
          )
        : [...state.formConfiguration.selectedCategories, category];

      return {
        formConfiguration: {
          ...state.formConfiguration,
          selectedCategories: updatedCategories,
        },
      };
    }),
  // this is a function to call header configuration details from backend or mock api from json db
  getHeaderConfiguration: async () => {
    try {
      const id = localStorage.getItem("headerId");
      const appId = localStorage.getItem("appId");
      const appName = localStorage.getItem("appName");
      const res = await axios.get(
        `http://49.207.7.220:8080/api/fetch-json?schemaName=${appName}&tableName=component&id=${id}&columnName=app_id&columnValue=${appId}`
      );
      const dataStr = res.data;
      const data = JSON.parse(dataStr[0]["json"]);
      return data;
    } catch (error) {
      Notification.error("theres an error fetching header data");
      console.log(error);
    }
  },
}));

export default useHeaderStore;
