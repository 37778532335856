import React, { useRef } from "react";
import { useOutsideClick } from "../../components/header/OutsideClick";

const PopupModal = ({
  isVisible,
  title,
  children,
  onClose,
  onSave,
  saveButtonText = "Save",
  cancelButtonText = "Cancel",
  errorMessage = "",
}) => {
  const PopupRef = useOutsideClick(onClose);
  if (!isVisible) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
      <div
        ref={PopupRef}
        className="bg-white rounded-lg shadow-lg p-6 md:p-8 lg:p-10 w-11/12 sm:max-w-md md:max-w-lg lg:max-w-xl"
      >
        <h2 className="text-xl font-semibold mb-4">{title}</h2>

        {children}

        {errorMessage && <p className="text-red-500 mb-4">{errorMessage}</p>}

        <div className="flex flex-col sm:flex-row justify-between">
          <button
            onClick={onClose}
            className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
          >
            {cancelButtonText}
          </button>
          <button
            onClick={onSave}
            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 mb-2 sm:mb-0"
          >
            {saveButtonText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default PopupModal;
