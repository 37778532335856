export const paymentMethods = [
    {
        name: 'Stripe',
        imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSTtt-aAH_ueJ-OLtfJYAnm77Oy74np2CgIOQ&s',
        fields: [
            { label: 'Publishable Key', name: 'publishableKey', type: 'text' },
            { label: 'Secret Key', name: 'secretKey', type: 'text' }
        ]
    },
    {
        name: 'Razorpay',
        imageUrl: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTnA99TABOEvVDdjInRpcHjvKZIhgCnv0z9zg&s',
        fields: [
            { label: 'Key ID', name: 'keyId', type: 'text' },
            { label: 'Key Secret', name: 'keySecret', type: 'text' }
        ]
    }
];
