// src/components/previews/InputPreview.js
import React from "react";

const TextInputPreview = ({ config }) => {
  const { placeholder, value, width } = config;

  return (
    <input
      type="text"
      placeholder={placeholder || "Enter text"}
      value={value}
      readOnly
      style={{
        width: width || "200px",
        padding: "8px",
        border: "1px solid #ccc",
        borderRadius: "4px",
      }}
    />
  );
};

export default TextInputPreview;
