import React, { useEffect, useState } from "react";
import { Button, Checkbox, Col, Modal, Row, Spin } from "antd";
import { FiArrowUpRight } from "react-icons/fi";
import "./formBuilder.css";
import Api from "../../../services/Api.js";
import EmailModal from "../previewPage/emailModal.jsx";
import Dropdown from "../../../comon/dropdown/dropdown.jsx";
import PreviewPage from "../previewPage/previewPage.jsx";
import UploadData from "../../../comon/uploadData/uploadData.jsx";
import MultiSelectDropdown from "../../../comon/multiSelectDropdown/multiSelectDropdown.jsx";
import { DeleteTwoTone } from "@ant-design/icons";
import axios from "axios";
import AddNewData from "./addNewData.jsx";
import {
  deleteElement,
  fetchAllScreenData,
  fetchScreenNames,
  fetchTableList,
  fetchVisibleFor,
  handleElement,
  handlePreviewClick,
  handlleformData,
} from "./formBuilderHelper.js";
import useFormStore from "../../../store/useFormStore.jsx";
import { useNavigate } from "react-router-dom";

import { MdDelete } from "react-icons/md";

const FormBuilder = () => {
  const { setAllFormInputs, formConfiguration, incrementStep } = useFormStore();
  const [selectedOption, setSelectedOption] = useState("");
  // change this part so it can handle both states of an empty array or a predefined array.
  const [addElement, setAddElement] = useState([
    ...formConfiguration?.elements,
  ]);
  const [isModalOpen, setIsModalOpen] = useState();
  const [selectedTableName, setSelectedTableName] = useState("");
  const [inputs, setInputs] = useState([]);
  const [previewContent, setPreviewContent] = useState("");
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState();
  const [screenNames, setScreenNames] = useState([]);
  const [existingTableList, setExistingTableList] = useState([]);
  const [selectedScreenData, setSelectedScreenData] = useState([]);
  const [listForUsers, setListForUsers] = useState([]);
  const [columnList, setColumnList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  // global store comes here

  // add navigation to preview

  const buttonDropdownOption = [
    "Save",
    "Edit",
    "Next",
    "Proceed",
    "Previous",
    "Back",
    "Open a pop up",
    "Call a function",
    "Validate",
    "Apply",
    "Apply Conditions",
    "Send an email",
    "Create PDF",
    "Print",
  ];
  const linkDroupDownOption = [
    "Open URL in New Window",
    "Open Url in NewTab",
  ]

  useEffect(() => {
    // Fetch screen names when component mounts
    // fetchScreenNames(setScreenNames);
    // Fetch Table list
    // fetchTableList(setExistingTableList);
    // Fetch Visible For
    // fetchVisibleFor(setListForUsers);
    // fetchSubCateList();
    console.log("addElement",addElement);
  }, [addElement]);

  // const handlePreviewPageClick = () => {
  //   navigate("/preview", addElement);
  //   // setIsPreviewModalOpen(true);
  //   setPreviewContent(addElement);
  // };

  const handleSelectedScreen = (option) => {
    console.log("SELECTED OPTION", option);
    setSelectedOption(option);
    fetchAllScreenData(
      option,
      setSelectedScreenData,
      setAddElement,
      setInputs,
      setIsLoading
    );
  };

  const handleFromMyTable = async (option, index, name) => {
    setSelectedTableName(option);
    const updatedElement = [...addElement];

    updatedElement[index][name] = option;
    setAddElement(updatedElement);

    Api.get(
      `table-details?schemaName=applicationname&tableName=${option}`
    ).send((response) => {
      if (response !== undefined) {
        const columnList = response.body.map((data) => {
          return data.column_name;
        });
        setColumnList(columnList);
      } else {
        console.log("error");
      }
    });
  };

  const handleElementData = (event, index, name) => {
    const updatedElement = [...addElement];
    switch (name) {
      case "NameOfTheField":
        updatedElement[index][name] = event.target.value;
        break;
      case "IsRequired":
        updatedElement[index][name] = event.target.checked;

        updatedElement[index]["disabled"] &&
          (updatedElement[index]["disabled"] = !event.target.checked);
        break;
      case "disabled":
        updatedElement[index][name] = event.target.checked;
        updatedElement[index]["IsRequired"] &&
          (updatedElement[index]["IsRequired"] = !event.target.checked);
        break;
      case "FormElement":
      case "Source":
        setSelectedTableName("");
        if (name === "Source" && event === "AddNewData") {
          setColumnList([]);
          setIsModalOpen(true);
        } else {
          setSelectedTableName();
        }
        updatedElement[index][name] = event;
        break;
      case "VisibleFor":
        const visibleForRoles = event.map((role) => ({ Roles: role }));
        updatedElement[index][name] = visibleForRoles;
        break;
      // case "DisplayColumns":
      // const displayListData = event.map((role) => ({ pyNote: role }));
      // updatedElement[index][name] = event;
      // break;
      case "Action":
        updatedElement[index][name] = event;
        break;
      case "NextStep":
        updatedElement[index][name] = event;
        break;
      case "AddedNewRecords":
        setIsModalOpen(false);
        const inputData = inputs.map((role) => ({ pyNote: role }));
        updatedElement[index].AddedNewRecords = inputData;
        break;
      case "url":
        updatedElement[index][name] = event.target.value;
        break;
      case "popUpToOpen":
        updatedElement[index][name] = event;
        break;
      default:
        updatedElement[index][name] = +event.target.value;
        break;
    }
    setAddElement(updatedElement);
  };

  const fetchSubCateList = async () => {
    let url = `http://localhost:8080/api/table-data/searchColumnRequest?schemaName=public&tableName=questions`;
    let params = {
      columns: ["id", "question_id", "question_name"],
    };

    // try {
    //   const response = await fetch(
    //     url,
    //     {
    //       method: "GET",
    //       headers: {
    //         "Content-Type": "application/json",
    //         // Authorization: getToken() || "",
    //       },
    //       params: JSON.stringify(params),
    //     }
    //   );

    //   if (response.ok) {
    //     const data = await response.json();
    //     console.log(data);
    //     // setLoading(false)
    //     // setSubCaeList(data.data)

    //     if (data.status === "error" || data.status === false) {
    //       // localStorage.removeItem("token");
    //     }
    //   } else {
    //     // localStorage.removeItem("token");
    //   }
    // } catch (error) {
    //   // localStorage.removeItem("token");
    //   console.error("Error:", error);
    // }
  };

  const handleSelectedSourceData = async (event, index, name) => {
    let url = `http://localhost:8080/api/table-data/searchColumnRequest?schemaName=applicationname&tableName=questions`;
    let params = {
      columns: ["id", "question_id", "question_name"],
    };
    console.log(event);

    // fetch("http://localhost:8080/api/table-data/searchColumnRequest?schemaName=applicationname&tableName=questions", {

    //     method: 'GET',
    //     headers: {
    //       'Content-Type': 'application/json'
    //     },
    //     body: JSON.stringify({
    //       columns: ["id", "question_id", "question_name"]
    //     })

    // })
    //   .then(response => response.json())
    //   .then(data => console.log(data))
    //   .catch(error => console.error('Error:', error));

    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
        },
        params: params,
      })
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    // console.log(selectedTableName, event,{
    //   "columns": event
    // } )
    // Api.get(`table-data/searchColumnRequest?schemaName=applicationname&tableName=${selectedTableName}`)
    // .params({
    //         columns: ["id", "question_id", "question_name"]
    //     })
    // .send(function (response) {
    //   if (response !== undefined) {
    //     console.log(response);
    //   }
    // });
  };

  return (
    <div className="w-[90%] h-auto flex flex-col p-2">
      <section className="w-2/3 mx-auto p-6  rounded-lg flex flex-col items-start">
        <h2 className="text-2xl font-semibold text-white mb-6">
          Let's Build Your Form
        </h2>
        {/* add elements button  */}
        <div className="w-full flex justify-end">
          <button
            onClick={() => handleElement(addElement, setAddElement)}
            className="px-6 py-3 bg-blue-600 text-white font-medium rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors flex items-center space-x-2"
          >
            <i className="fas fa-plus"></i>
            <span>Add Element</span>
          </button>
        </div>
      </section>
      <div className="formBuilderContainer w-2/3 p-2  mx-auto mt-4">
        {addElement.length > 0 ? (
          <div className="flex flex-col">
            {addElement?.map((element, index) => {
              return (
                <div
                  key={index}
                  className="p-2 flex flex-wrap gap-4 items-center justify-around  mb-4 shadow-md rounded-md shadow-slate-200  bg-slate-50"
                >
                  <Col span={6}>
                    <Row>
                      <label>Form Element:</label>
                    </Row>
                    <Row>
                      <Dropdown
                        options={[
                          "Text",
                          "Link",
                          "Image Icon",
                          "Dropdown",
                          "Radio",
                          "Checkbox",
                          "Button",
                        ]}
                        value={element?.FormElement}
                        // disabled={element?.FormElement}
                        onSelect={(option) =>
                          handleElementData(option, index, "FormElement")
                        }
                      />
                    </Row>
                  </Col>
                  {
                    <Col span={6}>
                      <Row>
                        <label>Name</label>
                      </Row>
                      <Row>
                        <input
                          type="text"
                          name="NameOfTheField"
                          value={element?.NameOfTheField}
                          className="inputHeight"
                          onChange={(e) =>
                            handleElementData(e, index, "NameOfTheField")
                          }
                        />
                      </Row>
                    </Col>
                  }
                  {(element?.FormElement == "Button" ||
                    element?.FormElement == "Link") && (
                    <Col span={6}>
                      <Row>
                        <label>Action</label>
                      </Row>
                      <Row>
                        <Dropdown
                          options={ element?.FormElement == "Button" ? buttonDropdownOption : linkDroupDownOption}
                          onSelect={(option) =>
                            handleElementData(option, index, "Action")
                          }
                          value={element?.Action}
                        />
                      </Row>
                    </Col>
                  )}

                  <Col span={6}>
                    <Row>
                      <label>Required</label>
                    </Row>
                    <Row>
                      <Checkbox
                        checked={element?.IsRequired}
                        onChange={(e) =>
                          handleElementData(e, index, "IsRequired")
                        }
                      />
                    </Row>
                  </Col>
                  <Col span={6}>
                    <Row>
                      <label>Disable</label>
                    </Row>
                    <Row>
                      <Checkbox
                        checked={element?.disabled}
                        onChange={(e) =>
                          handleElementData(e, index, "disabled")
                        }
                      />
                    </Row>
                  </Col>
                  <Col span={6}>
                    <Row>
                      <label>Visible For:</label>
                    </Row>
                    <Row>
                      <MultiSelectDropdown
                        defaultValue={element?.VisibleFor.map(
                          (item) => item.Roles
                        )}
                        onChange={(value) =>
                          handleElementData(value, index, "VisibleFor")
                        }
                        optionLabelProp="Roles"
                        options={listForUsers.map((item) => ({
                          label: item,
                          value: item,
                        }))}
                      />
                    </Row>
                  </Col>

                  {element?.FormElement == "Text" && (
                    <Col span={6}>
                      <Row>
                        <label>NoOfLetters</label>
                      </Row>
                      <Row>
                        <input
                          type="number"
                          name="max_length"
                          value={element.max_length}
                          className="inputHeight"
                          onChange={(e) =>
                            handleElementData(e, index, "max_length")
                          }
                        />
                      </Row>
                    </Col>
                  )}

                  {element?.FormElement == "Image Icon" && (
                    <Col span={6}>
                      <Row></Row>
                      <Row>
                        <UploadData calledFrom="Image" label="Upload Icon" />
                      </Row>
                    </Col>
                  )}

                  {(element?.FormElement == "Radio" ||
                    element?.FormElement == "Dropdown" ||
                    element?.FormElement == "Checkbox") && (
                    <Col span={6}>
                      <Row>
                        <label>Source</label>
                      </Row>
                      <Row>
                        <Dropdown
                          options={["ExistingData", "AddNewData"]}
                          onSelect={(option) =>
                            handleElementData(option, index, "Source")
                          }
                          value={element?.Source}
                        />
                      </Row>
                    </Col>
                  )}

                  {element?.Source === "ExistingData" &&
                    (element?.FormElement == "Radio" ||
                      element.FormElement == "Dropdown" ||
                      element?.FormElement == "Checkbox") && (
                      <Col span={6}>
                        <Row>
                          <label>Select table</label>
                        </Row>
                        <Row>
                          <Dropdown
                            options={existingTableList}
                            onSelect={(option) =>
                              handleFromMyTable(option, index, "Table")
                            }
                            value={element?.Table}
                            defaultValue={element?.Table}
                          />
                        </Row>
                      </Col>
                    )}

                  {(element.Action == "Next" ||
                    element.Action == "Proceed" ||
                    element.Action == "Previous" ||
                    element.Action == "Back") && (
                    <Col span={6}>
                      <Row>
                        <label>Next Page After Click:</label>
                      </Row>
                      <Row>
                        <Dropdown
                          options={screenNames}
                          onSelect={(option) =>
                            handleElementData(option, index, "NextStep")
                          }
                          value={element?.NextStep}
                        />
                      </Row>
                    </Col>
                  )}

                  {(element.Action == "Open URL in New Window" ||
                    element.Action == "Open Url in NewTab") && (
                    <Col span={6}>
                      <Row>
                        <label>URL</label>
                      </Row>
                      <Row>
                        <input
                          type="text"
                          name="url"
                          className="inputHeight"
                          value={element?.url}
                          onChange={(e) => handleElementData(e, index, "url")}
                          placeholder="Enter URL"
                        />
                      </Row>
                    </Col>
                  )}
                  {element.Action == "Send an email" && (
                    <EmailModal
                      addElement={addElement}
                      setAddElement={setAddElement}
                      index={index}
                    />
                  )}

                  {element?.Action === "Open a pop up" && (
                    <Col span={6}>
                      <Row>
                        <label>Select pop page</label>
                      </Row>
                      <Dropdown
                        options={screenNames}
                        onSelect={(option) =>
                          handleElementData(option, index, "popUpToOpen")
                        }
                        disabled={element?.disabled}
                        value={element?.popUpToOpen}
                      />
                    </Col>
                  )}

                  {element?.Source === "ExistingData" &&
                    selectedTableName &&
                    (element?.FormElement == "Radio" ||
                      element.FormElement == "Dropdown" ||
                      element?.FormElement == "Checkbox") && (
                      <Col span={6}>
                        <Row>
                          <label>Select Source From below</label>
                        </Row>
                        <Row>
                          <MultiSelectDropdown
                            // defaultValue={element?.DisplayColumns}
                            onChange={(value) =>
                              handleSelectedSourceData(
                                value,
                                index,
                                "DisplayColumns"
                              )
                            }
                            optionLabelProp="column_name"
                            options={columnList.map((item) => ({
                              label: item,
                              value: item,
                            }))}
                          />
                        </Row>
                      </Col>
                    )}

                  {element?.Source === "AddNewData" && (
                    <AddNewData
                      isModalOpen={isModalOpen}
                      setIsModalOpen={setIsModalOpen}
                      handleElementData={handleElementData}
                      setInputs={setInputs}
                      inputs={inputs}
                      removeInput={(indexToRemove) =>
                        setInputs(
                          inputs.filter((_, index) => index !== indexToRemove)
                        )
                      }
                      index={index}
                    />
                  )}
                  {/* changed here */}

                  {element?.Source === "AddNewData" && (
                    <Col span={6}>
                      <Row>&nbsp;</Row>
                      <Row>
                        <Button onClick={() => setIsModalOpen(true)}>
                          Add more data
                        </Button>
                      </Row>
                    </Col>
                  )}

                  {/* <Col span={6}>
                    <Row>&nbsp;</Row>
                    <Row>
                      <DeleteTwoTone
                        className="deleteTwoTone"
                        onClick={() =>
                          deleteElement(index, addElement, setAddElement)
                        }
                      />
                    </Row>
                  </Col> */}
                  <Col span={6}>
                    <Row>&nbsp;</Row>
                    <Row>
                      <button
                        onClick={() =>
                          deleteElement(index, addElement, setAddElement)
                        }
                        className="px-6 py-3 mt-5 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-blue-100 transition-all duration-300 ease-in-out transform hover:scale-105 flex items-center space-x-2"
                      >
                        <span>Delete</span>
                        <MdDelete className="text-lg" />
                      </button>
                    </Row>
                  </Col>
                </div>
              );
            })}
          </div>
        ) : selectedOption && addElement.length <= 0 ? (
          <Spin />
        ) : (
          <h4 className="text-white">
            click on Add element to start adding elements to your form!{" "}
          </h4>
        )}
      </div>

      {/* {formConfiguration.elements && (
        <Modal
          open={isPreviewModalOpen}
          footer={null}
          onCancel={() => setIsPreviewModalOpen(false)}
        >
          <PreviewPage addElement={addElement} />
        </Modal>
      )} */}

      <div className="flex items-center justify-end mx-auto md:w-2/3 w-full">
        {addElement.length > 0 && (
          <button
            type="button"
            onClick={() => {
              handlePreviewClick(setAllFormInputs(addElement));
              incrementStep();
            }}
            className="px-6 py-3 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 focus:ring-offset-blue-100 transition-all duration-300 ease-in-out transform hover:scale-105 flex items-center space-x-2"
          >
            <span>Preview</span>
            <FiArrowUpRight height={16} width={16} />
          </button>
        )}
      </div>
    </div>
  );
};

export default FormBuilder;

// let columns = [...event]; // Ensure these are valid column names
// let params = {
//   columns: columns,
// };
// let url = `http://localhost:8080/api/table-data/searchColumnRequest?schemaName=applicationname&tableName=questions`;

//   axios
//     .get(url, {
//       columns: ["id", "question_id","question_name"]
//  }
//   )
//     .then((response) => {
//       console.log(response.data);
//     })
//     .catch((error) => {
//       console.error("Error:", error);
//     });

// let url = `http://localhost:8080/api/table-data/searchColumnRequest`;

// axios
//   .get(url, {
//       columns: ["id", "question_id", "question_name"]
//   })
//   .then((response) => {
//     console.log(response.data);
//   })
//   .catch((error) => {
//     console.error("Error:", error);
//   });