// src/components/forms/DropdownForm.js
import React from "react";
import DropdownPreview from "../previews/DropdownPreview";

export const getDefaultDropdownConfig = () => ({
  options: ["Option 1", "Option 2", "Option 3"],
  selectedOption: "Option 1",
});

const DropdownForm = ({ config, onConfigChange }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    onConfigChange({ ...config, [name]: value });
  };

  return (
    <div>
      <h3>Dropdown Settings</h3>
      <label>
        Options (comma-separated):
        <input
          type="text"
          name="options"
          value={config.options.join(", ")}
          onChange={(e) =>
            onConfigChange({
              ...config,
              options: e.target.value.split(",").map((opt) => opt.trim()),
            })
          }
        />
      </label>
      <label>
        Selected Option:
        <select
          name="selectedOption"
          value={config.selectedOption}
          onChange={handleChange}
        >
          {config.options.map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
      </label>
    </div>
  );
};

export default DropdownForm;
export { DropdownPreview };
