import React from 'react'

const ManageVideos = () => {
  return (
    <div className='text-6xl font-bold'>
      VIDEO
    </div>
  )
}

export default ManageVideos
