// Forms.js

import React, {useState} from "react";
import {ChromePicker} from "react-color";
import {IoClose} from "react-icons/io5";

const HandleColorSelect = ({ name, color, setColor, onClose }) => {
  const handleColorChange = (color) => {
    setColor(color.hex);
  };
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="flex flex-col items-center relative text-gray-400 bg-white shadow-xl rounded-xl p-8 space-y-4">
        {name}
        <div
          className="absolute cursor-pointer  text-xl top-0 right-0"
          onClick={() => onClose(null)}
        >
          <IoClose />
        </div>
        <ChromePicker color={color} onChange={handleColorChange} />
      </div>
    </div>
  );
};

const Styles = () => {
  const [primaryColor, setPrimaryColor] = useState("#1302ff");
  const [secondaryColor, setSecondaryColor] = useState("#4206A6");
  const [textColor, setTextColor] = useState("#BBB3B3");
  const [colorPopup, setColorPopup] = useState(null);

  const popupOptions = {
    primary: {
      color: primaryColor,
      setColor: setPrimaryColor,
      name: "Primary Color",
    },
    secondary: {
      color: secondaryColor,
      setColor: setSecondaryColor,
      name: "Secondary Color",
    },
    text: { color: textColor, setColor: setTextColor, name: "Text Color" },
  };

  return (
    <div className="p-2 bg-gray-300 rounded-md">
      <h2 className="text-2xl font-bold ">Color theme</h2>
      {colorPopup && (
        <HandleColorSelect
          onClose={setColorPopup}
          color={popupOptions[colorPopup].color}
          setColor={popupOptions[colorPopup].setColor}
          name={popupOptions[colorPopup].name}
        />
      )}
      <div className="flex  ">
        <div className="p-2 w-36 font-bold mx-2 bg-white rounded-md ">
          Primary color
          <div
            className="p-4 border border-gray-500  rounded-md w-full py-2  h-10 cursor-pointer "
            onClick={() => setColorPopup("primary")}
            style={{ backgroundColor: primaryColor }}
          ></div>
        </div>
        <div className="p-2 w-36 font-bold mx-2 bg-white rounded-md ">
          Secondary color
          <div
            className="p-4 border border-gray-500  rounded-md w-full py-2  h-10 cursor-pointer "
            onClick={() => setColorPopup("secondary")}
            style={{ backgroundColor: secondaryColor }}
          ></div>
        </div>
        <div className="p-2 w-36 font-bold mx-2 bg-white rounded-md ">
          Text color
          <div
            className="p-4 border border-gray-500  rounded-md w-full py-2  h-10 cursor-pointer "
            onClick={() => setColorPopup("text")}
            style={{ backgroundColor: textColor }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default Styles;
