/* This code snippet is defining an array named `layoutData` which contains objects representing
different layouts. Each layout object has properties such as `layoutId`, `layoutName`,
`layoutImage`, and `layoutComponents`. The `layoutComponents` property is an array of objects
representing different components within that layout, each with properties like `type`, `name`, and
`id`. */

export const layoutData = [
  {
    layoutId: 1,
    layoutName: "standard homepage",
    layoutImage: "",
    layoutComponents: [
      {
        type: "navbar",
        name: "User_NavBar",
        id: "navbar",
      },

      {
        type: "carousel",
        name: "UserCarousel",
        id: "carousel",
      },
      {
        type: "form",
        name: "UserAdvanceForm",
        id: "form",
      },
      {
        type: "banner",
        name: "UserBanner",
        id: "banner",
      },
    ],
  },
  {
    layoutId: 2,
    layoutName: "standard Dashboard",
    layoutImage: "",
    layoutComponents: [
      {
        "type": "carousel",
        "name":"UserCarousel",
        "id": "carousel"
      },
      {
        "type": "form",
        "name":"UserAdvanceForm",
        "id": "form"
      },
      {
        "type": "banner",
        "name":"UserBanner",
        "id": "banner"
      },
    ],
  },
  {
    layoutId: 3,
    layoutName: "standard Box section page",
    layoutImage: "",
    layoutComponents: [
      {
        "type": "banner",
        "name":"UserBanner",
        "id": "banner"
      },
      {
        "type": "contactForm",
        "name":"UserContactForm",
        "id": "contactForm"
      },
      {
        "type": "footer",
        "name":"UserFooter",
        "id": "footer"
      }
    ],
  },
  {
    layoutId: 4,
    layoutName: "standard form page",
    layoutImage: "",
    layoutComponents: [
      {
        type: "navbar",
        name: "User_NavBar",
        id: "navbar",
      },

      {
        type: "carousel",
        name: "UserCarousel",
        id: "carousel",
      },
      {
        type: "form",
        name: "UserAdvanceForm",
        id: "form",
      },
      {
        type: "banner",
        name: "UserBanner",
        id: "banner",
      },
    ],
  },
];
