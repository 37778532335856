import React, {useRef, useState} from "react";
import SuccessPopup from "../../Popups/SuccessPopup";
import {addUnderscores} from "../../../utils/utils/helper";
import AddCustomDataFieldModal from "./AddCustomDataFieldModal";
import {useSelector} from "react-redux";
import {CREATE_TABLE_API} from "../../../utils/utils/constants";

const dummyFeildList = [
  { name: "EmailAddress", type: "Text" },
  { name: "EmployeeID", type: "Number" },
  { name: "FirstName", type: "Text" },
  { name: "HireDate", type: "Date" },
  { name: "LastName", type: "Text" },
  { name: "PhoneNumber", type: "Text" },
  { name: "Position", type: "Text" },
  { name: "Salary", type: "Decimal" },
];

const CreateData = () => {
  const [selectedFields, setSelectedFields] = useState([]);
  const userData = useSelector(store => store.user);
  const { applicationName } = userData;
  const [error, setError] = useState("");
  const [successPopup, setSuccessPopup] = useState(false);
  const [customFieldModal, setCustomFieldModal] = useState(false);
  const inputRef = useRef();

  // Function to handle adding custom fields from the modal
  const handleAddCustomFields = (newFields) => {
    setSelectedFields([...selectedFields, ...newFields]);
    setCustomFieldModal(false); // Close the modal
  };

  // Function to open the custom field modal
  const openCustomFieldModal = () => {
    setCustomFieldModal(true);
  };

  // Function to handle submission of the modal form
  const handleModalSubmit = (newFields) => {
    setSelectedFields(prevFields => [...prevFields, ...newFields]);
    setCustomFieldModal(false); // Close the modal after submission
  };

  // Function to handle clicking on a field to select it
  const handleFieldClick = (field) => {
    setSelectedFields((prev) => {
      const fieldExists = prev.some(
        (existingField) => existingField.name === field.name
      );
      if (!fieldExists) {
        return [...prev, field];
      }
      return prev;
    });
  };

  // Function to handle creating the table
  const handleCreateTable = () => {
    const inputValue = inputRef.current.value;
    if (!inputValue.trim()) {
      setError("Please enter table name***");
    } else {
      const tableData = {
        schemaName: applicationName,
        tableName: addUnderscores(inputValue),
        columns: selectedFields,
      };
      const tableDataJson = JSON.stringify(tableData);
      fetch(CREATE_TABLE_API, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: tableDataJson,
      }).then((response) => response.json());

      // Reset the form and selected fields after table creation
      inputRef.current.value = "";
      setSelectedFields([]);
      setSuccessPopup(true);
    }
  };

  return (
    <div className="">
      {successPopup && (
        <SuccessPopup
          closeFunction={() => setSuccessPopup(false)}
          message={"Your table has been created successfully "}
        />
      )}
      {customFieldModal && (
        <AddCustomDataFieldModal
          prevFieldData={selectedFields}
          onClose={() => setCustomFieldModal(false)}
          onSubmit={handleModalSubmit}
          onFieldsSubmit={handleAddCustomFields}
        />
      )}
      <p className="text-xs font-semibold text-gray-600">
        Lets create some Data tables for you to use in your application where ever it is needed.
      </p>

      <div className="mt-4">
        <label
          htmlFor="simple-input"
          className="block text-xs font-medium text-gray-700"
        >
          Add a Table Name
        </label>
        <input
          ref={inputRef}
          type="text"
          id="simple-input"
          className="mt-1 block w-6/12 px-3 py-1 bg-white border border-gray-300 rounded-md  text-sm text-gray-700 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500"
          placeholder="Enter the table name..."
        />
        <p className="text-red-600 text-xs">{error}</p>
      </div>
      <h5 className="text-xs mt-2 font-semibold text-skt-light-blue cursor-pointer">
        Check How
      </h5>
      <div className="flex m-4 h-64">
        <table className="shadow-xl border border-skt-blue w-6/12">
          <thead>
            <tr className="bg-gray-200  font-semibold text-sm">
              <td className="pl-2 py-1 border-r border-gray-400"> Name</td>
              <td className="pl-2 py-1">Type</td>
            </tr>
          </thead>

          <tbody>
            {dummyFeildList.map((field) => (
              <tr
                key={field.name}
                className="border-b cursor-pointer  border-gray-400 text-sm"
                onClick={() => handleFieldClick(field)}
              >
                <td className="pl-2 py-1">{field.name}</td>
                <td className="pl-2 py-1">{field.type}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <table className="shadow-xl border border-skt-blue w-6/12">
          <thead className="w-full table">
            <tr className="bg-gray-200  font-semibold text-sm">
              <td className="pl-2 py-1 border-r border-gray-400"> Name</td>
              <td className="pl-2 py-1">Type</td>
            </tr>
          </thead>

          <tbody className="block h-[228px] w-full overflow-auto">
            {selectedFields.map((field) => (
              <tr
                key={field.name}
                className="border-b w-full  border-gray-400 text-sm"
              >
                <td className="pl-2 py-1 w-72">{field.name}</td>
                <td className="pl-2 py-1 w-52">{field.type}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex justify-between">
        <button
          onClick={handleCreateTable}
          className="px-2 text-sm  border-2 border-skt-blue text-skt-blue rounded-full  hover:bg-skt-blue hover:text-white"
        >
          Create Data Table
        </button>
        <button
          onClick={() => {
            setCustomFieldModal(true);
          }}
          className="px-2 text-sm  border-2 border-skt-blue text-skt-blue rounded-full  hover:bg-skt-blue hover:text-white"
        >
          Add custom field data+
        </button>
      </div>
    </div>
  );
};

export default CreateData;
