import { create } from "zustand";

export const useStore = create((set) => ({
  formConfiguration: {
    formName: "",
    formDescription: "",
    elements: [],
  },
  // navigation configuration logic
  navElements: [],
  pages: { Home: 1, About: 2, Contact: 3 }, // Example pages
  navActions: ["Display Page", "Open Menu"], // Example actions
  addNavElement: () =>
    set((state) => ({
      navElements: [...state.navElements, { name: "", action: "", page: "" }],
    })),
  updateNavElement: (index, field, value) =>
    set((state) => {
      const updatedElements = [...state.navElements];
      updatedElements[index][field] = value;
      return { navElements: updatedElements };
    }),
  removeNavElement: (index) =>
    set((state) => ({
      navElements: state.navElements.filter((_, i) => i !== index),
    })),
  previewConfiguration: () =>
    set((state) => {
      console.log("Preview Configuration:", state.navElements);
    }),
  saveConfiguration: () =>
    set((state) => {
      const configurationToSave = state.navElements.map(
        ({ name, action, page }) => ({
          linkName: name,
          selectedAction: action,
          selectedPage: page,
        })
      );
      console.log("Save Configuration to Backend:", configurationToSave);
      // Add logic to send configurationToSave to backend
    }),
  // navigation config end here
  step: 0,
  showPaymentConfig: false,
  selectedPaymentMethods: [],

  setFormName: (name) =>
    set((state) => ({
      ...state,
      formConfiguration: {
        ...state.formConfiguration,
        formName: name,
      },
    })),

  setFormDescription: (description) =>
    set((state) => ({
      ...state,
      formConfiguration: {
        ...state.formConfiguration,
        formDescription: description,
      },
    })),

  resetForm: () =>
    set((state) => ({
      ...state,
      formConfiguration: {
        formName: "",
        formDescription: "",
        elements: [],
      },
    })),

  updateForm: (formData) =>
    set((state) => ({
      ...state,
      formConfiguration: {
        ...state.formConfiguration,
        ...formData,
      },
    })),

  getFormData: () => {
    const { formName, formDescription } = useStore.getState().formConfiguration;
    return { formName, formDescription };
  },

  setAllFormInputs: (elementsArray) =>
    set((state) => ({
      formConfiguration: {
        ...state.formConfiguration,
        elements: elementsArray,
      },
    })),

  incrementStep: () =>
    set((state) => ({
      step: state.step + 1,
    })),

  decrementStep: () =>
    set((state) => ({
      step: state.step > 0 ? state.step - 1 : 0,
    })),

  setStep: (number) =>
    set(() => ({
      step: number,
    })),

  addPaymentMethod: (method) =>
    set((state) => ({
      selectedPaymentMethods: [...state.selectedPaymentMethods, method],
    })),

  removePaymentMethod: (method) =>
    set((state) => ({
      selectedPaymentMethods: state.selectedPaymentMethods.filter(
        (selected) => selected.name !== method.name
      ),
    })),

  // Functions to manage showPaymentConfig state
  setShowPaymentConfigTrue: () =>
    set(() => ({
      showPaymentConfig: true,
    })),

  setShowPaymentConfigFalse: () =>
    set(() => ({
      showPaymentConfig: false,
    })),

  toggleShowPaymentConfig: () =>
    set((state) => ({
      showPaymentConfig: !state.showPaymentConfig,
    })),
}));
