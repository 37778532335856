import React, { useRef, useState } from "react";
import axios from "axios";
import { BASE_URL } from "../../../../utils/utils/constants";
import {
  showSuccessToast,
  showWarningToast,
  showErrorToast,
} from "../../../Toast/ToastNotification";

const ManageImages = () => {
  const [images, setImages] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [imageName, setImageName] = useState("");
  const fileInputRef = useRef(null);

  function selectFiles() {
    fileInputRef.current.click();
  }

  function onFileSelect(event) {
    const files = event.target.files;
    if (files.length === 0) return;
    for (let i = 0; i < files.length; i++) {
      if (files[i].type.split("/")[0] !== "image") continue;
      if (!images.some((e) => e.name === files[i].name)) {
        setImages((prevImages) => [
          ...prevImages,
          {
            name: files[i].name,
            file: files[i],
            url: URL.createObjectURL(files[i]),
          },
        ]);
      }
    }
  }

  function deleteImage(index) {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  }

  function onDragOver(event) {
    event.preventDefault();
    setIsDragging(true);
    event.dataTransfer.dropEffect = "copy";
  }

  function onDragLeave(event) {
    event.preventDefault();
    setIsDragging(false);
  }

  function onDrop(event) {
    event.preventDefault();
    setIsDragging(false);
    const files = event.dataTransfer.files;
    for (let i = 0; i < files.length; i++) {
      if (files[i].type.split("/")[0] !== "image") continue;
      if (!images.some((e) => e.name === files[i].name)) {
        setImages((prevImages) => [
          ...prevImages,
          {
            name: files[i].name,
            file: files[i],
            url: URL.createObjectURL(files[i]),
          },
        ]);
      }
    }
  }

  async function uploadImages() {
    if (images.length === 0 || !imageName) {
      alert("Please select images and provide an image name.");
      return;
    }

    const formData = new FormData();
    images.forEach((image) => {
      formData.append("files", image.file);
    });

    const url = `${BASE_URL}upload?appId=A-1&assetDescription=ImageDescription&assetName=${imageName}`;

    try {
      const response = await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (response.status === 200) {
        showSuccessToast("Images uploaded successfully.");
        setImages([]);
        setImageName("");
      }
    } catch (error) {
      if (error.response.status === 400) {
        showWarningToast("There was a problem with your request.");
      } else if (error.response.status === 500) {
        showErrorToast("Failed to upload images");
      } else {
        console.error("Error uploading images:", error);
        showErrorToast("Failed to upload images.");
      }
    }
  }

  return (
    <div className="flex mt-6 items-center justify-center  p-4">
      <div className="shadow-lg rounded-lg p-10 w-full max-w-lg bg-white">
        <h2 className="text-3xl font-bold text-center mb-6 text-gray-800">Upload Images</h2>

        <div
          className={`border-4 border-dashed rounded-lg p-8 flex flex-col items-center transition-all duration-300 ease-in-out
            ${isDragging ? "bg-blue-100 border-blue-500" : "bg-gray-50 border-gray-300"}`}
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
        >
          {isDragging ? (
            <span className="text-blue-500 font-medium">Drop images here</span>
          ) : (
            <>
              <span className="text-gray-500">Drag & Drop images here or</span>
              <button
                type="button"
                className="mt-4 bg-blue-600 text-white px-6 py-2 rounded-lg shadow hover:bg-blue-700 transition duration-200"
                onClick={selectFiles}
              >
                Browse Files
              </button>
            </>
          )}
          <input
            name="file"
            type="file"
            className="hidden"
            multiple
            ref={fileInputRef}
            onChange={onFileSelect}
          />

          <div className="grid grid-cols-3 gap-4 mt-4 w-full">
            {images.map((image, index) => (
              <div
                className="relative border border-gray-200 rounded-lg overflow-hidden shadow transition-transform duration-200 hover:scale-105"
                key={index}
              >
                <button
                  className="absolute top-2 right-2 bg-red-600 text-white text-lg font-bold rounded-full px-2 py-1 shadow hover:bg-red-700 transition duration-200"
                  onClick={() => deleteImage(index)}
                >
                  &times;
                </button>
                <img className="w-full h-24 object-cover" src={image.url} alt={image.name} />
              </div>
            ))}
          </div>
        </div>

        <div className="flex items-center mt-8">
          <input
            type="text"
            className="flex-grow border h-[40px] border-gray-300 rounded-lg p-3 mr-2 text-gray-700 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-600 transition duration-200"
            placeholder="Image name..."
            value={imageName}
            onChange={(e) => setImageName(e.target.value)}
            required
          />
          <button
            type="button"
            className="bg-blue-600 text-white px-6 py-2 rounded-lg shadow hover:bg-blue-700 transition duration-200"
            onClick={uploadImages}
          >
            Upload
          </button>
        </div>
      </div>
    </div>
  );
};

export default ManageImages;
