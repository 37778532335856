import { Link } from "react-router-dom";
import React, { useState } from "react";
import PopupModal from "./PopupModal";
import useFooterStore from "../../store/useFooterStore";

import axios from "axios";
import {
  showErrorToast,
  showSuccessToast,
  showWarningToast,
} from "../../components/Toast/ToastNotification";

const FooterPreview = () => {
  const { selectedTemplate, formConfiguration } = useFooterStore();
  console.log("selectedTemplate", selectedTemplate, formConfiguration);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [componentName, setComponentName] = useState("");
  const [error, setError] = useState("");

  const handleSave = async () => {
    if (!componentName) {
      showErrorToast("Component Name field is empty");

      return;
    }
    const compTempStr = selectedTemplate.name;
    const appId = localStorage.getItem("appId");
    //     const appName = localStorage.getItem("appName");
    const jsonPayload = {
      appId: appId,
      compTempID: compTempStr,
      pageId: "page1",
      compName: componentName,
      compType: "Footer",
      visibilityRule: "always",
      visible: true,
      enabled: true,
      version: "1.0",
      createDateTime: new Date().toISOString(),
      json: { ...formConfiguration },
    };
    console.log(JSON.stringify(jsonPayload, null, 2));

    try {
      const res = await axios.post(
        "http://49.207.7.220:8080/api/component/save",
        jsonPayload
      );

      console.log(res.data);
      localStorage.setItem("footerId", res.data.id);
      localStorage.setItem("footerTemplate", selectedTemplate.name);
      console.log("footerId", res.data.id);
      // Notification.success("success configuration saved successfully");
      if (res.status === 200) {
        showSuccessToast("success configuration saved successfully");
        handleClose();
      }
    } catch (error) {
      console.log(error);
      console.log(error.response);
      console.log(error.response.data);
      // Notification.error("something went wrong please try again");
      if (error.response.status === 400) {
        showWarningToast("There was a problem with your request.");
      } else if (error.response.status === 500) {
        showErrorToast("Failed to save configuration");
      } else {
        console.error("Error saving data:", error);
        showErrorToast("Failed to save configuration.");
      }
    }
  };

  const handleComponentNameChange = (e) => {
    setComponentName(e.target.value);
    setError("");
  };

  const handleClose = () => {
    setIsPopupVisible(false);
    setComponentName("");
    setError("");
  };

  const Template = selectedTemplate;
  if (!Template) {
    return (
      <div className=" flex flex-col p-6 items-center justify-evenly">
        <p className="">select a template for configuration</p>
        <Link
          className="p-4 mt-6 rounded-md hover:bg-[#a9a9ff] duration-300  bg-[#d4d4ff]"
          to={"/config-steps/footer"}
        >
          Back to configure
        </Link>
      </div>
    );
  }

  return (
    <>
      <Template config={formConfiguration} />
      {isPopupVisible && (
        <PopupModal
          isVisible={isPopupVisible}
          title="Enter Component Name"
          onClose={handleClose}
          onSave={handleSave}
          errorMessage={error}
          saveButtonText="Save"
          cancelButtonText="Cancel"
        >
          <input
            type="text"
            value={componentName}
            onChange={handleComponentNameChange}
            placeholder="Component Name"
            className="border border-gray-300 p-2 rounded w-full mb-4"
            required
          />
        </PopupModal>
      )}

      <div className="flex justify-between items-center p-4 bg-gray-100 border-b border-gray-300">
        <Link
          to={"/config-steps/footer"}
          className="text-blue-600 hover:underline font-medium"
        >
          Back to configure
        </Link>
        <button
          onClick={() => setIsPopupVisible(true)}
          className="px-4 py-2 bg-green-500 text-white rounded"
        >
          Save configuration
        </button>
      </div>
    </>
  );
};

export default FooterPreview;
