import React from 'react'

const ProcessDesign = () => {
  return (
    <div className='text-center'>
      {/* <h1 className='text-3xl'>PROCESS DESIGN</h1> */}
    </div>
  )
}

export default ProcessDesign
