// src/components/previews/ImagePreview.js
import React from "react";

const ImagePreview = ({ config }) => {
  const { src, alt, width, height } = config;

  return (
    <img
      src={src || "https://via.placeholder.com/150"}
      alt={alt || "Placeholder Image"}
      style={{
        width: width || "150px",
        height: height || "150px",
        objectFit: "cover",
      }}
    />
  );
};

export default ImagePreview;
