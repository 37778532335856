// export const optionsData =[
//     {
//         "id": 1,
//         "option_id": "OP1",
//         "option_description": "TBD",
//         "option_value": "Ecommerce",
//         "question_id": "GQ02",
//         "createdby": "DUMMY_FOR_NOW",
//         "updatedby": "DUMMY_FOR_NOW",
//         "created_at": "2024-05-29 16:16:14.558",
//         "updated_at": "2024-05-29 16:16:14.558"
//     },
//     {
//         "id": 2,
//         "option_id": "OP2",
//         "option_description": "TBD",
//         "option_value": "Portfolio",
//         "question_id": "GQ02",
//         "createdby": "DUMMY_FOR_NOW",
//         "updatedby": "DUMMY_FOR_NOW",
//         "created_at": "2024-05-29 16:16:14.558",
//         "updated_at": "2024-05-29 16:16:14.558"
//     },
//     {
//         "id": 3,
//         "option_id": "OP3",
//         "option_description": "TBD",
//         "option_value": "Business Landing page/Awareness page",
//         "question_id": "GQ02",
//         "createdby": "DUMMY_FOR_NOW",
//         "updatedby": "DUMMY_FOR_NOW",
//         "created_at": "2024-05-29 16:16:14.558",
//         "updated_at": "2024-05-29 16:16:14.558"
//     },
//     {
//         "id": 4,
//         "option_id": "OP4",
//         "option_description": "TBD",
//         "option_value": "Internal Business Management ",
//         "question_id": "GQ02",
//         "createdby": "DUMMY_FOR_NOW",
//         "updatedby": "DUMMY_FOR_NOW",
//         "created_at": "2024-05-29 16:16:14.558",
//         "updated_at": "2024-05-29 16:16:14.558"
//     },
//     {
//         "id": 5,
//         "option_id": "OP5",
//         "option_description": "TBD",
//         "option_value": "Blog",
//         "question_id": "GQ02",
//         "createdby": "DUMMY_FOR_NOW",
//         "updatedby": "DUMMY_FOR_NOW",
//         "created_at": "2024-05-29 16:16:14.558",
//         "updated_at": "2024-05-29 16:16:14.558"
//     },
//     {
//         "id": 6,
//         "option_id": "OP6",
//         "option_description": "TBD",
//         "option_value": "Static / Single page",
//         "question_id": "GQ03",
//         "createdby": "DUMMY_FOR_NOW",
//         "updatedby": "DUMMY_FOR_NOW",
//         "created_at": "2024-05-29 16:16:14.558",
//         "updated_at": "2024-05-29 16:16:14.558"
//     },
//     {
//         "id": 7,
//         "option_id": "OP7",
//         "option_description": "TBD",
//         "option_value": "Dynamic / Multiple page",
//         "question_id": "GQ03",
//         "createdby": "DUMMY_FOR_NOW",
//         "updatedby": "DUMMY_FOR_NOW",
//         "created_at": "2024-05-29 16:16:14.558",
//         "updated_at": "2024-05-29 16:16:14.558"
//     },
//     {
//         "id": 8,
//         "option_id": "OP8",
//         "option_description": "TBD",
//         "option_value": "Signin & Signout",
//         "question_id": "GQ04",
//         "createdby": "DUMMY_FOR_NOW",
//         "updatedby": "DUMMY_FOR_NOW",
//         "created_at": "2024-05-29 16:16:14.558",
//         "updated_at": "2024-05-29 16:16:14.558"
//     },
//     {
//         "id": 9,
//         "option_id": "OP9",
//         "option_description": "TBD",
//         "option_value": "Email Verification",
//         "question_id": "GQ04",
//         "createdby": "DUMMY_FOR_NOW",
//         "updatedby": "DUMMY_FOR_NOW",
//         "created_at": "2024-05-29 16:16:14.558",
//         "updated_at": "2024-05-29 16:16:14.558"
//     },
//     {
//         "id": 10,
//         "option_id": "OP10",
//         "option_description": "TBD",
//         "option_value": "Mobile OTP Verification",
//         "question_id": "GQ04",
//         "createdby": "DUMMY_FOR_NOW",
//         "updatedby": "DUMMY_FOR_NOW",
//         "created_at": "2024-05-29 16:16:14.558",
//         "updated_at": "2024-05-29 16:16:14.558"
//     },
//     {
//         "id": 11,
//         "option_id": "OP11",
//         "option_description": "TBD",
//         "option_value": "Multi Language support",
//         "question_id": "GQ04",
//         "createdby": "DUMMY_FOR_NOW",
//         "updatedby": "DUMMY_FOR_NOW",
//         "created_at": "2024-05-29 16:16:14.558",
//         "updated_at": "2024-05-29 16:16:14.558"
//     },
//     {
//         "id": 12,
//         "option_id": "OP12",
//         "option_description": "TBD",
//         "option_value": "Admin Dashboard",
//         "question_id": "GQ04",
//         "createdby": "DUMMY_FOR_NOW",
//         "updatedby": "DUMMY_FOR_NOW",
//         "created_at": "2024-05-29 16:16:14.558",
//         "updated_at": "2024-05-29 16:16:14.558"
//     },
//     {
//         "id": 13,
//         "option_id": "OP13",
//         "option_description": "TBD",
//         "option_value": "Admin(default)",
//         "question_id": "GQ05",
//         "createdby": "DUMMY_FOR_NOW",
//         "updatedby": "DUMMY_FOR_NOW",
//         "created_at": "2024-05-29 16:16:14.558",
//         "updated_at": "2024-05-29 16:16:14.558"
//     },
//     {
//         "id": 14,
//         "option_id": "OP14",
//         "option_description": "TBD",
//         "option_value": "Customer/User",
//         "question_id": "GQ05",
//         "createdby": "DUMMY_FOR_NOW",
//         "updatedby": "DUMMY_FOR_NOW",
//         "created_at": "2024-05-29 16:16:14.558",
//         "updated_at": "2024-05-29 16:16:14.558"
//     },
//     {
//         "id": 15,
//         "option_id": "OP15",
//         "option_description": "TBD",
//         "option_value": "Manager",
//         "question_id": "GQ05",
//         "createdby": "DUMMY_FOR_NOW",
//         "updatedby": "DUMMY_FOR_NOW",
//         "created_at": "2024-05-29 16:16:14.558",
//         "updated_at": "2024-05-29 16:16:14.558"
//     },
//     {
//         "id": 16,
//         "option_id": "OP16",
//         "option_description": "TBD",
//         "option_value": "Team member",
//         "question_id": "GQ05",
//         "createdby": "DUMMY_FOR_NOW",
//         "updatedby": "DUMMY_FOR_NOW",
//         "created_at": "2024-05-29 16:16:14.558",
//         "updated_at": "2024-05-29 16:16:14.558"
//     }
// ]

//  {
//         "id": 194,
//         "createdby": "aaush",
//         "updatedby": "aaush",
//         "appid": null,
//         "flowid": null,
//         "userid": null,
//         "created_at": "2024-04-05T09:41:34.951+00:00",
//         "updated_at": "2024-04-05T09:41:34.951+00:00",
//         "option_id": "OP194",
//         "option_description": "TBD",
//         "option_value": "No, prefer a different access control model",
//         "question_id": "GQ74"
//     }
// ]

export const optionsData = [
  {
    id: 1,
    option_id: "OP1",
    option_description: "TBD",
    option_value: "Mobile",
    question_id: "GQ02",
    createdby: "DUMMY_FOR_NOW",
    updatedby: "DUMMY_FOR_NOW",
    created_at: "2024-05-29 16:16:14.558",
    updated_at: "2024-05-29 16:16:14.558",
  },
  {
    id: 2,
    option_id: "OP2",
    option_description: "TBD",
    option_value: "Web",
    question_id: "GQ02",
    createdby: "DUMMY_FOR_NOW",
    updatedby: "DUMMY_FOR_NOW",
    created_at: "2024-05-29 16:16:14.558",
    updated_at: "2024-05-29 16:16:14.558",
  },
  {
    id: 3,
    option_id: "OP3",
    option_description: "TBD",
    option_value: "Both",
    question_id: "GQ02",
    createdby: "DUMMY_FOR_NOW",
    updatedby: "DUMMY_FOR_NOW",
    created_at: "2024-05-29 16:16:14.558",
    updated_at: "2024-05-29 16:16:14.558",
  },

  {
    id: 4,
    option_id: "OP4",
    option_description: "TBD",
    option_value: "Android",
    question_id: "GQ03",
    createdby: "DUMMY_FOR_NOW",
    updatedby: "DUMMY_FOR_NOW",
    created_at: "2024-05-29 16:16:14.558",
    updated_at: "2024-05-29 16:16:14.558",
  },
  {
    id: 5,
    option_id: "OP5",
    option_description: "TBD",
    option_value: "IOS",
    question_id: "GQ03",
    createdby: "DUMMY_FOR_NOW",
    updatedby: "DUMMY_FOR_NOW",
    created_at: "2024-05-29 16:16:14.558",
    updated_at: "2024-05-29 16:16:14.558",
  },
  {
    id: 6,
    option_id: "OP6",
    option_description: "TBD",
    option_value: "Both",
    question_id: "GQ03",
    createdby: "DUMMY_FOR_NOW",
    updatedby: "DUMMY_FOR_NOW",
    created_at: "2024-05-29 16:16:14.558",
    updated_at: "2024-05-29 16:16:14.558",
  },
  {
    id: 7,
    option_id: "OP7",
    option_description: "TBD",
    option_value: "E-Commerce",
    question_id: "GQ04",
    createdby: "DUMMY_FOR_NOW",
    updatedby: "DUMMY_FOR_NOW",
    created_at: "2024-05-29 16:16:14.558",
    updated_at: "2024-05-29 16:16:14.558",
  },
  {
    id: 8,
    option_id: "OP8",
    option_description: "TBD",
    option_value: "Static Page",
    question_id: "GQ04",
    createdby: "DUMMY_FOR_NOW",
    updatedby: "DUMMY_FOR_NOW",
    created_at: "2024-05-29 16:16:14.558",
    updated_at: "2024-05-29 16:16:14.558",
  },
  {
    id: 9,
    option_id: "OP9",
    option_description: "TBD",
    option_value: "Customers",
    question_id: "GQ05",
    createdby: "DUMMY_FOR_NOW",
    updatedby: "DUMMY_FOR_NOW",
    created_at: "2024-05-29 16:16:14.558",
    updated_at: "2024-05-29 16:16:14.558",
  },
  {
    id: 10,
    option_id: "OP10",
    option_description: "TBD",
    option_value: "Vendor",
    question_id: "GQ05",
    createdby: "DUMMY_FOR_NOW",
    updatedby: "DUMMY_FOR_NOW",
    created_at: "2024-05-29 16:16:14.558",
    updated_at: "2024-05-29 16:16:14.558",
  },
  {
    id: 11,
    option_id: "OP11",
    option_description: "TBD",
    option_value: "Agent",
    question_id: "GQ05",
    createdby: "DUMMY_FOR_NOW",
    updatedby: "DUMMY_FOR_NOW",
    created_at: "2024-05-29 16:16:14.558",
    updated_at: "2024-05-29 16:16:14.558",
  },
  {
    id: 12,
    option_id: "OP12",
    option_description: "TBD",
    option_value: "Event Managers",
    question_id: "GQ05",
    createdby: "DUMMY_FOR_NOW",
    updatedby: "DUMMY_FOR_NOW",
    created_at: "2024-05-29 16:16:14.558",
    updated_at: "2024-05-29 16:16:14.558",
  },
  {
    id: 13,
    option_id: "OP13",
    option_description: "TBD",
    option_value: "Others",
    question_id: "GQ05",
    createdby: "DUMMY_FOR_NOW",
    updatedby: "DUMMY_FOR_NOW",
    created_at: "2024-05-29 16:16:14.558",
    updated_at: "2024-05-29 16:16:14.558",
  },
];
