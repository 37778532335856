import {create} from "zustand";
import Notification from "../services/Notification";
import customInstance from "../axios/axiosInstance";
import axios from "axios";

const useFooterStore = create((set) => ({
  selectedTemplate: null,
  setSelectedTemplate: (template) => set({ selectedTemplate: template }),
  resetSelectedTemplate: () => set({ selectedTemplate: null }),

  formConfiguration: {
    logoText: "",
    logoUrl:"",
    BrandName: "",
    contactInfo: {
      email: "",
      phone: "",
      address: "",
      timings: "",
    },
    socialMediaIcons: [],
    socialMediaLinks: [],
    socialMediaData:[],
    links: [],
    backgroundColor: "#ffffff",
    textColor: "#000000",
  },

  setFormConfiguration: (newConfig) =>
    set((prevState) => {
      // Check if newConfig is a function (callback)
      if (typeof newConfig === "function") {
        return { formConfiguration: newConfig(prevState.formConfiguration) };
      }
      // Otherwise, treat it as an object
      return {
        formConfiguration: { ...prevState.formConfiguration, ...newConfig },
      };
    }),

  // setFormConfiguration: (newConfig) =>
  //   set((state) => ({
  //     formConfiguration: {
  //       ...state.formConfiguration,
  //       ...newConfig,
  //     },
  //   })),

  // this is a function to call footer configuration details from backend or mock api from json db
  getFooterConfiguration: async () => {
    try {
      const id = localStorage.getItem("footerId");
      const appId = localStorage.getItem("appId");
      const appName = localStorage.getItem("appName");
      const res = await axios.get(
        `http://49.207.7.220:8080/api/fetch-json?schemaName=${appName}&tableName=component&id=${id}&columnName=app_id&columnValue=${appId}`
      );
      const dataStr = res.data;
      const data = JSON.parse(dataStr[0]["json"]);
      return data;
    } catch (error) {
      Notification.error("there's an error fetching footer data");
      console.log(error);
    }
  },
}));

export default useFooterStore;
