import {IoCloseCircleSharp} from "react-icons/io5";

const InputField = ({ onNameChange, onTypeChange, id, onRemove }) => {
    return (
      <div className="relative border border-gray-600 shadow-lg p-2 m-2 mt-4 flex">
        <span 
          className="absolute -top-3  -right-2 text-2xl cursor-pointer "
          onClick={() => onRemove(id)}
        >
          <IoCloseCircleSharp className="bg-white text-red-600 rounded-full" />
        </span>
        <input
          className="text-sm border-2 border-gray-400 p-2 rounded-lg w-8/12 mr-2"
          placeholder="Enter column name"
          type="text"
          onChange={(e) => onNameChange(id, e.target.value)}
          required
        />
        <select
          className=" border-l-2 border py-2 text-sm text-center rounded-md border-gray-600 w-4/12"
          onChange={(e) => onTypeChange(id, e.target.value)}
        >
          <option value="selectType">select type</option>

          <option value="text">Text</option>
          <option value="number">Number</option>
          <option value="float">Float</option>
          <option value="decimal">Decimal</option>
          <option value="image">Image</option>
          <option value="date">Date</option>
        </select>
      </div>
    );
  };
  
  
export default InputField;