import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import profileStore from "../../store/profileStore";
import profilePlaceholder from "./profile.png";
import { FaUserEdit } from "react-icons/fa";
import useProfileValidation from "./useProfileValidation";
import UserImageUpload from "./UserImageUpload";
import { FaArrowRightLong, FaArrowLeftLong, FaTrash } from "react-icons/fa6";
import {
  getTokenFromLocalStorage,
  setTokenToLocalStorage,
} from "../../utils/utils/LocalStorageUtils";
import Header from "../header/Header";
import Notification from "../../services/Notification";
import InputField from "./InputField";
import Button from "./Button";
import axios from "axios";
import "./p.css";
import { showErrorToast, showSuccessToast, showWarningToast } from "../Toast/ToastNotification";

const UserProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [curStep, setCurStep] = useState(1);
  //   const [userimg, setUserimg] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const setUserImage = profileStore((state) => state.setUserImage);
  const userImage = profileStore((state) => state.userImage);
  const signupEmail = profileStore((state) => state.signupEmail);

  const [isNewUser, setIsNewUser] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    email: "",
    phoneNumber: "",
    role: "",
    address: "",
    state: "",
    country: "",
    gender: "",
     profilePictureUrl: "",
  });

  const {errors,validate} = useProfileValidation(formData);
  const isUserDataEntryRoute = location.pathname === "/auth/userDataEntry"; // Check if the current route is /auth/userDataEntry
  const fetchEmail = getTokenFromLocalStorage("email");

  const getUserProfile = async () => {
    const token = getTokenFromLocalStorage("token");

    if (token && fetchEmail ) {


      try {
        const res = await axios.get(
          `http://49.207.7.220:8080/api/get-profile/${fetchEmail}`

        );
        console.log("response of api: ", res.data);
        console.log(JSON.stringify(res.data, null, 2));

        if (res.data) {
          setFormData({
            firstName: res.data.firstName || "",
            lastName: res.data.lastName || "",
            dateOfBirth: res.data.dateOfBirth || "",
            email: res.data.email || "",
            phoneNumber: res.data.phoneNumber || "",
            role: res.data.whoAreYou || "",
            address: res.data.address || "",
            state: res.data.state || "",
            country: res.data.country || "",
            gender: res.data.gender || "",
             profilePictureUrl: res.data.profilePictureUrl || profilePlaceholder,
          });
          setIsNewUser(false);
        } else {
          console.log("No data received from API");
        }
        console.log("response of api: 0 ", res.data);
      } catch (error) {
        console.log("Error fetching user data:1 ", error);
        if (error.response) {
          if (error.response.status === 404) {
            console.log("data not found ,Create a new user");
            // Notification.warning("Warning", "Create your user profile");
            showWarningToast("Create your user profile");
            setIsNewUser(true);
          } else if (error.response.status >= 500) {
            console.log("Server Error");
            showErrorToast("please try again after some time");
          }
        } else {
          console.log("Unexpected error occurred");
          showErrorToast("Unexpected error occurred, try again");
        }
      }
    } else {
      console.log("No token found");
      showErrorToast("You are not authenticated please login");
    }
  };
  useEffect(() => {
    if (location.pathname === "/userprofile") {
      getUserProfile();
    }
  }, [location.pathname]);



  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

  };




  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValidate = validate(formData);
    if (!isValidate) return;

    const dataToSend = {
      firstName: formData.firstName || "",
      lastName: formData.lastName || "",
      dateOfBirth: formData.dateOfBirth || "",
      email: isUserDataEntryRoute ? signupEmail : formData.email,
      phoneNumber: Number(formData.phoneNumber) ,
      whoAreYou: formData.role || "",
      address: formData.address || "",
      state: formData.state || "",
      country: formData.country || "",
      gender: formData.gender || "",
    };

    console.log(dataToSend);
    console.log(JSON.stringify(dataToSend, null, 2));

    try {
      const headers = {
        "Content-Type": "application/json",
      };

      if (isUserDataEntryRoute || isNewUser) {
        // POST request for new data entry
        const saveRes = await axios.post(
          "http://49.207.7.220:8080/api/save-profile",
          dataToSend,
          { headers }
        );
        console.log(saveRes.data);
        if (saveRes) {
          console.log("Data saved successfuly ", saveRes.data);
          showSuccessToast("User Profile created successfully");
        }
        if (saveRes.data && isUserDataEntryRoute) {
          navigate("/auth/signin"); // Navigate after data entry
        }
      } else if (location.pathname === "/userprofile") {
        const token = getTokenFromLocalStorage("token");
        if (token) {
          const updateProfileEmail = getTokenFromLocalStorage("email");
          // PUT request for updating existing profile
          const res = await axios.put(
            `http://49.207.7.220:8080/api/update-profile/${updateProfileEmail}`,
            dataToSend,
            { headers }
          );
          console.log(res.data);
          console.log(JSON.stringify(res.data, null, 2));
          console.log("data updated");
          if (res.data) {
            console.log("Data saved successfuly ", res.data);
            // Notification.success(
            //   "Success",
            //   "User Profile is updated successfully"
            // );
            showSuccessToast("User Profile is updated successfully");
            setIsEdit(false); // Disable editing mode after update
          }
        }
      }
    } catch (error) {
      // Handle error responses
      if (error.response) {
        const { status, data } = error.response;
        if (status === 400) {
          console.error(
            "Bad Request: ",
            data.message || "Please check your input."
          );
          showErrorToast("Please check your input.");
        } else if (status === 401) {
          console.error("Unauthorized: You must log in again.");
          showWarningToast("Unauthorized: You must log in again.");
          // navigate('/auth/signin');
        } else if (status === 500) {
          console.error("Internal Server Error: Please try again later.");
          showErrorToast("Internal Server Error: Please try again later.");
        } else {
          console.error("Unknown error:", data);
        }
      } else if (error.request) {
        console.error("Network Error: Please check your internet connection.");
        showErrorToast("Network Error: Please check your internet connection.");
      } else {
        console.error("Error:", error.message);
        showErrorToast(`${error.message}`);
      }
    }
  };

  const handleSkip = () => {
    navigate("/auth/signin");
  };

  return (
    <>
      {!isUserDataEntryRoute && (
        <div>
          <Header />
        </div>
      )}
      <div className="profile-main   ">
        <div className="blob-1 "></div>

        <div className="blob-2"></div>
        <div className="profile">
          <div className="step-back-btn">
            <div className="back-stp-btn">

            </div>
            <div className="skip-edit-div">
              {isUserDataEntryRoute ? (
                <button
                  onClick={handleSkip}
                  className="flex items-center text-xl space-x-2 bg-[#1e90ff] text-white px-4 py-2 rounded-lg shadow-md transform transition-all
                  duration-100 ease-out hover:scale-105 hover:shadow-lg hover:shadow-[blue]-500/50 hover:-translate-y-1"
                >
                   Skip
                </button>
              ) : (
                !isEdit && (
                  <button
                    onClick={() => setIsEdit(true)}
                    className="flex items-center  space-x-2 bg-[#1e90ff] text-white px-4 py-2 rounded-lg shadow-md transform
                    transition-all duration-100 ease-out hover:scale-105 hover:shadow-lg hover:shadow-[blue]-500/50 hover:-translate-y-1"
                  >
                  <span className="text-lg sm:text-xl md:text-2xl lg:text-2xl"> <FaUserEdit /></span>
                   <span className="hidden sm:inline">Edit</span>
                  </button>
                )
              )}
            </div>
          </div>

          <h1>Profile</h1>
          <form onSubmit={handleSubmit}>
            <UserImageUpload/>

            <div className="form-con">

                  <InputField
                    label="First Name"
                    type="text"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                    placeholder=""
                    disabled={!isEdit && !isUserDataEntryRoute}
                  />

                  <InputField
                    label="Last Name"
                    type="text"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                    placeholder=""
                    disabled={!isEdit && !isUserDataEntryRoute}
                  />

                  <InputField
                          label="Gender"
                          type="select"
                          name="gender"
                          value={formData.gender}
                          onChange={handleChange}
                          options={['Male', 'Female', 'Others']} // Gender options
                          disabled={!isEdit && !isUserDataEntryRoute}
                        />
                  <InputField
                    label="Date of Birth"
                    type="date"
                    name="dateOfBirth"
                    placeholder=""
                    value={formData.dateOfBirth}
                    onChange={handleChange}
                    disabled={!isEdit && !isUserDataEntryRoute}
                  />

                  <InputField
                    label="Email"
                    type="email"
                    name="email"
                    value={isUserDataEntryRoute ? signupEmail : fetchEmail}
//                     value={isUserDataEntryRoute ? signupEmail : formData.email}
                    onChange={handleChange}
                    placeholder=""
                     disabled={true}
//                     disabled={isUserDataEntryRoute || (!isEdit && !isUserDataEntryRoute)}
                  />

                  <InputField
                    label="Phone Number"
                    type="text"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    placeholder=""
                    disabled={!isEdit && !isUserDataEntryRoute}
                  />


                  <InputField
                    label="Occupation/Role"
                    type="text"
                    name="role"
                    value={formData.role}
                    onChange={handleChange}
                    placeholder=""
                    disabled={!isEdit && !isUserDataEntryRoute}
                  />
                  <InputField
                    label="Address"
                    type="text"
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    placeholder=""
                    disabled={!isEdit && !isUserDataEntryRoute}
                  />


            </div>

            <div className="btn-div-con">

                  <Button
                  disabled={!isEdit && !isUserDataEntryRoute}
                    label={
                      isUserDataEntryRoute || isNewUser ? "Submit" : "Update"
                    }
                    type="submit"
                  />

            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default UserProfile;
