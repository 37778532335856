import "./Header3.css";
import { Link } from "react-router-dom";
const HeaderTemplate3 = ({ config }) => {
  if (!config) {
    return (
      <nav className="nav-template">
        <div className="top-bar">
          <div className="top-left">
            <span>Ship to 12345</span>
            <span>Location</span>
          </div>
          <div className="top-right">
            <a href="#" className="top-link">
              Link 1
            </a>
            <a href="#" className="top-link">
              Link 2
            </a>
            <a href="#" className="top-link">
              Link 3
            </a>
            <a href="#" className="top-link">
              Link 4
            </a>
          </div>
        </div>
        <div className="bottom-bar">
          <div className="left-section">
            <div className="logo">LOGO</div>
            <a href="#" className="category-link">
              Category 1
            </a>
            <a href="#" className="category-link">
              Category 2
            </a>
            <a href="#" className="category-link">
              Category 3
            </a>
            <a href="#" className="category-link">
              Category 4
            </a>
          </div>
          {/* {config.searchBar && (
            <div className="search-section">
              <input type="text" placeholder="Search here..." />
              <button className="search-btn">🔍</button>
            </div>
          )} */}
          <div className="right-section">
            <a href="#" className="right-link">
              👤
            </a>
            <a href="#" className="right-link">
              🛒
            </a>
          </div>
        </div>
      </nav>
    );
  }
  return (
    <nav className="nav-template">
      <div className="top-bar">
        <div className="top-left">
          <span>Ship to 12345</span>
          <span>Location</span>
        </div>
        <div className="top-right">
          {config.navigationLinks.map((link) => {
            return (
              <div className="p-4 bg-white shadow-md rounded-lg mb-4">
                <Link
                  to={`/${link.name.toLowerCase().replace(/\s+/g, "-")}`}
                  className="text-blue-600 hover:text-blue-800 font-semibold text-lg"
                >
                  {link.name}
                </Link>
              </div>
            );
          })}
        </div>
      </div>
      <div className="bottom-bar">
        <div className="left-section">
          <div className="flex items-center gap-2">
          <img src={config?.logoUrl} alt="abcd" className="w-12 h-12" />
        <div className="nav-logo">{config?.logoText}</div>
            </div>
          {/* <div className="logo">{config?.logoText}</div> */}
          {config?.selectedCategories.map((item) => {
            return (
              <div id={item.id}>
                <h3>{item.name}</h3>
              </div>
            );
          })}
        </div>
        {config.searchBar ? (
          <div className="search-section">
            <input type="text" placeholder="Search here..." />
            <button className="search-btn">🔍</button>
          </div>
        ) : null}

        <div className="right-section">
          <a href="#" className="right-link">
            👤
          </a>
          <a href="#" className="right-link">
            {config.cart && <p>🛒</p>}
          </a>
        </div>
      </div>
    </nav>
  );
};

export default HeaderTemplate3;
