import React from "react";
import { Select } from "antd";

const QuestionForm = ({
  question,
  options,
  multiSelectOptions,
  onResponseChange,
}) => {
  const handleChange = (e, multiSelect = false, multiSelectData = []) => {
    if (multiSelect) {
      onResponseChange(question.question_id, multiSelectData);
      return;
    }
    const response =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    onResponseChange(question.question_id, response);
  };

  const handleMultiselect = (newValues) => {
    handleChange("e", true, newValues);
  };

  return (
    <div className="p-4 bg-white shadow rounded-lg">
      <h2 className="text-xl font-semibold mb-4">
        {question.question_name}
      </h2>
      {question.options_type === "text" && (
        <input
          type="text"
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
        />
      )}
      {question.options_type === "RadioButton" &&
        options.map((option, index) => (
          <div key={option.option_id} className="flex items-center mb-2">
            <input
              type="radio"
              id={option.option_id}
              name={question.question_id}
              value={option.option_value}
              onChange={handleChange}
              className="accent-purple-500 h-4 w-4"
            />
            <label htmlFor={option.option_id} className="ml-2 text-md">
              {option.option_value}
            </label>
          </div>
        ))}
      {question.options_type === "select" && (
        <select
          onChange={handleChange}
          className="w-full p-2 border border-gray-300 rounded"
        >
          <option value={""}>Select an Option</option>
          {options.map((option) => (
            <option key={option.option_id} value={option.option_value}>
              {option.option_value}
            </option>
          ))}
        </select>
      )}
      {question.options_type === "multiselect" && (
        <Select
          mode="multiple"
          className="w-full"
          onChange={handleMultiselect}
          optionLabelProp="label"
          options={multiSelectOptions}
        />
      )}
    </div>
  );
};

export default QuestionForm;
