// Forms.js

import React from "react";
import AllForms from "../../formBuilder/Forms";
const Forms = () => {
  return (
    <div>
      <h2>Forms Component</h2>
      {/* for this part kindly import from the form builder forms */}
      <AllForms />
    </div>
  );
};

export default Forms;
