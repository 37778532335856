// src/components/forms/ImageForm.js
import React from "react";
import ImagePreview from "../previews/ImagePreview";

export const getDefaultImageConfig = () => ({
  src: "https://via.placeholder.com/150",
  alt: "Placeholder Image",
  width: "150px",
  height: "150px",
});

const ImageForm = ({ config, onConfigChange }) => {
  const handleChange = (e) => {
    const { name, value } = e.target;
    onConfigChange({ ...config, [name]: value });
  };

  return (
    <div>
      <h3>Image Settings</h3>
      <label>
        Image URL:
        <input
          type="text"
          name="src"
          value={config.src}
          onChange={handleChange}
        />
      </label>
      <label>
        Alt Text:
        <input
          type="text"
          name="alt"
          value={config.alt}
          onChange={handleChange}
        />
      </label>
      <label>
        Width:
        <input
          type="text"
          name="width"
          value={config.width}
          onChange={handleChange}
        />
      </label>
      <label>
        Height:
        <input
          type="text"
          name="height"
          value={config.height}
          onChange={handleChange}
        />
      </label>
    </div>
  );
};

export default ImageForm;
export { ImagePreview };
