import React, { useEffect, useRef } from "react";
export const useOutsideClick = (callbackFn) => {
  let dropdownRef = useRef();
  useEffect(() => {
    let handler = (e) => {
      if (!dropdownRef.current?.contains(e.target)) {
        callbackFn();
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);
  return dropdownRef;
};
