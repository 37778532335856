import React, { useState } from 'react'

const useForgotPasswordValidation = () => {
    const [errors, setErrors] = useState({});
    const validateEmail=(email)=>{
        const emailRegex= /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/;
        if(!email.trim()){
            setErrors((prevErrors)=>({...prevErrors,email:"Email is required"}));
            return false;
        }
        if(!emailRegex.test(email.trim())){
            setErrors((prevErrors)=>({...prevErrors,email:"Email is invalid"}));
            return false;
        }
        setErrors((prevErrors) => ({ ...prevErrors, email: "" }));  // Clear the error if email is valid
                return true;
    }

            return { errors, validateEmail };  // Return errors and validateEmail from the hook

}

export default useForgotPasswordValidation