import React from "react";
import "./Footer3.css";
import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaLinkedin,
  FaYoutube,
} from "react-icons/fa";

const FooterTemplate3 = ({ config }) => {
  if (!config) {
    return (
      <footer className="footer-template">
        <div className="footer-top-section">
          <div className="footer-column">
            <h3>About</h3>
            <a href="#">Contact Us</a>
            <a href="#">About Us</a>
            <a href="#">Careers</a>
            <a href="#">Stories</a>
            <a href="#">Press</a>
            <a href="#">Corporate Info</a>
          </div>
          <div className="footer-column">
            <h3>Group Companies</h3>
            <a href="#">Link 1</a>
            <a href="#">Link 2</a>
            <a href="#">Link 3</a>
          </div>
          <div className="footer-column">
            <h3>Help</h3>
            <a href="#">Payments</a>
            <a href="#">Shipping</a>
            <a href="#">Cancellations</a>
            <a href="#">FAQ</a>
            <a href="#">Report Infringement</a>
          </div>
          <div className="footer-column">
            <h3>Policy</h3>
            <a href="#">Terms of Use</a>
            <a href="#">Security</a>
            <a href="#">Privacy</a>
            <a href="#">Sitemap</a>
            <a href="#">Compliance</a>
          </div>
          <div className="footer-column">
            <h3>Contact</h3>
            <p>Address Line 1</p>
            <p>Address Line 2</p>
            <p>City, State, Zip</p>
            <p>Phone: +1 234 567 890</p>
            <div className="social-icons">
              <span>🔗</span>
              <span>🔗</span>
              <span>🔗</span>
            </div>
          </div>
        </div>
        <div className="footer-bottom-section">
          <div className="footer-bottom-links">
            <a href="#">Become a Seller</a>
            <a href="#">Advertise</a>
            <a href="#">Gift Cards</a>
            <a href="#">Help Center</a>
          </div>
          <div className="footer-bottom-info">
            <p>© 2024 Your Company</p>
            <div className="payment-icons">
              <span>Visa</span>
              <span>MasterCard</span>
              <span>American Express</span>
              <span>PayPal</span>
            </div>
          </div>
        </div>
      </footer>
    );
  }
  const socialMediaData = config?.socialMediaIcons.map((icon, index) => ({
  id: index + 1,
  icon: icon,
  link: config?.socialMediaLinks[index] || "", // Use an empty string if there's no corresponding link
  }));
  console.log('socialMediaData',socialMediaData);
  return (
    <footer className="footer-template">
      <div className="flex items-center gap-2">
              <img src={config?.logoUrl} alt="abcd" className="w-12 h-12" />
              <span className="logo">{config?.logoText}</span>
            </div>
      <div className="footer-top-section">
        {config.links.map((ele, index) => {
          const heading = Object.keys(ele)[0];
          const linksOfHeading = ele[heading];
          return (
            <div key={index} className="footer-column">
              <h4>{heading}</h4>
              {linksOfHeading.map((link, index) => (
                <a href="#" key={index}>
                  {link}
                </a>
              ))}
            </div>
          );
        })}
        <div key="contact" className="footer-column">
          <h3>Contact</h3>
          <p>{config.contactInfo.address}</p>
          <p>{config.contactInfo.email}</p>
          <p>Phone: {config.contactInfo.phone}</p>
          <div className="social-icons">
            {socialMediaData?.map((item, index) => (
              <a href={item.link} target="_blank" rel="noopener noreferrer" key={index}>
                {item.icon === "facebook" && <FaFacebook />}
                {item.icon === "instagram" && <FaInstagram />}
                {item.icon === "linkedin" && <FaLinkedin />}
                {item.icon === "twitter" && <FaTwitter />}
                {item.icon === "youtube" && <FaYoutube />}
              </a>
            ))}
          </div>
        </div>
      </div>
      <div className="footer-bottom-section">
        <div className="footer-bottom-links">
          <a href="#">Become a Seller</a>
          <a href="#">Advertise</a>
          <a href="#">Gift Cards</a>
          <a href="#">Help Center</a>
        </div>
        <div className="footer-bottom-info">
          <p>© 2024 Your Company</p>
          <div className="payment-icons">
            <span>Visa</span>
            <span>MasterCard</span>
            <span>American Express</span>
            <span>PayPal</span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterTemplate3;
