// Import the functions you need from the SDKs you need
//import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
import { initializeApp, getApps, getApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBUy9FR3sx_gznZXa0i-4N2f9j6MXOPu2o",
  authDomain: "wordfun-dcd3b.firebaseapp.com",
  databaseURL: "https://wordfun-dcd3b-default-rtdb.firebaseio.com",
  projectId: "wordfun-dcd3b",
  storageBucket: "wordfun-dcd3b.firebasestorage.app",
  messagingSenderId: "830624246372",
  appId: "1:830624246372:web:ff9aec1757ae81d3ef0d71",
  measurementId: "G-6NTQ1X6EXE",
};

// Initialize Firebase
//const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
const firebaseApp =
  getApps().length === 0 ? initializeApp(firebaseConfig) : getApp();

// Get Firestore database
const db = getFirestore(firebaseApp);
export { db };
